// Customize PaginationItem styles here
const Pagination = (theme) => {
  return {
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            color: theme.palette.primary.dark,
            backgroundColor: '#F5F9FB',
            borderColor: '#F5F9FB',
            '&.Mui-selected': {
              backgroundColor: `${theme.palette.primary.dark} !important`,
              color: '#F5F9FB' // Change text color of the selected item
            }
          },
          '& .MuiPaginationItem-previousNext': {
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.primary.main}`
          },
          '& .MuiPaginationItem-firstLast': {
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.primary.main}`
          }
        }
      }
    }
  };
};

export default Pagination;
