import { Carousel } from 'antd';
import botLogo from '../../assets/bot_logo.svg';
import '../../styles/components/Chatbot/Thinking.css'; // Import your CSS file
import { Typography } from '@mui/material';

const Thinking = () => {
  return (
    <div className="thinking flex items-end mt-4">
      <div className="thinking__avatar">
        <img src={botLogo} className="thinking__avatar__img" alt="Bot Logo" />
      </div>
      <div className="thinking__chat__bubble">
        <Carousel
          className="thinking__chat__bubble__carousel"
          autoplay
          autoplaySpeed={8000}
          infinite={false}
          dots={false}
        >
          <div className="thinking__chat__bubble__stack">
            <Typography variant="body1" className="thinking__chat__bubble__text">
              Interpreting Question
            </Typography>
            <div className="thinking__chat__bubble__dot"></div>
          </div>
          <div className="thinking__chat__bubble__stack">
            <Typography variant="body1" className="thinking__chat__bubble__text">
              Performing Semantic Search
            </Typography>
            <div className="thinking__chat__bubble__dot"></div>
          </div>
          <div className="thinking__chat__bubble__stack">
            <Typography variant="body1" className="thinking__chat__bubble__text">
              Generating Response
            </Typography>
            <div className="thinking__chat__bubble__dot"></div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Thinking;
