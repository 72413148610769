import { Box, Typography, CircularProgress } from '@mui/material';
import '../../../styles/components/common/ErrorComponents/NoDataFound.css';
import { Player } from '@lottiefiles/react-lottie-player';
import NoDataFoundJson from '../../../assets/lottie files/noDataFound.json';

// eslint-disable-next-line no-unused-vars
export const NoDataFound = ({ loading = false, text }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Player
            className="noresultfound_error_icon"
            src={NoDataFoundJson}
            loop
            autoplay
            style={{ width: '150px', height: '150px', padding: '0px', margin: '0px' }}
          />
          <Typography variant="h4">{text}</Typography>
        </>
      )}
    </Box>
  );
};
