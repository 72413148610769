import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { EventType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { b2cPolicies, protectedResources } from '../msal-config/authConfig';
import axiosInstance from '../axios/axios';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { isAccessTokenExpired } from '../utils/isTokenExpired';
import { onboardUser } from '../services/authService';

function RouteGuard({ children }) {
  const { instance, inProgress, accounts } = useMsal();
  const { onBoarding, email, objectId } = JSON.parse(localStorage.getItem('userData') || '{}');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getToken = async () => {
    let accessToken = localStorage.getItem('accessToken');
    const tokenExpiresOn = localStorage.getItem('tokenExpiresOn');
    if (!isAccessTokenExpired(tokenExpiresOn)) {
      setIsAuthenticated(true);

      return accessToken;
    }

    const idTokenRequest = {
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      account: accounts[0],
      scopes: protectedResources.iaskAdminApi.scopes
    };

    return instance
      .acquireTokenSilent(idTokenRequest)
      .then((tokenResponse) => {
        // Acquire token silent success
        let accessToken = tokenResponse.accessToken;
        setIsAuthenticated(true);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('tokenExpiresOn', tokenResponse.expiresOn);

        return accessToken;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(idTokenRequest)
            .then(function (tokenResponse) {
              // Acquire token interactive success
              let accessToken = tokenResponse.accessToken;
              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('tokenExpiresOn', tokenResponse.expiresOn);

              setIsAuthenticated(true);

              return accessToken;
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.log(error);
              setIsAuthenticated(false);
              navigate('/login');
            });
        } else {
          navigate('/login');
        }
        console.log(error);

        return '';
      });
  };

  axiosInstance.interceptors.request.use(async (config) => {
    const bearer = await getToken();
    config.headers.Authorization = `Bearer ${bearer}`;

    return config;
  });

  useEffect(() => {
    // Check if user is authenticated
    // Try getting Token silently first else pop up login
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      getToken();
    }
  }, [instance, inProgress, isAuthenticated]);

  useEffect(() => {
    // Listen for sign-in event and onboard user
    instance.addEventCallback(async (event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = event.payload.account;

        const payload = {
          email: account.idTokenClaims.emails[0],
          objectId: account.idTokenClaims.oid
        };

        const response = await onboardUser(payload);

        const userData = {
          ...payload,
          orgId: response?.data?.org_id,
          active: response?.data?.active,
          crawlerConfigured: response?.data?.crawler_config,
          domainName: response?.data?.domain_name,
          onBoarding: response?.data?.on_boarding,
          teamsBotConfigured: response?.data?.teams_config
        };
        localStorage.setItem('userData', JSON.stringify(userData));
        if (response?.success && response?.data?.on_boarding) {
          navigate('/onboard');
        } else {
          navigate('/crawler');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!pathname.includes('onboard') && onBoarding && email && objectId) {
      navigate('/onboard');
    } else if (
      pathname.includes('onboard') &&
      onBoarding === false &&
      localStorage.getItem('loginStarted') === 'true'
    ) {
      navigate('/crawler');
    }
    localStorage.removeItem('loginStarted');
  }, [pathname, onBoarding]);

  return (
    <>
      {isAuthenticated && onBoarding !== undefined ? (
        <>
          {children} <Outlet />
        </>
      ) : (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default RouteGuard;
