function formatDate(date, format) {
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const year = date.getFullYear();

  if (format === 'MM/DD/YYYY') {
    return `${month}/${day}/${year}`;
  }
  if (format === 'YYYY-MM-DD') {
    return `${year}-${month}-${day}`;
  }

  return `${month}/${day}/${year}`;
}

export const getDateRange = (type, format = 'MM/DD/YYYY') => {
  const today = new Date();
  let startDate, endDate;

  switch (type) {
    case 'Today':
      startDate = today;
      endDate = today;
      break;
    case 'Last 7 Days':
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
      endDate = today;
      break;
    case 'This Month':
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = today;
      break;
    case 'Last Year':
      startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
      endDate = today;
      break;
    default:
      return 'Invalid type specified. Please provide last7Days, lastMonth, or lastYear as the argument.';
  }

  return {
    startDate: formatDate(startDate, format),
    endDate: formatDate(endDate, format)
  };
};
