import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import '../../../styles/components/Crawler/History/HistoryActions.css';
import CustomDialog from '../../common/Dialogs/CustomDialog';
import CrawlerRunDetailsDialog from '../../common/Dialogs/CrawlerRunDetailsDialog';
import { Visibility } from '@mui/icons-material';
import { refreshCrawlerHistoryStatus } from '../../../app/services/crawlerService';
import { useDispatch } from 'react-redux';

const HistoryActions = (props) => {
  const dispatch = useDispatch();
  const { currentPage, crawlerRunDetails } = props;
  const [openCrawlerRunDetailsDialog, setOpenCrawlerRunDetailsDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => {
    setOpenCrawlerRunDetailsDialog(false);
  };

  const handleOpenDialog = () => {
    setLoading(true);
    dispatch(
      refreshCrawlerHistoryStatus({ page: currentPage, crawlerRunIdList: [crawlerRunDetails.id] })
    )
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setOpenCrawlerRunDetailsDialog(true);
  };

  return (
    <>
      <Tooltip title="View crawler run details" placement="top">
        <IconButton className="actions__viewiconbtn" onClick={handleOpenDialog}>
          <Visibility className="actions__viewicon" />
        </IconButton>
      </Tooltip>

      <CustomDialog openDialog={openCrawlerRunDetailsDialog} handleCloseDialog={handleCloseDialog}>
        <CrawlerRunDetailsDialog crawlerRunDetails={crawlerRunDetails} loading={loading} />
      </CustomDialog>
    </>
  );
};

export default HistoryActions;
