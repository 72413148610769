import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../../../styles/components/common/Tabs/CustomTabs.css';
import { useTabs } from '../../../app/hooks/useTab';

export default function CustomTabs(props) {
  const { tabs } = props;
  const { currentTab, handleSetCurrentTab } = useTabs();

  const handleTabChange = (event, newTab) => {
    handleSetCurrentTab(newTab);
  };

  return (
    <Box className="tabs__container">
      <Tabs value={currentTab} onChange={handleTabChange} aria-label="navigation tabs">
        {tabs?.length
          ? tabs.map((tab, index) => (
              <Tab
                className={index === 0 ? 'first__tab' : ''}
                key={tab.tabName}
                value={tab.tabName}
                label={tab.tabName}
                icon={currentTab === tab.tabName ? tab.icon : undefined}
                iconPosition={currentTab === tab.tabName ? 'start' : undefined}
              />
            ))
          : null}
      </Tabs>
    </Box>
  );
}
