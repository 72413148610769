import ThemeSettings from './app/theme/Theme';
import './styles/App.css';
import { Grid } from '@mui/material';
import AppRoutes from './app/routes/AppRoutes.jsx';
import { withTabs } from './app/context/TabsContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Toast from './components/common/Toast/Toast.jsx';
import { MsalProvider } from '@azure/msal-react';

function App({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <Grid flexDirection={'row'} className="app">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeSettings>
            <Toast />
            <AppRoutes />
          </ThemeSettings>
        </LocalizationProvider>
      </Grid>
    </MsalProvider>
  );
}

export default withTabs(App);
