import React, { useEffect, useRef, useState } from 'react';
import './../../../styles/components/Crawler/Configuration/Configuration.css';
import { Box, Button, Typography, useTheme } from '@mui/material';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchBar from '../../common/Searchbar/SearchBar.js';
import CustomTable from '../../common/Table/CustomTable.jsx';
import useCrawlerConfigs from '../../../app/hooks/useCrawlerConfigs.js';
import useMultiSelector from '../../../app/hooks/useMultiSelector.js';
import CustomDialog from '../../common/Dialogs/CustomDialog.jsx';
import AddCrawlerDialog from '../../common/Dialogs/AddCrawlerDialog.jsx';
import RunCrawlerDialog from '../../common/Dialogs/RunCrawlerDialog.jsx';
import TableMenuBar from '../../common/Table/TableMenuBar.jsx';
import useSearch from '../../../app/hooks/useSearch.jsx';
import {
  setConfigOrderParam,
  setConfigSearchParam
} from '../../../app/redux/slices/crawlerSlice.js';
import { useDispatch, useSelector } from 'react-redux';

const columnHeaders = [
  { id: 'name', checkbox: true, sort: true, label: 'Crawler', align: 'left' },
  { id: 'totalRuns', checkbox: false, sort: true, label: 'Total runs', align: 'left' },
  { id: 'lastSynced', checkbox: false, sort: true, label: 'Last synced', align: 'left' },
  {
    id: 'actions',
    checkbox: false,
    customComponent: true,
    sort: false,
    label: 'Actions',
    align: 'left'
  }
];

const Configuration = () => {
  const theme = useTheme();
  const configurationHeaderRef = useRef();
  const tableContainerRef = useRef();
  const dispatch = useDispatch();

  const {
    loading,
    pageCount,
    crawlersList,
    currentPage,
    setCurrentPage,
    getCrawlerConfigs,
    addCrawlerConfig,
    runCrawler,
    onfilterApply
  } = useCrawlerConfigs();

  const { configFilters, configSearchParam, configOrderParam } = useSelector(
    (state) => state.crawler
  );
  const { searchText, setSearchText, onSearch, onClearSearch } = useSearch(configSearchParam);
  const { selected, selectAll, selectCurrent } = useMultiSelector();
  const [openAddCrawlerDialog, setOpenAddCrawlerDialog] = useState(false);
  const [openRunCrawlerDialog, setOpenRunCrawlerDialog] = useState(false);
  const [tableRowsData, setTableRowsData] = useState(crawlersList || []);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(columnHeaders[2].id);

  const paramsMap = {
    name: 'name',
    totalRuns: 'num_crawler_runs',
    lastSynced: 'updated_date'
  };

  useEffect(() => {
    setTableRowsData(crawlersList);
  }, [crawlersList]);

  const getSortedList = (property) => {
    let nextOrder = 'asc'; //Default to ascending order

    if (orderBy === property) {
      //If clicking on the same column, toggle the order
      nextOrder = order === 'asc' ? 'desc' : 'asc';
    }

    let orderParam = nextOrder === 'asc' ? '' : '-';

    orderParam += paramsMap[property];
    dispatch(setConfigOrderParam(orderParam));
    setCurrentPage(1);
    getCrawlerConfigs(1, configFilters, configSearchParam, orderParam, true);

    // Update state to reflect the new order and property
    setOrder(nextOrder);
    setOrderBy(property);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    onSearch(setConfigSearchParam, getCrawlerConfigs, configFilters);
  };

  useEffect(() => {
    if (configOrderParam) {
      const paramsMapReverse = Object.entries(paramsMap).reduce((result, [key, value]) => {
        result[value] = key;

        return result;
      }, {});

      const isDescOrder = configOrderParam.includes('-');

      const property =
        paramsMapReverse[isDescOrder ? configOrderParam.substring(1) : configOrderParam];

      setOrder(isDescOrder ? 'desc' : 'asc');
      setOrderBy(property);
    }
  }, [configOrderParam]);

  return (
    <Box container className="configuration__container">
      <Box ref={configurationHeaderRef} className="configuration__context">
        <Box container item className="configuration__header">
          <Box md={8}>
            <Typography variant="tabHeader">Configuration</Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: theme.typography.fontWeightMedium, textTransform: 'none' }}
            >
              Crawler configuration actions and overview
            </Typography>
          </Box>
          <Box md={4}>
            <Button
              className="configuration__runbutton"
              variant="outlined"
              color="primary"
              startIcon={<PlayArrowIcon />}
              disabled={selected.length === 0}
              onClick={() => setOpenRunCrawlerDialog(true)}
            >
              Run{selected.length > 0 ? ` (${selected.length})` : null}
            </Button>
            <Button
              className="configuration__addbutton"
              variant="contained"
              color="primary"
              startIcon={<AddToDriveIcon />}
              onClick={() => setOpenAddCrawlerDialog(true)}
            >
              Add Crawler
            </Button>
          </Box>
        </Box>
        <div className="configuration__tablemenubar">
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={handleSearch}
            onClear={() => {
              setCurrentPage(1);
              onClearSearch(setConfigSearchParam, getCrawlerConfigs, configFilters);
            }}
          />
          <TableMenuBar
            openFilterDialog={openFilterDialog}
            setOpenFilterDialog={setOpenFilterDialog}
            onfilterApply={onfilterApply}
            setSearchText={setSearchText}
          />
        </div>
      </Box>
      <Box
        ref={tableContainerRef}
        sx={{ height: `calc(100% - ${configurationHeaderRef?.current?.clientHeight}px)` }}
        className="configuration__list"
      >
        <CustomTable
          rows={tableRowsData}
          columnHeaders={columnHeaders}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selected={selected}
          selectAll={selectAll}
          selectCurrent={selectCurrent}
          loading={loading}
          getSortedList={getSortedList}
        />
      </Box>

      {/* Run and Add action dialogs */}
      <CustomDialog
        openDialog={openRunCrawlerDialog}
        handleCloseDialog={() => setOpenRunCrawlerDialog(false)}
      >
        <RunCrawlerDialog
          runCrawler={runCrawler}
          handleCloseDialog={() => {
            setTimeout(() => {
              getCrawlerConfigs(
                currentPage,
                configFilters,
                configSearchParam,
                configOrderParam,
                false
              );
            }, 1000);
            setOpenRunCrawlerDialog(false);
          }}
          runMultiple={true}
          selectedCrawlers={selected}
          deleteCrawler={selectCurrent}
          crawlersList={crawlersList}
        />
      </CustomDialog>

      <CustomDialog
        openDialog={openAddCrawlerDialog}
        handleCloseDialog={() => setOpenAddCrawlerDialog(false)}
        padding="24px 28px"
        crossIconStyle={{ width: '16px', height: '16px' }}
        crossIconBtnStyle={{ top: '20px', right: '24px' }}
      >
        <AddCrawlerDialog
          addCrawlerConfig={addCrawlerConfig}
          currentCrawlerConfigurationPage={currentPage}
          handleCloseDialog={() => setOpenAddCrawlerDialog(false)}
        />
      </CustomDialog>
    </Box>
  );
};

export default Configuration;
