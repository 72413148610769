export const isValidJSON = (jsonString) => {
  try {
    if (!jsonString) {
      return false;
    }
    JSON.parse(jsonString);

    return true;
  } catch (err) {
    console.log('Error parsing JSON: ', err.message);

    return false;
  }
};
