import React from 'react';
import '../../../styles/components/Crawler/Dashboard/DashboardSummaryCard.css';
import { Typography, Avatar } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { ClipLoader } from 'react-spinners';

const DashboardSummaryCard = (props) => {
  const { value, title, loading, icon = <FolderIcon />, bgcolor } = props;

  return (
    <div className="card">
      <div className="card__title__container">
        <Avatar sx={{ width: '40px', height: '40px', bgcolor: bgcolor }}>{icon}</Avatar>
        <Typography variant="body1" className="card__title">
          {title}
        </Typography>
      </div>
      <div className="card__value__container">
        <Typography variant="h4" className="card__value">
          {loading ? <ClipLoader color="#006494" size={20} speedMultiplier={0.7} /> : value}
        </Typography>
      </div>
    </div>
  );
};

export default DashboardSummaryCard;
