/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import '../../../styles/components/Crawler/Dashboard/ScheduledCrawlersList.css';
import { NoDataFound } from '../../common/ErrorComponents/NoDataFound';
import { List, ListItem } from '@mui/material';
import ScheduledCrawlersListRow from './ScheduledCrawlersListRow';

export default function ScheduledCrawlersList({ rows, loading }) {
  return (
    <List
      className="customlist"
      sx={{
        width: '100%',
        bgcolor: 'transparent',
        overflowY: 'auto',
        padding: 0,
        margin: 0,
        borderRadius: 'var(--borderRadius, 4px)'
      }}
    >
      {rows && rows?.length && !loading ? (
        rows.map((row) => <ScheduledCrawlersListRow key={row.crawlerName} crawler={row} />)
      ) : (
        <ListItem>
          <NoDataFound loading={loading} text={'No Data Found'} />
        </ListItem>
      )}
    </List>
  );
}
