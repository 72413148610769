export const getValidatedFilterParams = (paramKey, paramValue) => {
  if (paramValue !== undefined && paramValue !== null) {
    if (Array.isArray(paramValue) && paramValue.length > 0) {
      let paramValueString = `&${paramKey}=`;
      paramValue.forEach((value, ind) => {
        paramValueString += `${value}`;
        if (ind < paramValue.length - 1) {
          paramValueString += ',';
        }
      });

      return paramValueString;
    } else if (paramValue.toString().length > 0) {
      return `&${paramKey}=${paramValue}`;
    }
  }

  return '';
};
