/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import '../../../styles/components/common/Toast/Toast.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearToast } from '../../../app/redux/slices/toastSlice';

const getSeverityIcon = (severity) => {
  switch (severity) {
    case 'success':
      return <CheckCircleIcon className="severityicon check" />;
    case 'warning':
      return <WarningIcon className="severityicon warning" />;
    case 'info':
      return <InfoIcon className="severityicon info" />;
    case 'error':
      return <ErrorIcon className="severityicon error" />;
    default:
      return <CheckCircleIcon className="severityicon check" />;
  }
};

const Toast = () => {
  const dispatch = useDispatch();
  const { open, title, description, severity, showDuration } = useSelector((state) => state.toast);
  const [isActive, setIsActive] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const timer1Ref = useRef(null);
  const timer2Ref = useRef(null);

  const showToast = () => {
    // setting the Animation properties of the progressbar
    let root = document.querySelector(':root');
    root.style.setProperty('--animation-duration', `${showDuration / 1000}s`);
    root.style.setProperty('--animation-timing-function', 'linear');
    root.style.setProperty('--animation-fill-mode', 'forwards');

    setIsActive(true);
    setShowProgress(true);

    timer1Ref.current = setTimeout(() => {
      setIsActive(false);
      closeToast(timer1Ref);
    }, showDuration);
    timer2Ref.current = setTimeout(() => {
      setShowProgress(false);
      closeToast(timer2Ref);
    }, showDuration + 300);
  };

  const clearTimeouts = () => {
    clearTimeout(timer1Ref.current);
    clearTimeout(timer2Ref.current);
  };

  const closeToast = (timerRef = null) => {
    setIsActive(false);

    setTimeout(() => {
      setShowProgress(false);
      dispatch(clearToast()); // Hide Toast, clearToast
    }, 300);

    if (!timerRef?.current) {
      clearTimeouts();
    } else clearTimeout(timerRef?.current);
  };

  useEffect(() => {
    // Clean up timers on unmounting
    return () => clearTimeouts();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    if (open)
      showToast(); // Show toast when open is true
    else closeToast();
  }, [open]);

  return (
    <div
      className={`toast ${isActive ? 'active' : ''}`}
      style={{ display: showProgress || isActive ? '' : 'none' }}
    >
      <div className="toast-content">
        {getSeverityIcon(severity)}

        <div className="toat__message">
          <span className="text text-1">{title}</span>
          <span className="text text-2">{description}</span>
        </div>
      </div>
      <IconButton className="close" onClick={closeToast}>
        <CloseIcon />
      </IconButton>

      <div className={`progress ${showProgress ? 'active' : ''}`}></div>
    </div>
  );
};

export default Toast;
