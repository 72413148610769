import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Tooltip } from '@mui/material';
import { ReactComponent as SharepointIcon } from '../../../assets/sharepoint_icon.svg';
import { ReactComponent as OnedriveIcon } from '../../../assets/onedrive_icon.svg';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduledCrawlersListButtons from './ScheduledCrawlersListButtons';

const ScheduledCrawlersListRow = ({ crawler }) => {
  return (
    <ListItem
      className="customlist__listitem"
      style={{
        justifyContent: 'space-between',
        display: 'flex',
        padding: 0,
        margin: 0,
        paddingBottom: '15px'
      }}
    >
      <div
        className="customlist__listitem__text"
        style={{ display: 'flex', flex: 1, alignItems: 'center' }}
      >
        <ListItemAvatar>
          <Avatar>
            {crawler.crawlerType === 'sharepoint' ? <SharepointIcon /> : <OnedriveIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Tooltip title={crawler.crawlerName} placement="top">
              <Typography
                variant="h5"
                sx={{
                  textTransform: 'none !important',
                  fontWeight: 400,
                  maxWidth: '160px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {crawler.crawlerName}
              </Typography>
            </Tooltip>
          }
          secondary={
            <React.Fragment>
              <ScheduleIcon
                sx={{
                  alignItems: 'flex-end',
                  fontSize: 12,
                  marginRight: 1,
                  color: '#B7BEC4'
                }}
              />
              {/* 3:00 Mon, Feb 21 */}
              {crawler.crawlerTime + '  ' + crawler.todayDate}
            </React.Fragment>
          }
        />
      </div>
      <ScheduledCrawlersListButtons crawler={crawler} />
    </ListItem>
  );
};

export default ScheduledCrawlersListRow;
