import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetApi, callPostApi } from './apiCallService';

// Insights Dashboard Apis
export const getDashboardSummaryAction = createAsyncThunk(
  'insights/getDashboardSummaryAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `insights/summary`;
      result = await callGetApi(endpoint);
      if (!result.success) throw new Error(result.error);

      return result.data;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const getActiveUsersAndConversationsGraphDataAction = createAsyncThunk(
  'insights/getActiveUsersAndConversationsGraphDataAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `insights/active_user_analytics`;
      result = await callPostApi(endpoint, payload);
      if (!result.success) throw new Error(result.error);

      return result.data;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const getMostReferedFilesListAction = createAsyncThunk(
  'insights/getMostReferedFilesListAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `insights/most_referred_files`;
      result = await callGetApi(endpoint);
      if (!result.success) throw new Error(result.error);

      return result.data;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

//Insights Auditing APIs
export const listInsightsConversationAuditsAction = createAsyncThunk(
  'insights/listInsightsConversationAuditsAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      let endpoint = `insights/auditing/`;

      // adding querystringParams and params to endpoint
      let resetState = payload?.resetState ? true : false;
      if (payload?.page >= 0) endpoint += `?page=${payload.page}`;
      if (payload?.searchParams?.length > 0) endpoint += `&search=${payload.searchParams}`;
      if (payload?.orderParams?.length > 0) endpoint += `&ordering=${payload.orderParams}`;
      if (payload?.filterParams?.length > 0) endpoint += payload.filterParams;

      result = await callGetApi(endpoint);
      if (!result.success) throw new Error(result.error);

      const response = result.data;

      let conversationAuditsList = response.results.map((conversation) => ({
        question: conversation.question,
        answer: conversation.answer,
        dateTime: conversation.date
      }));

      return {
        success: true,
        totalCount: response.count,
        nextPage: response.next,
        previousPage: response.previous,
        page: payload.page,
        conversationAuditsList: conversationAuditsList,
        reset: resetState
      };
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);
