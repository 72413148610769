//********************** COLOR PALETTE **********************//
const palette = (mode) => {
  const lightColor = '19, 41, 61';
  const darkColor = '232, 241, 242';
  const mainColor = mode === 'light' ? lightColor : darkColor;

  return {
    customColors: {
      main: mainColor,
      tableHeaderBg: mode === 'light' ? 'rgba(0, 100, 148, 0.04)' : '#3D3759'
    },
    common: {
      black: '#000',
      white: '#FFF'
    },
    mode: mode,
    primary: {
      lighter: '#E8F1F2',
      light: '#1B98E0',
      main: '#247BA0',
      dark: '#006494',
      darker: '#13293D',
      contrastText: '#FFF'
    },
    secondary: {
      light: '#9C9FA4',
      main: '#8A8D93',
      dark: '#777B82',
      contrastText: '#FFF'
    },
    blue: {
      light: '#070e15',
      lightHover: '#d9e8ef',
      lightActive: '#b0cfde',
      normal: '#006494',
      normalHover: '#005a85',
      normalActive: '#005076',
      dark: '#004b6f',
      darkHover: '#003c59',
      darkActive: '#002d43',
      darker: '#002334'
    },
    darkblue: {
      light: '#e7eaec',
      lightHover: '#dcdfe2',
      lightActive: '#b6bdc3',
      normal: '#13293d',
      normalHover: '#112537',
      normalActive: '#0f2131',
      dark: '#0e1f2e',
      darkHover: '#0b1925',
      darkActive: '#09121b',
      darker: '#070e15'
    },
    grey: {
      G0: '#FFFFFF',
      G10: '#fafafa',
      G20: '#f5f5f6',
      G30: '#ebecec',
      G40: '#dfe0e1',
      G50: '#c1c3c5',
      G60: '#b2b4b6',
      G70: '#a6a8ab',
      G80: '#979a9d',
      G90: '#888b8f',
      G100: '#797d81',
      G200: '#6a6e73',
      G300: '#5b6065',
      G400: '#4f5459',
      G500: '#40454b',
      G600: '#34393f',
      G700: '#22292f',
      G800: '#131a21',
      G900: '#070e15'
    },
    success: {
      light: '#6AD01F',
      main: '#56CA00',
      dark: '#4CB200',
      contrastText: '#FFF'
    },
    error: {
      light: '#FF6166',
      main: '#FF4C51',
      dark: '#E04347',
      contrastText: '#FFF'
    },
    warning: {
      light: '#FFCA64',
      main: '#FFB400',
      dark: '#E09E00',
      contrastText: '#FFF'
    },
    info: {
      light: '#32BAFF',
      main: '#16B1FF',
      dark: '#139CE0',
      contrastText: '#FFF'
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.68)`,
      disabled: `rgba(${mainColor}, 0.38)`,
      inactive: 'rgba(0, 0, 0, 0.60)'
    },
    input: {
      borderColor: '#E8F1F2',
      borderColorFocused: '#247BA0',
      textColor: '#13293D',
      placeholderColor: '#13293D'
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? '#FFF' : '#312D4B',
      default: mode === 'light' ? '#F4F5FA' : '#28243D'
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.3)`,
      disabledBackground: `rgba(${mainColor}, 0.18)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  };
};

export default palette;
