import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCrawlerRunHistoryAction } from '../services/crawlerService';
import { getFormatedDate } from '../utils/dateFormater';
import { getValidatedFilterParams } from '../utils/getValidatedFilterParams';
import HistoryActions from '../../components/Crawler/History/HistoryActions';
import { setHistorySearchParam } from '../redux/slices/crawlerSlice';

const useCrawlerHistory = () => {
  const dispatch = useDispatch();

  const { crawlerRunHistory, totalRuns, historyFilters, historySearchParam, historyOrderParam } =
    useSelector((state) => state.crawler);
  const [crawlerRunsHistoryList, setCrawlerRunsHistoryList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 10;

  const getFormatedCrawlersList = () => {
    // Getting Crawler Configurations for current page and formating them
    let currentCrawlersList = crawlerRunHistory[currentPage];
    currentCrawlersList = currentCrawlersList?.map((crawlerRun) => {
      return {
        ...crawlerRun,
        startDate: getFormatedDate(crawlerRun.startDate),
        customComponent: (currentPage) => (
          <HistoryActions crawlerRunDetails={crawlerRun} currentPage={currentPage} />
        )
      };
    });

    return currentCrawlersList;
  };

  const getCrawlerRunHistory = (
    page = 1,
    newFilters = {},
    searchParams = '',
    orderParams = '',
    resetState = false
  ) => {
    try {
      setLoading(true);
      var filterParams = '';
      if (Object.keys(newFilters).length > 0) {
        try {
          filterParams += getValidatedFilterParams(
            newFilters?.crawlerTypes?.length > 1
              ? 'crawler_config_id__type__in'
              : 'crawler_config_id__type__icontains',
            newFilters?.crawlerTypes
          );
          filterParams += getValidatedFilterParams(
            newFilters?.status?.length > 1 ? 'status__in' : 'status__icontains',
            newFilters?.status
          );
          filterParams += getValidatedFilterParams('start_date__gte', newFilters?.fromDate);
          filterParams += getValidatedFilterParams('start_date__lte', newFilters?.toDate);
        } catch (error) {
          filterParams = '';
        }
      }

      const payload = {
        page: page,
        filterParams: filterParams,
        searchParams: searchParams,
        orderParams: orderParams,
        resetState: resetState
      };
      dispatch(listCrawlerRunHistoryAction(payload))
        .unwrap()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(crawlerRunHistory).length > 0) {
      setPageCount(Math.ceil(totalRuns / rowsPerPage));
      setCrawlerRunsHistoryList(getFormatedCrawlersList());
    } else {
      getCrawlerRunHistory(1, historyFilters, historySearchParam, historyOrderParam, true);
    }
  }, [crawlerRunHistory]);

  useEffect(() => {
    if (!(currentPage in crawlerRunHistory) && Object.keys(crawlerRunHistory).length > 0) {
      getCrawlerRunHistory(currentPage, historyFilters, historySearchParam, historyOrderParam);
    }
    if (currentPage in crawlerRunHistory) {
      setCrawlerRunsHistoryList(getFormatedCrawlersList());
    }
  }, [currentPage]);

  const onfilterApply = (newFilters) => {
    setCurrentPage(1);
    dispatch(setHistorySearchParam(''));
    getCrawlerRunHistory(1, newFilters, '', '', true); // by default get crawler configs for page 1 when filters are applied.
  };

  return {
    crawlerRunsHistoryList,
    setCrawlerRunsHistoryList,
    pageCount,
    currentPage,
    setCurrentPage,
    getCrawlerRunHistory,
    loading,
    setLoading,
    onfilterApply
  };
};

export default useCrawlerHistory;
