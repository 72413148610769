export const getInsightsAnalyticsGraphOption = (
  xAxisType,
  xAxisData,
  yAxisType,
  graphData,
  graphSeriesName,
  graphType
) => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    grid: { top: '5%', bottom: '17%', left: '6%', right: '6%' },
    toolbox: {
      feature: {
        // magicType: { show: true, type: ['line', 'bar'] },
        // restore: { show: true },
        // saveAsImage: { show: true }
      },
      emphasis: {
        iconStyle: {
          borderColor: '#324556'
        }
      },
      right: '10px'
    },
    xAxis: {
      type: xAxisType,
      data: xAxisData
    },
    yAxis: {
      type: yAxisType,
      nameTextStyle: {
        color: '#324556',
        fontWeight: 'bold'
      }
    },
    series: [
      {
        name: graphSeriesName,
        type: graphType,
        data: graphData,
        smooth: true,
        color: '#5470c6'
      }
    ],
    dataZoom: [
      {
        type: 'slider',
        show: true,
        start: 50,
        end: 100,
        handleSize: 15,
        height: 1,
        bottom: '6.5%',
        width: '70%',
        left: '15%'
      },
      {
        type: 'inside',
        start: 10,
        end: 100
      }
    ]
  };
};
