/* eslint-disable no-unused-vars */
// Slice containes insights tab related states
import { createSlice } from '@reduxjs/toolkit';
import {
  getActiveUsersAndConversationsGraphDataAction,
  getDashboardSummaryAction,
  getMostReferedFilesListAction,
  listInsightsConversationAuditsAction
} from '../../services/insightsService';

const initialState = {
  // insights dashboard states
  dashboardSummary: {},
  activeUsersAndConversationsGraphData: {},
  mostReferedFilesList: [],

  // insights conversation audits states
  insightsConversationAudits: {}, // { pageNumber: [conversations...], ... }
  totalCount: 0,
  previousPage: '',
  nextPage: '',
  auditFilters: {}, // filters
  auditSearchParam: '', // search
  auditOrderParam: '' // order
};

const insightsSlice = createSlice({
  name: 'insights',
  initialState: initialState,
  reducers: {
    setDashboardSummary: (state, action) => {
      state.dashboardSummary = action.payload;
    },
    setInsightsAuditFilters: (state, action) => {
      state.auditFilters = action.payload;
    },
    setAuditSearchParam: (state, action) => {
      state.auditSearchParam = action.payload;
    },
    setAuditOrderParam: (state, action) => {
      state.auditOrderParam = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardSummaryAction.fulfilled, (state, action) => {
        state.dashboardSummary = action.payload;
      })
      .addCase(getDashboardSummaryAction.rejected, (state, action) => {})
      .addCase(getActiveUsersAndConversationsGraphDataAction.fulfilled, (state, action) => {
        state.activeUsersAndConversationsGraphData = action.payload;
      })
      .addCase(getActiveUsersAndConversationsGraphDataAction.rejected, (state, action) => {})
      .addCase(getMostReferedFilesListAction.fulfilled, (state, action) => {
        state.mostReferedFilesList = action.payload;
      })
      .addCase(getMostReferedFilesListAction.rejected, (state, action) => {})
      .addCase(listInsightsConversationAuditsAction.fulfilled, (state, action) => {
        if (action.payload.reset) state.insightsConversationAudits = {};
        state.insightsConversationAudits[action.payload.page] =
          action.payload.conversationAuditsList;
        state.totalCount = action.payload.totalCount;
        state.previousPage = action.payload.previousPage;
        state.nextPage = action.payload.nextPage;
      })
      .addCase(listInsightsConversationAuditsAction.rejected, (state, action) => {});
  }
});

export const {
  setDashboardSummary,
  setInsightsAuditFilters,
  setAuditSearchParam,
  setAuditOrderParam
} = insightsSlice.actions;

export default insightsSlice.reducer;
