export const generateSchedulerTimes = (minuteDiff = 30) => {
  const schedulerTimes = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += minuteDiff) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const time = `${formattedHour}:${formattedMinute}`;
      schedulerTimes.push(time);
    }
  }

  return schedulerTimes;
};
