const Table = (theme) => {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[0],
          border: `1px solid ${theme.palette.primary.lighter}`
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          '& .MuiTableCell-head': {
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.13px'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          height: 'auto',
          overflowY: 'auto'
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-body': {
            letterSpacing: '0.25px',
            color: theme.palette.primary.darker
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // '& .MuiTableCell-head:first-of-type, & .MuiTableCell-root:first-of-type ': {
          //   paddingLeft: theme.spacing(3)
          // },
          '& .MuiTableCell-head:last-child, & .MuiTableCell-root:last-child': {
            paddingRight: theme.spacing(3),
            borderRight: 'none'
          },
          '& .MuiTableCell-head': {
            borderBottom: 'none'
          },

          [theme.breakpoints.down('xl')]: {
            '& .MuiTableCell-head': {
              // height: '64px',
              // maxHeight: '64px !important',
              // padding: '8px 0 8px 20px'
            }
          },
          [theme.breakpoints.up('xl')]: {
            '& .MuiTableCell-head, & .MuiTableCell-root': {
              // height: '74px',
              // maxHeight: '74px !important',
              // padding: '12px 0 12px 20px'
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px 0 12px 20px',
          borderBottom: `1px solid ${theme.palette.divider}`,
          borderRight: `1px solid ${theme.palette.divider}`,
          '& .MuiButton-root': {
            textTransform: 'uppercase',
            color: theme.palette.primary.darker
          },
          '& .MuiTypography-root': {
            display: 'block',
            maxWidth: '250px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          },
          [theme.breakpoints.down('xl')]: {
            // height: '64px',
            // maxHeight: '64px !important',
            // padding: '8px 0 8px 20px'
          },
          [theme.breakpoints.up('xl')]: {
            // height: '74px',
            // maxHeight: '74px !important'
          }
        },
        stickyHeader: {
          backgroundColor: '#f6f9fb'
        }
      }
    }
  };
};

export default Table;
