import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useSearch = (searchParam) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const onSearch = (action, getSearchList, filters = {}) => {
    if (searchText?.trim().length) {
      dispatch(action(searchText.trim()));
      getSearchList(1, filters, searchText.trim(), '', true);
    }
  };

  const onClearSearch = (action, getSearchList, filters) => {
    setSearchText('');
    dispatch(action(''));
    getSearchList(1, filters, '', '', true);
  };

  useEffect(() => {
    setSearchText(searchParam);
  }, [searchParam]);

  return { searchText, setSearchText, onSearch, onClearSearch };
};

export default useSearch;
