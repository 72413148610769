import React from 'react';
import '../../../styles/components/Crawler/Dashboard/Dashboard.css';
import { Grid, Stack, Typography, Box } from '@mui/material';
import DashboardSummaryCard from './DashboardSummaryCard';
import Graph from '../../common/Graph/Graph';
import { getRunTimeGraphOption } from './getRunTimeGraphsOptions';
import { getCrawlerAnalyticsGraphOption } from './getCrawlerAnalyticsGraphOptions';
import DashboardGraphFilters from './DashboardGraphFilters';
import { useSelector } from 'react-redux';
import ScheduledCrawlersList from './ScheduledCrawlersList.jsx';
import { ReactComponent as FileIcon } from '../../../assets/file_icon.svg';
import { ReactComponent as PermissionsIcon } from '../../../assets/permissions_synced_icon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/settings_icon_filled.svg';
import TotalRunsIcon from '../../../assets/total_runs_icon.png';
import useCrawlerDashboard from '../../../app/hooks/useCrawlerDashboard.js';
import { isObjectEmpty } from '../../../app/utils/objectEmpty';

export const DashboardHeaders = ({ header, subheader }) => {
  return (
    <Stack>
      <Typography variant="h5" className="dashboard__summary__title">
        {header}
      </Typography>
      <Typography variant="h6" className="dashboard__summary__subtitle">
        {subheader}
      </Typography>
    </Stack>
  );
};

const Dashboard = () => {
  const icons = [
    <SettingsIcon key={1} />,
    <img src={TotalRunsIcon} alt="total runs" key={2} style={{ height: '25px', width: '25px' }} />,
    <FileIcon key={3} />,
    <PermissionsIcon key={4} />
  ];

  const bgColors = ['#f1e3fa', '#fce7de', '#FBECCA', '#E9F9EE'];

  const { crawlerRunAnalyticsGraphData, crawlerRuntimeMetricsGraphData, dashboardSummary } =
    useSelector((state) => state.crawler);

  const {
    summaryLoader,
    crawlerAnalyticsGraphLoader,
    setCrawlerAnalyticsGraphLoader,
    crawlerRuntimeMetricsGraphLoader,
    setCrawlerRuntimeMetricsGraphLoader,
    crawlerScheduleLoader,
    scheduledCrawlersList
  } = useCrawlerDashboard();

  const summaryData = [
    { title: 'Crawlers', value: dashboardSummary.total_crawler_configs },
    { title: 'Total runs', value: dashboardSummary.total_crawler_runs },
    { title: 'File count', value: dashboardSummary.file_count },
    { title: 'Permissions synced', value: dashboardSummary.permission_count }
  ];

  return (
    <Box className="dashboard">
      {/* Dashboard summary content and table */}
      <Box className="dashboard__container__wrapper">
        <Grid container gap={4} className="dashboard__container">
          <Grid item xs={5.9} className="dashboard__summary">
            <Box className="dashboard__summary__header">
              <DashboardHeaders
                header={'Crawler Summary'}
                subheader={'Crawler metrics since start'}
              />
            </Box>
            <Grid
              container
              className="dashboard__summary__content"
              spacing={{ xs: 2, md: 3, lg: 4, xl: 5 }}
            >
              {summaryData?.map((summaryCard) => (
                <Grid item key={summaryCard.title} xs={6} sx={{ padding: '0px' }}>
                  <DashboardSummaryCard
                    title={summaryCard.title}
                    value={summaryCard.value}
                    loading={summaryLoader}
                    icon={icons[summaryData.indexOf(summaryCard)]}
                    bgcolor={bgColors[summaryData.indexOf(summaryCard)]}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={5.9}
            md={5.87}
            className="dashboard__table__container"
            display="flex"
            style={{ height: '100%', width: '100%', flexDirection: 'column', maxHeight: '100%' }}
          >
            <Box
              className="dashboard__table__header"
              display="flex"
              flex={{ xs: '0 1 auto' }}
              flexDirection={{ xs: 'column' }}
              style={{ width: '100%' }}
            >
              <DashboardHeaders
                header={"Today's Scheduled Crawler Runs"}
                subheader={'Crawler runs scheduled for today'}
              />
            </Box>
            <Box
              className="dashboard__table"
              display="flex"
              flex={1}
              flexDirection={{ xs: 'column' }}
              style={{ width: '100%', height: '100%' }}
            >
              <ScheduledCrawlersList loading={crawlerScheduleLoader} rows={scheduledCrawlersList} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Dashboard graphs content */}
      <Box className="dashboard__container__graph">
        <Grid gap={4} container sx={{ height: '100%', flexDirection: 'row' }}>
          <Grid xs={5.9} item sx={{ height: '100%' }}>
            <Box
              className="dashboard__graph__container"
              display="flex"
              style={{ height: '100%', width: '100%', flexDirection: 'column' }}
            >
              <Box
                className="dashboard__graph__header"
                display="flex"
                flex={{ xs: '0 1 auto' }}
                flexDirection={{ xs: 'column' }}
                style={{ width: '100%' }}
              >
                <DashboardHeaders
                  header={'Crawler Run Analytics'}
                  subheader={'Visualizing crawler success and failure over time'}
                />
                <DashboardGraphFilters
                  graphFor={'crawlerRunAnalytics'}
                  setLoading={setCrawlerAnalyticsGraphLoader}
                />
              </Box>
              <Box
                className="dashboard__graph"
                display="flex"
                flex={1}
                flexDirection={{ xs: 'column' }}
                style={{ width: '100%' }}
              >
                <Graph
                  graphType="bar"
                  option={
                    !isObjectEmpty(crawlerRunAnalyticsGraphData)
                      ? getCrawlerAnalyticsGraphOption(
                          'category',
                          crawlerRunAnalyticsGraphData?.dates,
                          'value',
                          [
                            crawlerRunAnalyticsGraphData?.pass_count,
                            crawlerRunAnalyticsGraphData?.fail_count
                          ],
                          'bar'
                        )
                      : null
                  }
                  graphStyle={{
                    height: '100%',
                    width: '100%'
                  }}
                  loading={crawlerAnalyticsGraphLoader}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={5.9} md={5.87} item sx={{ height: '100%' }}>
            <Box
              className="dashboard__graph__container"
              display="flex"
              style={{ height: '100%', width: '100%', flexDirection: 'column' }}
            >
              <Box
                className="dashboard__graph__header"
                display="flex"
                flex={{ xs: '0 1 auto' }}
                flexDirection={{ xs: 'column' }}
                style={{ width: '100%' }}
              >
                <DashboardHeaders
                  header={'Crawler Runtime Insights'}
                  subheader={'Runtime analysis minimum, maximum and average durations'}
                />
                <DashboardGraphFilters
                  graphFor={'crawlerRuntimeMetrics'}
                  setLoading={setCrawlerRuntimeMetricsGraphLoader}
                />
              </Box>
              <Box
                className="dashboard__graph"
                display="flex"
                flex={1}
                flexDirection={{ xs: 'column' }}
                style={{ width: '100%' }}
              >
                <Graph
                  graphType="line"
                  option={
                    !isObjectEmpty(crawlerRuntimeMetricsGraphData)
                      ? getRunTimeGraphOption(
                          'category',
                          crawlerRuntimeMetricsGraphData?.dates,
                          'value',
                          'line',
                          crawlerRuntimeMetricsGraphData?.average_runtime,
                          crawlerRuntimeMetricsGraphData?.max_runtime,
                          crawlerRuntimeMetricsGraphData?.min_runtime
                        )
                      : null
                  }
                  graphStyle={{
                    height: '100%',
                    width: '100%'
                  }}
                  loading={crawlerRuntimeMetricsGraphLoader}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
