export default function Input(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 36,
          borderRadius: theme.shape.borderRadius,
          '& fieldset': {
            borderColor: theme.palette.input.borderColor
          },
          '& input': {
            height: 15
          },
          '&:hover fieldset': {
            borderColor: `${theme.palette.input.borderColor} !important`
          },
          '&.Mui-focused fieldset': {
            borderColor: `${theme.palette.input.borderColor} !important`
          }
        },
        input: {
          fontSize: 13,
          color: theme.palette.input.textColor,
          fontWeight: theme.typography.fontWeightMedium,
          '&::placeholder': {
            color: theme.palette.input.placeholderColor,
            fontWeight: theme.typography.fontWeightMedium,
            opacity: 1
          }
        },
        adornedStart: {
          '& .MuiSvgIcon-root': {
            color: theme.palette.input.placeholderColor,
            opacity: 1
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          height: 30,
          borderRadius: 20,
          '&::before': {
            borderBottom: 'none !important'
          },
          '&::after': {
            borderBottom: 'none !important'
          },
          '& fieldset': {
            border: 'none'
          }
        },
        input: {
          fontSize: 12,
          padding: '0px 16px',
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  };
}
