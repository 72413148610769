import React from 'react';
import '../../../styles/components/common/Dialogs/CrawlerRunDetailsDialog.css';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { getFormatedDate } from '../../../app/utils/dateFormater';

const CrawlerRunDetailsRow = ({ label, value }) => {
  return (
    <Stack flexDirection="row" className="detailsdialog__row">
      <Typography variant="h5" className="detailsdialog__row__key">
        {label}
      </Typography>
      <Typography variant="body1" className="detailsdialog__row__value">
        {value}
      </Typography>
    </Stack>
  );
};

const CrawlerRunDetailsDialog = ({ crawlerRunDetails, loading }) => {
  const getFormatedData = (key) => {
    try {
      const formatedKey = JSON.parse(crawlerRunDetails?.statusDetails)[key];

      return formatedKey;
    } catch (error) {
      console.log(error);

      return 0;
    }
  };

  return (
    <div className="detailsdialog">
      <Typography variant="h4" className="detailsdialog__title">
        Crawler Run Details
      </Typography>
      {loading ? (
        <div className="detailsdialog__loader">
          <CircularProgress />
        </div>
      ) : (
        <Stack flexDirection="column" className="detailsdialog__content">
          <CrawlerRunDetailsRow label="Name" value={crawlerRunDetails.name} />
          <CrawlerRunDetailsRow label="Type" value={crawlerRunDetails.type} />
          <CrawlerRunDetailsRow label="Status" value={crawlerRunDetails.status} />
          <CrawlerRunDetailsRow label="Time taken" value={crawlerRunDetails.timeTaken} />
          <CrawlerRunDetailsRow
            label="Start date"
            value={getFormatedDate(crawlerRunDetails.startDate)}
          />
          <CrawlerRunDetailsRow
            label="New Files Discovered"
            value={getFormatedData('New Files Discovered')}
          />
          <CrawlerRunDetailsRow
            label="Permission Synced"
            value={getFormatedData('Permission Synced')}
          />
          <CrawlerRunDetailsRow label="Pass" value={getFormatedData('Pass')} />
          <CrawlerRunDetailsRow label="Fail" value={getFormatedData('Fail')} />
        </Stack>
      )}
    </div>
  );
};

export default CrawlerRunDetailsDialog;
