/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './../../styles/components/Sidebar/Sidebar.css';
import iAskLogo from './../../assets/iask_logo_white.svg';
import iAskLogoSmall from './../../assets/imgs/iask_logo_white.svg';
import insightsLogo from './../../assets/insights_logo.svg';
import policiesLogo from './../../assets/policies_logo.svg';
import settingsLogo from './../../assets/settings.svg';
import userProfileLogo from './../../assets/user_profile.svg';
import helpLogo from './../../assets/imgs/help_icon.png';
import feedbackLogo from './../../assets/imgs/feedback_icon.png';
import chatLogo from './../../assets/bot_logo_white.svg';
import { ReactComponent as IAskChatBotLogo } from '../../assets/bot_logo.svg';
import { ReactComponent as TeamsBotLogo } from '../../assets/teamsbot.svg';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { ChevronLeft, ChevronRight, ExitToAppRounded, Launch } from '@mui/icons-material';

const SidebarMenuItem = (props) => {
  const { link, menuItemName, logo, isOpen } = props;
  const location = useLocation();

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <div
        className={`sidebar__menuitem ${
          location.pathname.includes(link) ? 'selected__menuitem' : ''
        } ${!isOpen ? 'sidebar__menuitem__closed' : ''}`}
      >
        <Tooltip title={menuItemName} placement="right">
          <img src={logo} />
        </Tooltip>
        {isOpen && <p>{menuItemName}</p>}
      </div>
    </Link>
  );
};

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { teamsBotConfigured } = JSON.parse(localStorage.getItem('userData') || '{}');

  const menuItems = [
    { link: '/crawler', menuItemName: 'Crawler', logo: settingsLogo },

    // { link: '/policies', menuItemName: 'Policies', logo: policiesLogo },
    { link: '/insights', menuItemName: 'Insights', logo: insightsLogo },
    { link: '/chatbot', menuItemName: 'Chatbot', logo: chatLogo }
  ];
  const { instance } = useMsal();
  const username = instance?.getActiveAccount()?.name || 'Username';

  const handleLogout = (e) => {
    e.preventDefault();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <div className={`sidebar ${open ? '' : 'sidebar__close'}`}>
        <div className="sidebar__container">
          <div>
            <div className={`sidebar__logo ${open ? '' : 'sidebar__logo__closed'}`}>
              {open ? <img src={iAskLogo} /> : <img src={iAskLogoSmall} />}
            </div>

            <div className="sidebar__menu">
              {menuItems.map((menuItem) => (
                <SidebarMenuItem
                  key={menuItem.menuItemName}
                  link={menuItem.link}
                  menuItemName={menuItem.menuItemName}
                  logo={menuItem.logo}
                  isOpen={open}
                />
              ))}
            </div>
          </div>
          {open ? (
            <div className="sidebar_actionButtons__container">
              {teamsBotConfigured === false && (
                <div className="actionButtons" onClick={() => navigate('/onboard')}>
                  <TeamsBotLogo
                    style={{ width: '24px', height: '24px', marginRight: '8px' }}
                    fill="white"
                  />
                  <p>Integrate Teams</p>
                </div>
              )}
              <div
                className="actionButtons"
                onClick={() => window.open('https://www.privou.ai/feedback/', '_blank')}
              >
                <img
                  src={feedbackLogo}
                  style={{ width: '23px', height: '23px', marginRight: '9px' }}
                />
                <p>Feedback</p>
              </div>
              <div
                className="actionButtons"
                onClick={() => window.open('https://www.privou.ai/help/', '_blank')}
              >
                <img
                  src={helpLogo}
                  style={{ width: '20px', height: '20px', marginRight: '12px' }}
                />
                <p>Help</p>
              </div>
            </div>
          ) : (
            <div className="sidebar_actionButtons__container__closed">
              {teamsBotConfigured === false && (
                <div className="actionButtons__closed" onClick={() => navigate('/onboard')}>
                  <Tooltip title={'Integrate Microsoft Teams Bot'} placement="right">
                    <TeamsBotLogo style={{ width: '24px', height: '24px' }} fill="white" />
                  </Tooltip>
                </div>
              )}
              <div className="actionButtons__closed" onClick={() => navigate('/onboard')}>
                <Tooltip title={'Feedback'} placement="right">
                  <img
                    src={feedbackLogo}
                    style={{ width: '23px', height: '23px' }}
                    alt="feedback icon"
                  />
                </Tooltip>
              </div>
              <div className="actionButtons__closed" onClick={() => navigate('/onboard')}>
                <Tooltip title={'Help'} placement="right">
                  <img src={helpLogo} style={{ width: '20px', height: '20px' }} alt="help icon" />
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        <div className="sidebar__userActions">
          {open ? (
            <>
              {' '}
              <Tooltip title={username} placement="top">
                <div className="sidebar__userprofile">
                  <img src={userProfileLogo} />
                  <p>{username}</p>
                </div>
              </Tooltip>
              <Button className="sidebar__logoutbtn" variant="outlined" onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Tooltip title={username} placement="right">
                <div className="sidebar__userprofile__closed">
                  {username?.length ? username[0] : 'A'}
                </div>
              </Tooltip>
              <Tooltip title={'Logout'} placement="right">
                <ExitToAppRounded className="sidebar__logoutbtn__closed" onClick={handleLogout} />
              </Tooltip>
            </>
          )}
        </div>

        <div className={`sidebar__action__icon ${open ? '' : 'sidebar__action__icon__close'}`}>
          {open ? (
            <ChevronLeft
              sx={{ color: '#13293d', cursor: 'pointer' }}
              onClick={() => setOpen(false)}
            />
          ) : (
            <ChevronRight
              sx={{ color: '#13293d', cursor: 'pointer' }}
              onClick={() => setOpen(true)}
            />
          )}
        </div>
      </div>
      <Outlet />
    </React.Fragment>
  );
};

export default Sidebar;
