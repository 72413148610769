/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import '../../../styles/components/common/Dialogs/AddCrawlerDialog.css';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { Info } from '@mui/icons-material';
import DialogActionButtons from './DialogActionButtons';
import { generateSchedulerTimes } from '../../../app/utils/generateSchedulerTimes';
import { validateForm } from '../../../app/utils/crawlerFormValidator';
import useMultiSelector from '../../../app/hooks/useMultiSelector';
import { CheckboxTableCell } from '../Table/CheckboxTableCell';
import { useDispatch } from 'react-redux';
import { getCrawlersToAddAction } from '../../../app/services/crawlerService';

const TextFieldInputAdornment = ({ text }) => (
  <InputAdornment position="end" style={{ cursor: 'default' }}>
    <Tooltip title={text} placement="top">
      <Info fontSize="small" />
    </Tooltip>
  </InputAdornment>
);

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    height: '42px'
  }
}));

const ImportCrawlerList = (props) => {
  const { crawlerType, selectedCrawlers, setSelectedCrawlers } = props;
  const dispatch = useDispatch();

  const [importCrawlerList, setImportCrawlerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selected, selectAll, selectCurrent } = useMultiSelector();

  useEffect(() => {
    setLoading(true);
    dispatch(getCrawlersToAddAction({ type: crawlerType }))
      .unwrap()
      .then((crawlersList) => {
        setImportCrawlerList(crawlersList.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [crawlerType]);

  useEffect(() => {
    let updatedselectedCrawlers = [];
    importCrawlerList?.map((crawler) => {
      if (selected.includes(crawler?.name))
        updatedselectedCrawlers.push({ name: crawler?.name, site_url: crawler?.site_url });
    });
    setSelectedCrawlers(updatedselectedCrawlers);
  }, [selected]);

  const handleSelectAll = (e) => {
    let allCrawlers = [];
    importCrawlerList.forEach((crawler) => {
      if (!crawler?.exists) allCrawlers.push({ id: crawler?.name });
    });
    selectAll(e, allCrawlers);
  };

  const importCrawlersCount = (importCrawlerList || []).filter(
    (crawler) => !crawler?.exists
  ).length;
  const selectedCrawlersCount = selectedCrawlers?.length || 0;

  return (
    <Stack flexDirection="column" className="import__crawler__container">
      {!loading && importCrawlerList?.length === 0 && (
        <Typography sx={{ margin: 'auto' }}>No crawlers found</Typography>
      )}
      {!loading && importCrawlerList.length > 0 && (
        <Stack>
          <Stack flexDirection="row" className="import__crawler__header__container">
            <Checkbox
              size="medium"
              sx={{ p: 0 }}
              indeterminate={
                selectedCrawlersCount > 0 && selectedCrawlersCount < importCrawlersCount
              }
              checked={selectedCrawlersCount === importCrawlersCount && importCrawlersCount > 0}
              onChange={handleSelectAll}
            />
            <Typography variant="h4" sx={{ paddingLeft: '15px' }}>
              Crawler Name
            </Typography>
          </Stack>
          {importCrawlerList?.map((crawler, index) => (
            <div
              key={index}
              style={{
                width: '100%',
                padding: '12px 14px',
                borderTop: index > 0 ? '1px solid #E8F1F2' : ''
              }}
            >
              <CheckboxTableCell
                id={crawler?.name}
                label={crawler?.name}
                selectRow={selectCurrent}
                selected={selected}
                textSelection={crawler?.exists}
                showDisableText={crawler?.exists}
              />
            </div>
          ))}
        </Stack>
      )}
      {loading && <CircularProgress sx={{ margin: 'auto' }} />}
    </Stack>
  );
};

const AddCrawlerDialog = ({ addCrawlerConfig, handleCloseDialog }) => {
  const [formData, setFormData] = useState({
    crawlerName: '',
    crawlerType: '',
    crawlerUrl: ''
  });
  const [crawlerType, setCrawlerType] = useState('');
  const [selectedCrawlers, setSelectedCrawlers] = useState([]);

  const [accessScope, setAccessScope] = useState({
    usersValue: '',
    groupsValue: '',
    type: 'users'
  });

  const [formErrors, setFormErrors] = useState({
    crawlerName: { error: false, message: '' },
    crawlerType: { error: false, message: '' },
    crawlerUrl: { error: false, message: '' }
  });
  const [apiPayload, setApiPayload] = useState();
  const importCrawlerTypes = [{ name: 'Sharepoint', value: 'sharepoint' }];
  const [showSelectedCrawlersError, setShowSelectedCrawlersError] = useState();

  const crawlerTypes = [
    { name: 'Sharepoint', value: 'sharepoint' },
    { name: 'OneDrive', value: 'onedrive' }
  ];
  const schedulerTimes = generateSchedulerTimes(30);

  // eslint-disable-next-line prettier/prettier
  const schedulerDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  const [scheduleTime, setScheduleTime] = useState(schedulerTimes[0] || '00:00');
  const [scheduleDays, setScheduleDays] = useState([schedulerDays[0]]);
  const [showAddOptions, setShowAddOptions] = useState('both'); // both or manual or import
  const [showScheduleOptions, setShowScheduleOptions] = useState(false);

  const textFieldStyle = {
    sx: {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.60)',
        fontSize: '16px'
      },
      fontSize: '16px',
      padding: '6.5px 12px'
    }
  };

  const isFormValid = () => {
    if (showAddOptions === 'import') {
      setShowSelectedCrawlersError(selectedCrawlers?.length === 0);

      return selectedCrawlers?.length > 0;
    }

    if (formData?.crawlerType === 'onedrive') {
      return validateForm(
        { ...formData, crawlerUrl: `https://${accessScope?.url}` },
        setFormErrors
      );
    } else return validateForm(formData, setFormErrors);
  };

  useEffect(() => {
    if (formData?.crawlerType === 'onedrive') {
      const selectedAccessScopeValue =
        accessScope?.type === 'users' ? accessScope?.usersValue : accessScope?.groupsValue;
      setFormData({
        ...formData,
        crawlerUrl: `${accessScope?.type}/${selectedAccessScopeValue}`
      });
    }
  }, [accessScope]);

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const schedule = `{"time": "${scheduleTime}", "days": ${JSON.stringify(
      scheduleDays
    )}, "timezone": ${JSON.stringify(localTimeZone)}}`;
    const selectedCrawlerType = showAddOptions === 'import' ? crawlerType : formData?.crawlerType;

    const payload = {
      name: formData.crawlerName,
      type: selectedCrawlerType,
      org_id: process.env.REACT_APP_ORG_ID,
      url_link: formData?.crawlerUrl,
      site_urls: selectedCrawlers
    };
    if (showScheduleOptions) {
      payload.schedule = schedule;
    }
    setApiPayload(payload);
  }, [
    crawlerType,
    selectedCrawlers,
    formData.crawlerName,
    formData.crawlerType,
    formData.crawlerUrl,
    scheduleTime,
    scheduleDays,
    showScheduleOptions
  ]);

  return (
    <div className="add__dialog">
      <Typography variant="h3" className="add__dialog__title">
        Add Crawler
      </Typography>
      <Typography
        variant="subtitle"
        className="add__dialog__subtitle"
        sx={{ textTransform: 'none' }}
      >
        {showAddOptions === 'both' && 'Select configuration mode'}
        {showAddOptions === 'import' && 'Select Sharepoint sites to configure'}
        {showAddOptions === 'manual' && 'Configure the crawler details'}
      </Typography>

      {showAddOptions === 'both' && (
        <Stack className="add__dialog__options" flexDirection="column">
          <Select
            value={crawlerType}
            required
            className="add__dialog__optionsselect"
            inputProps={textFieldStyle}
            onChange={(e) => setCrawlerType(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              if (selected === '') {
                return <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Select crawler Type</span>;
              }

              return selected;
            }}
          >
            <MenuItem className="form__selectitems" disabled value="">
              <span>Select Crawler Type</span>
            </MenuItem>
            {importCrawlerTypes.map((crawler) => (
              <MenuItem className="form__selectitems" key={crawler.value} value={crawler.value}>
                {crawler.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            className="add__dialog__optionsbtn"
            onClick={() => setShowAddOptions('import')}
            disabled={!crawlerType?.trim()?.length}
          >
            Import crawlers
          </Button>
          <Stack className="add__dialog__orcontainer">
            <Divider className="divider" orientation="horizontal" variant="middle" />
            <Typography variant="subtitle" className="add__dialog__or">
              OR
            </Typography>
            <Divider className="divider" orientation="horizontal" variant="middle" />
          </Stack>
          <Button
            variant="contained"
            className="add__dialog__optionsbtn"
            onClick={() => setShowAddOptions('manual')}
          >
            Manually configure
          </Button>
        </Stack>
      )}

      {/* Import Crawler List */}
      {showAddOptions === 'import' && (
        <>
          <ImportCrawlerList
            crawlerType="sharepoint"
            selectedCrawlers={selectedCrawlers}
            setSelectedCrawlers={setSelectedCrawlers}
          />
          {showSelectedCrawlersError && (
            <Typography variant="h5" className="add__dialog__error">
              Please select at least one crawler
            </Typography>
          )}
        </>
      )}

      {/* Manually Configure Crawler */}
      {showAddOptions === 'manual' && (
        <div className="add__dialog__form">
          <Typography variant="subtitle" className="add__dialog__formtitle">
            Connection Details
          </Typography>

          <div className="add__dialog__inputscontainer">
            <CustomTextField
              variant="outlined"
              className="add__dialog__formInputs"
              inputProps={textFieldStyle}
              value={formData.crawlerName}
              onChange={(e) => setFormData({ ...formData, crawlerName: e.target.value })}
              placeholder="Crawler Name"
              error={formErrors.crawlerName.error}
              helperText={formErrors.crawlerName.error ? formErrors.crawlerName.message : ''}
            />
            <FormControl fullWidth error={!!formErrors.crawlerType.error}>
              <Select
                value={formData.crawlerType}
                required
                className="add__dialog__formSelect"
                inputProps={textFieldStyle}
                onChange={(e) => setFormData({ ...formData, crawlerType: e.target.value })}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return (
                      <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Select crawler Type</span>
                    );
                  }

                  return selected;
                }}
              >
                <MenuItem className="form__selectitems" disabled value="">
                  <span>Select Crawler Type</span>
                </MenuItem>
                {crawlerTypes.map((crawler) => (
                  <MenuItem className="form__selectitems" key={crawler.value} value={crawler.value}>
                    {crawler.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formErrors.crawlerType.message}</FormHelperText>
            </FormControl>

            {formData.crawlerType && formData.crawlerType == 'onedrive' ? (
              <>
                <FormControl className="add__dialog__formRadio__container">
                  <Typography variant="h6" className="add__dialog__formRadio__label">
                    Select Access Scope:
                  </Typography>
                  <RadioGroup
                    row
                    name="controlled-radio-buttons-group"
                    value={accessScope?.type}
                    onChange={(e) => setAccessScope({ ...accessScope, type: e.target.value })}
                  >
                    <FormControlLabel value="users" control={<Radio />} label="User" />
                    <FormControlLabel value="groups" control={<Radio />} label="Group" />
                  </RadioGroup>
                </FormControl>
                <CustomTextField
                  variant="outlined"
                  className="add__dialog__formInputs access__scope__input"
                  inputProps={textFieldStyle}
                  value={
                    accessScope?.type === 'users'
                      ? accessScope?.usersValue
                      : accessScope?.groupsValue
                  }
                  onChange={(e) =>
                    setAccessScope({
                      ...accessScope,
                      usersValue:
                        accessScope?.type === 'users' ? e.target.value : accessScope.usersValue,
                      groupsValue:
                        accessScope?.type === 'groups' ? e.target.value : accessScope.groupsValue
                    })
                  }
                  placeholder={
                    accessScope?.type === 'groups' ? 'Enter Group ID' : 'Enter Principal name'
                  }
                  error={formErrors.crawlerUrl.error}
                  helperText={
                    formErrors.crawlerUrl.error
                      ? accessScope?.type === 'groups'
                        ? 'Enter a valid Group ID'
                        : 'Enter a valid Principal name'
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <TextFieldInputAdornment
                        text={
                          <p>
                            {accessScope?.type === 'groups'
                              ? 'Enter the Azure AD group ID. eg.: 00000000-0000-0000-0000-000000000000'
                              : 'Enter the User Principal Name. eg.: sam@companydomain.com'}
                          </p>
                        }
                      />
                    )
                  }}
                />
              </>
            ) : (
              <CustomTextField
                variant="outlined"
                className="add__dialog__formInputs"
                inputProps={textFieldStyle}
                value={formData.crawlerUrl}
                onChange={(e) => setFormData({ ...formData, crawlerUrl: e.target.value })}
                placeholder="Storage Crawler URL"
                error={formErrors.crawlerUrl.error}
                helperText={formErrors.crawlerUrl.error ? formErrors.crawlerUrl.message : ''}
                InputProps={{
                  endAdornment: (
                    <TextFieldInputAdornment
                      text={'Url link to access a specific storage location.'}
                    />
                  )
                }}
              />
            )}
          </div>
        </div>
      )}

      {showAddOptions != 'both' && (
        <div className="add__dialog__scheduler">
          <div className="scheduler__container">
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography variant="subtitle" className="scheduler__title">
                Scheduler
              </Typography>
              <Switch
                checked={showScheduleOptions}
                onChange={() => {
                  setShowScheduleOptions(!showScheduleOptions);
                }}
              />
            </Stack>
            {showScheduleOptions && (
              <div className="scheduler__content">
                <div className="scheduler__time">
                  <Typography variant="subtitle" className="scheduler__label">
                    Time
                  </Typography>
                  <Select
                    value={scheduleTime}
                    required
                    className="scheduler__Select"
                    inputProps={textFieldStyle}
                    onChange={(e) => setScheduleTime(e.target.value)}
                    displayEmpty
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                      },
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {schedulerTimes.map((time) => (
                      <MenuItem className="form__selectitems" key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="scheduler__day">
                  <Typography variant="subtitle" className="scheduler__label">
                    Day
                  </Typography>
                  <Select
                    value={scheduleDays}
                    required
                    multiple
                    className="scheduler__Select"
                    inputProps={textFieldStyle}
                    onChange={(e) => setScheduleDays(e.target.value)}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <div className="scheduler__daySelect__input">
                        {selected?.map((value) => (
                          <Chip key={value} label={value} className="scheduler__daySelect__chip" />
                        ))}
                      </div>
                    )}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                      },
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {schedulerDays.map((day) => (
                      <MenuItem className="form__selectitems" key={day} value={day}>
                        <Checkbox checked={scheduleDays.includes(day)} />
                        <ListItemText primary={day} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}
          </div>
          <DialogActionButtons
            submitBtnText="Add crawler"
            cancelBtnText="Cancel"
            onSubmitAction={addCrawlerConfig}
            handleCloseDialog={handleCloseDialog}
            checkValidity={true}
            isFormValid={isFormValid}
            align="right"
            apiPayload={apiPayload}
          />
        </div>
      )}
    </div>
  );
};

export default AddCrawlerDialog;
