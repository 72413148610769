//********************** RESOLUTION BREAKPOINTS **********************//
const breakpoints = () => ({
  values: {
    xs: 0,
    sm: 700,
    md: 1000,
    lg: 1200,
    xl: 1600
  }
});

export default breakpoints;
