import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import React from 'react';
import { ReactComponent as SortIcon } from '../../../assets/sort_icon.svg';
import '../../../styles/components/common/Table/TableHeader.css';

const TableHeader = (props) => {
  const {
    columnHeaders,
    onSelectAllClick,
    selectedRowsCount,
    rowCount,
    onRequestSort,
    tableHeaderStyles
  } = props;

  return (
    <TableHead sx={tableHeaderStyles}>
      <TableRow>
        {columnHeaders.map((columnHeader) => (
          <TableCell className="tableheader__cell" align={columnHeader.align} key={columnHeader.id}>
            <Box className="tableheader__cellcontainer" sx={{}}>
              {columnHeader.checkbox ? (
                <Box className="tableheader__checkboxcell">
                  <Checkbox
                    size="medium"
                    sx={{ paddingLeft: '12px' }}
                    indeterminate={selectedRowsCount > 0 && selectedRowsCount < rowCount}
                    checked={selectedRowsCount === rowCount && rowCount > 0}
                    onChange={onSelectAllClick}
                  />
                  <Typography variant="h4" sx={{ paddingLeft: '7px', fontSize: '15px !important' }}>
                    {columnHeader.label}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="h4" sx={{ paddingLeft: '8px', fontSize: '15px !important' }}>
                  {columnHeader.label}
                </Typography>
              )}

              {columnHeader.sort && (
                <Tooltip title={`Sort by ${columnHeader.label}`} placement="top">
                  <IconButton onClick={() => onRequestSort(columnHeader.id)}>
                    <SortIcon className="tableheader__sorticon" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
