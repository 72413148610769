/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as IAskLogo } from '../../assets/iask_logo.svg';
import '../../styles/components/Chatbot/ChatView.css';
import Message from './Message.jsx';
import Thinking from './Thinking.jsx';
import { socket } from '../../app/socket/socket.js';
import { DeleteOutline, Send } from '@mui/icons-material';

const ChatView = () => {
  const [askedQuestion, setAskedQuestion] = useState('');
  const [isTypingStatus, setIsTypingStatus] = useState({ id: 0, isTyping: false });
  const [thinking, setThinking] = useState(false);

  let messages = JSON.parse(localStorage.getItem('messages')) || [];
  const messagesEndRef = useRef();
  const inputRef = useRef();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, thinking]);

  const updateMessage = (newValue, ai = false) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);

    const newMsg = {
      id: id,
      createdAt: Date.now(),
      text: newValue,
      ai: ai
    };
    setIsTypingStatus({ id: id, isTyping: true });

    let newMessages = JSON.parse(localStorage.getItem('messages')) || [];
    newMessages.push(newMsg);
    localStorage.setItem('messages', JSON.stringify(newMessages));
  };

  function getSessionId() {
    const storage = localStorage;
    const storageKey = 'RASA_SESSION_ID';
    const savedId = storage.getItem(storageKey);
    if (savedId) {
      return savedId;
    }

    const { objectId } = JSON.parse(localStorage.getItem('userData') || '{}');
    const newId = `web_${objectId}`;
    storage.setItem(storageKey, newId);

    return newId;
  }

  let interval;
  useEffect(() => {
    if (isTypingStatus.isTyping) {
      interval = setInterval(() => {
        scrollToBottom();
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isTypingStatus.isTyping]);

  const handleSocketConnection = () => {
    console.log('Connected to Socket.io server.');
    socket.emit('session_request', {
      session_id: getSessionId()
    });
  };

  function handleBotUttered(response) {
    if (response?.text) {
      updateMessage(response?.text, true);
      setThinking(false);
    }
    if (response?.attachment) {
      updateMessage(response.attachment.payload.src, true);
    }
  }

  useEffect(() => {
    inputRef.current.focus();
    socket.connect();

    socket.on('connect', handleSocketConnection);

    socket.on('connect_error', (error) => {
      console.error(error);
    });

    function onDisconnect() {
      console.log('Disconnected');
    }

    socket.on('bot_uttered', handleBotUttered);

    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', handleSocketConnection);
      socket.off('disconnect', onDisconnect);
      socket.off('bot_uttered', handleBotUttered);
    };
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    setThinking(true);

    const cleanPrompt = askedQuestion;

    const newMsg = cleanPrompt;

    setAskedQuestion('');
    updateMessage(newMsg, false);

    try {
      socket.emit('user_uttered', {
        message: newMsg,
        session_id: getSessionId()
      });
    } catch (err) {
      setThinking(false);
      window.alert(`Error: ${err} please try again later`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage(e);
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    localStorage.removeItem('messages');
    setAskedQuestion('');
    setIsTypingStatus({ id: 0, isTyping: false });
  };

  return (
    <Box className="chatview">
      <Stack className="chatview__header">
        <IAskLogo className="chatview__header__img" />
        <Typography className="chatview__header__title" variant="h3">
          Enterprise Conversational Assistant
        </Typography>
      </Stack>

      <Stack className="chatview__container">
        <Box className="chatview__body">
          {messages.length ? (
            messages.map((message, index) => (
              <Message
                messagesEndRef
                key={index}
                isTypingStatus={isTypingStatus}
                setIsTypingStatus={setIsTypingStatus}
                message={{ ...message }}
              />
            ))
          ) : (
            <></>
          )}

          {thinking && <Thinking />}

          <span ref={messagesEndRef}></span>
        </Box>

        <form className="chatview__form" onSubmit={sendMessage}>
          <div className="chatview__form__group">
            <Button
              className="chatview__form__clearbtn"
              disabled={!messages?.length}
              variant="contained"
              disableElevation
              onClick={handleClear}
            >
              <Tooltip title="Clear chat" placement="top">
                <DeleteOutline sx={{ color: messages?.length ? '#d5472e' : 'gray' }} />
              </Tooltip>
            </Button>
            <textarea
              ref={inputRef}
              className="chatview__form__textarea"
              value={askedQuestion}
              placeholder="Just Ask ..."
              onKeyDown={handleKeyDown}
              onChange={(e) => setAskedQuestion(e.target.value)}
            />
            <Button
              type="submit"
              className={`submit-button ${askedQuestion?.length > 0 ? 'active' : 'disabled'}`}
              disabled={!askedQuestion}
              variant="contained"
              disableElevation
            >
              <Tooltip title="Ask question" placement="top">
                <Send sx={{ color: askedQuestion?.length > 0 ? '#0056b3' : 'gray' }} />
              </Tooltip>
            </Button>
          </div>
        </form>

        <Typography className="chatview__note" variant="body1">
          <span style={{ fontWeight: '600' }}>Note</span>: Kindly be advised this chat UI does not
          feature RBAC (Role-Based Access Control) permissions for interactions with the Privou.
          Please use MS Teams Version.
        </Typography>
      </Stack>
    </Box>
  );
};

export default ChatView;
