/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import '../../../styles/components/common/Dialogs/EditCrawlerDialog.css';
import { isValidJSON } from '../../../app/utils/validJSON.js';
import {
  // eslint-disable-next-line no-unused-vars
  Checkbox,
  Chip,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
  styled,
  Stack,
  Switch
} from '@mui/material';
import { Info } from '@mui/icons-material';
import DialogActionButtons from './DialogActionButtons';
import { generateSchedulerTimes } from '../../../app/utils/generateSchedulerTimes';
import { validateForm } from '../../../app/utils/crawlerFormValidator';

const TextFieldInputAdornment = ({ text }) => (
  <InputAdornment position="end" style={{ cursor: 'default' }}>
    <Tooltip title={text} placement="top">
      <Info fontSize="small" />
    </Tooltip>
  </InputAdornment>
);

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    height: '42px'
  }
}));

const EditCrawlerDialog = ({ crawlerConfig, editCrawlerConfig, handleCloseDialog }) => {
  const [formData, setFormData] = useState({
    crawlerName: crawlerConfig?.name,
    crawlerType: crawlerConfig?.type,
    crawlerUrl: crawlerConfig?.urlLink,
    crawlerScheduleTime: isValidJSON(crawlerConfig?.schedule)
      ? JSON.parse(crawlerConfig?.schedule).time
      : '00:00',
    crawlerScheduleDays: isValidJSON(crawlerConfig?.schedule)
      ? JSON.parse(crawlerConfig?.schedule).days
      : ['Monday']
  });

  const [formErrors, setFormErrors] = useState({
    crawlerName: { error: false, message: '' },
    crawlerType: { error: false, message: '' },
    crawlerUrl: { error: false, message: '' },
    crawlerScheduleTime: { error: false, message: '' },
    crawlerScheduleDays: { error: false, message: '' }
  });
  const [apiPayload, setApiPayload] = useState();

  const [showScheduleOptions, setShowScheduleOptions] = useState(
    isValidJSON(crawlerConfig?.schedule) ? true : false
  );

  const schedulerTimes = generateSchedulerTimes(30);

  // eslint-disable-next-line prettier/prettier
  const schedulerDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  const [accessScope, setAccessScope] = useState({
    usersValue: crawlerConfig?.urlLink?.includes('users')
      ? crawlerConfig?.urlLink?.split('/').pop()
      : '',
    groupsValue: crawlerConfig?.urlLink?.includes('groups')
      ? crawlerConfig?.urlLink?.split('/').pop()
      : '',
    type: crawlerConfig?.urlLink?.includes('groups') ? 'groups' : 'users'
  });

  const textFieldStyle = {
    sx: {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.60)',
        fontSize: '16px'
      },
      fontSize: '16px',
      padding: '6.5px 12px'
    }
  };

  const isFormValid = () => {
    if (formData?.crawlerType === 'onedrive') {
      return validateForm(
        { ...formData, crawlerUrl: `https://${accessScope?.url}` },
        setFormErrors
      );
    } else return validateForm(formData, setFormErrors);
  };

  useEffect(() => {
    if (formData?.crawlerType === 'onedrive') {
      const selectedAccessScopeValue =
        accessScope?.type === 'users' ? accessScope?.usersValue : accessScope?.groupsValue;
      setFormData({
        ...formData,
        crawlerUrl: `${accessScope?.type}/${selectedAccessScopeValue}`
      });
    }
  }, [accessScope]);

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const schedule = `{"time": "${formData.crawlerScheduleTime}", "days": ${JSON.stringify(
      formData.crawlerScheduleDays
    )}, "timezone": ${JSON.stringify(localTimeZone)}}`;

    const payload = {
      name: formData.crawlerName,
      type: formData.crawlerType,
      org_id: process.env.REACT_APP_ORG_ID,
      url_link: formData?.crawlerUrl,
      crawlerConfigId: crawlerConfig.id
    };

    if (showScheduleOptions) {
      payload.schedule = schedule;
    }
    setApiPayload(payload);
  }, [
    formData.crawlerName,
    formData.crawlerType,
    formData.crawlerUrl,
    formData.crawlerScheduleTime,
    formData.crawlerScheduleDays,
    showScheduleOptions
  ]);

  return (
    <div className="edit__dialog">
      <Typography variant="h3" className="edit__dialog__title">
        Edit Crawler
      </Typography>
      <Typography
        variant="subtitle"
        className="edit__dialog__subtitle"
        sx={{ textTransform: 'none' }}
      >
        Edit crawler configuration
      </Typography>

      <div className="edit__dialog__form">
        <Typography variant="subtitle" className="edit__dialog__formtitle">
          Connection Details
        </Typography>

        <div className="edit__dialog__inputscontainer">
          <CustomTextField
            variant="outlined"
            className="add__dialog__formInputs"
            inputProps={textFieldStyle}
            value={formData.crawlerName}
            onChange={(e) => setFormData({ ...formData, crawlerName: e.target.value })}
            placeholder="Crawler Name"
            error={formErrors.crawlerName.error}
            helperText={formErrors.crawlerName.error ? formErrors.crawlerName.message : ''}
          />
          <Select
            value={formData.crawlerType}
            required
            className="edit__dialog__formSelect"
            inputProps={textFieldStyle}
            displayEmpty
            disabled
            renderValue={(selected) => {
              if (selected === '') {
                return <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Select crawler type</span>;
              }

              return selected;
            }}
          >
            <MenuItem className="form__selectitems" disabled value="">
              <span>Select crawler type</span>
            </MenuItem>
          </Select>

          {formData.crawlerType && formData.crawlerType == 'onedrive' ? (
            <>
              <FormControl className="add__dialog__formRadio__container">
                <Typography variant="h6" className="add__dialog__formRadio__label">
                  Select Access Scope:
                </Typography>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={accessScope?.type}
                  onChange={(e) => setAccessScope({ ...accessScope, type: e.target.value })}
                >
                  <FormControlLabel value="users" control={<Radio />} label="User" />
                  <FormControlLabel value="groups" control={<Radio />} label="Group" />
                </RadioGroup>
              </FormControl>
              <CustomTextField
                variant="outlined"
                className="add__dialog__formInputs access__scope__input"
                inputProps={textFieldStyle}
                value={
                  accessScope?.type === 'users' ? accessScope?.usersValue : accessScope?.groupsValue
                }
                onChange={(e) =>
                  setAccessScope({
                    ...accessScope,
                    usersValue:
                      accessScope?.type === 'users' ? e.target.value : accessScope.usersValue,
                    groupsValue:
                      accessScope?.type === 'groups' ? e.target.value : accessScope.groupsValue
                  })
                }
                placeholder={
                  accessScope?.type === 'groups' ? 'Enter Group ID' : 'Enter Principal name'
                }
                error={formErrors.crawlerUrl.error}
                helperText={
                  formErrors.crawlerUrl.error
                    ? accessScope?.type === 'groups'
                      ? 'Enter a valid Group ID'
                      : 'Enter a valid Principal name'
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <TextFieldInputAdornment
                      text={
                        <p>
                          {accessScope?.type === 'groups'
                            ? 'Enter the Azure AD group ID. eg.: 00000000-0000-0000-0000-000000000000'
                            : 'Enter the User Principal Name. eg.: sam@companydomain.com'}
                        </p>
                      }
                    />
                  )
                }}
              />
            </>
          ) : (
            <CustomTextField
              variant="outlined"
              className="edit__dialog__formInputs"
              inputProps={textFieldStyle}
              value={formData.crawlerUrl}
              onChange={(e) => setFormData({ ...formData, crawlerUrl: e.target.value })}
              placeholder="Storage Crawler URL"
              error={formErrors.crawlerUrl.error}
              helperText={formErrors.crawlerUrl.error ? formErrors.crawlerUrl.message : ''}
              InputProps={{
                endAdornment: (
                  <TextFieldInputAdornment
                    text={'Url link to access a specific storage location.'}
                  />
                )
              }}
            />
          )}
        </div>
      </div>

      <div className="edit__dialog__scheduler">
        <div className="scheduler__container">
          <Stack direction={'row'} sx={{ alignItems: 'center' }}>
            <Typography variant="subtitle" className="scheduler__title">
              Scheduler
            </Typography>
            <Switch
              checked={showScheduleOptions}
              onChange={() => setShowScheduleOptions(!showScheduleOptions)}
            />
          </Stack>
          {showScheduleOptions && (
            <div className="scheduler__content">
              <div className="scheduler__time">
                <Typography variant="subtitle" className="scheduler__label">
                  Time
                </Typography>
                <Select
                  value={formData.crawlerScheduleTime}
                  required
                  className="scheduler__Select"
                  inputProps={textFieldStyle}
                  onChange={(e) =>
                    setFormData({ ...formData, crawlerScheduleTime: e.target.value })
                  }
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {schedulerTimes.map((time) => (
                    <MenuItem className="form__selectitems" key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="scheduler__day">
                <Typography variant="subtitle" className="scheduler__label">
                  Day
                </Typography>
                <Select
                  value={formData.crawlerScheduleDays}
                  required
                  multiple
                  className="scheduler__Select"
                  inputProps={textFieldStyle}
                  onChange={(e) =>
                    setFormData({ ...formData, crawlerScheduleDays: e.target.value })
                  }
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <div className="scheduler__daySelect__input">
                      {selected?.map((value) => (
                        <Chip className="scheduler__daySelect__chip" key={value} label={value} />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {schedulerDays.map((day) => (
                    <MenuItem className="form__selectitems" key={day} value={day}>
                      <Checkbox checked={formData.crawlerScheduleDays?.includes(day)} />
                      <ListItemText primary={day} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
        <DialogActionButtons
          submitBtnText="Yes, Confirm"
          cancelBtnText="No, Cancel"
          onSubmitAction={editCrawlerConfig}
          handleCloseDialog={handleCloseDialog}
          checkValidity={true}
          isFormValid={isFormValid}
          align="right"
          apiPayload={apiPayload}
        />
      </div>
    </div>
  );
};

export default EditCrawlerDialog;
