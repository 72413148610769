/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { callDeleteApi, callGetApi, callPostApi, callPutApi } from './apiCallService';
import { getFormatedTime } from '../utils/timeConverter';
import { scheduledCrawlersPayload } from '../utils/scheduledCrawlersPayload';
import { isValidJSON } from '../utils/validJSON';

// NOTE: ALL actions return result object which do contain success and error / data keys

// Crawler Configurations actions
export const listCrawlerConfigsAction = createAsyncThunk(
  'crawler/listCrawlerConfigsAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      let endpoint = `crawler_configuration/`;

      // adding querystringParams and params to endpoint
      let resetState = payload?.resetState ? true : false;
      if (payload?.page >= 0) endpoint += `?page=${payload.page}`;
      if (payload?.searchParams?.length > 0) endpoint += `&search=${payload.searchParams}`;
      if (payload?.orderParams?.length > 0) endpoint += `&ordering=${payload.orderParams}`;
      if (payload?.filterParams?.length > 0) endpoint += payload.filterParams;

      result = await callGetApi(endpoint);
      if (!result.success) throw new Error(result.error);
      const response = result.data;

      let crawlersList = response.results.map((crawler) => {
        return {
          id: crawler.id,
          type: crawler.type,
          urlLink: crawler.url_link,
          accessDetails: crawler.access_details,
          schedule: crawler.schedule,
          createdDate: crawler.created_date,
          updatedDate: crawler.updated_date,
          orgId: crawler.org_id,
          name: crawler.name,
          totalRuns: crawler.crawler_runs?.length
        };
      });

      return {
        success: result.success,
        page: payload.page,
        crawlers: crawlersList,
        totalCrawlers: response.count,
        reset: resetState
      };
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const getCrawlerConfigAction = createAsyncThunk(
  'crawler/getCrawlerConfigAction',
  async (crawlerConfigId, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_configuration/${crawlerConfigId}`;
      result = await callGetApi(endpoint);
      if (!result.success) return thunkAPI.rejectWithValue(result);

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const addCrawlerConfigAction = createAsyncThunk(
  'crawler/addCrawlerConfigAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      let endpoint = `crawler_configuration/`;
      if (Object.keys(payload?.site_urls)?.length > 0) {
        endpoint = `crawler_configuration/bulk_create`;
      }

      result = await callPostApi(endpoint, payload);
      if (!result.success) throw new Error(result.error);

      thunkAPI.dispatch(listCrawlerConfigsAction({ page: 1 }));

      //If the Crawler to be added is scheduled for Today's day,
      //Then update todaysScheduledCrawlers list to show this newly added Crawler
      if (
        isValidJSON(result.data?.schedule) &&
        JSON.parse(result.data?.schedule).days.includes(scheduledCrawlersPayload().day)
      ) {
        thunkAPI.dispatch(listTodaysScheduledCrawlersAction(scheduledCrawlersPayload()));
      }

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const editCrawlerConfigAction = createAsyncThunk(
  'crawler/editCrawlerConfigAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_configuration/${payload.crawlerConfigId}`;
      result = await callPutApi(endpoint, payload);
      if (!result.success) throw new Error(result.error);

      thunkAPI.dispatch(listTodaysScheduledCrawlersAction(scheduledCrawlersPayload()));

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const deleteCrawlerConfigAction = createAsyncThunk(
  'crawler/deleteCrawlerConfigAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_configuration/${payload.id}`;
      result = await callDeleteApi(endpoint);
      if (!result.success) throw new Error(result.error);

      thunkAPI.dispatch(listTodaysScheduledCrawlersAction(scheduledCrawlersPayload()));

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

// Get Crawler config list for Adding Crawlers
export const getCrawlersToAddAction = createAsyncThunk(
  'crawler/getCrawlersToAddAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_configuration/fetch_crawlers/`;
      result = await callPostApi(endpoint, payload);
      if (!result.success) throw new Error(result.error);

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

// Crawler runs actions
export const listCrawlerRunHistoryAction = createAsyncThunk(
  'crawler/listCrawlerRunHistoryAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      let endpoint = `crawler_run/`;

      // adding querystringParams and params to endpoint
      let resetState = payload?.resetState ? true : false;
      if (payload?.page >= 0) endpoint += `?page=${payload.page}`;
      if (payload?.searchParams?.length > 0) endpoint += `&search=${payload.searchParams}`;
      if (payload?.orderParams?.length > 0) endpoint += `&ordering=${payload.orderParams}`;
      if (payload?.filterParams?.length > 0) endpoint += payload.filterParams;

      result = await callGetApi(endpoint);
      if (!result.success) throw new Error(result.error);
      const response = result.data;

      const STATUS = {
        crawler_in_progress: 'In Progress',
        crawler_completed: 'Completed',
        crawler_created: 'Created',
        crawler_failed: 'Failed'
      };

      let runHistoryList = response.results.map((crawlerRun) => {
        return {
          id: crawlerRun.id,
          name: crawlerRun.crawler_config_name,
          type: crawlerRun.crawler_config_type,
          startDate: crawlerRun.start_date,
          endDate: crawlerRun.end_date,
          status: STATUS[crawlerRun.status] || 'Failed',
          statusDetails: crawlerRun.status_details,
          orgId: crawlerRun.org_id,
          fileInformations: crawlerRun.file_informations,
          crawlerConfigId: crawlerRun.crawler_config_id,
          timeTaken: getFormatedTime(crawlerRun.start_date, crawlerRun.end_date)
        };
      });

      return {
        success: true,
        page: payload.page,
        runHistoryList: runHistoryList,
        totalRuns: response.count,
        reset: resetState
      };
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const getCrawlerRunHistoryAction = createAsyncThunk(
  'crawler/getCrawlerRunHistoryAction',
  async (crawlerRunId, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_run/${crawlerRunId}`;
      result = await callGetApi(endpoint);
      if (!result.success) throw new Error(result.error);

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const runCrawlerAction = createAsyncThunk(
  'crawler/runCrawlerAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_configuration/run_crawler`;

      if (payload.runMultiple) {
        // Run multiple crawlers
        const promises = payload.selectedCrawlers.map(async (crawlerConfigId) => {
          return callPostApi(endpoint, {
            crawler_config_id: crawlerConfigId,
            org_id: process.env.REACT_APP_ORG_ID
          });
        });

        // Await all promises to resolve
        const allResponses = await Promise.all(promises);

        result = allResponses[0];
      } else {
        result = await callPostApi(endpoint, {
          crawler_config_id: payload.crawler_config_id,
          org_id: process.env.REACT_APP_ORG_ID
        });
      }
      if (!result.success) throw new Error(result.error);
      setTimeout(async () => {
        await thunkAPI.dispatch(listCrawlerRunHistoryAction({ page: 1 }));
        await thunkAPI.dispatch(getDashboardSummaryAction());
        await thunkAPI.dispatch(listCrawlerConfigsAction({ page: 1 }));
      }, 2000);

      return {
        success: true,
        data: result.data,
        runCount: payload.selectedCrawlers.length || 1
      };
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const refreshCrawlerHistoryStatus = createAsyncThunk(
  'crawler/refreshCrawlerHistoryStatus',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_run/refresh_status`;
      result = await callPostApi(endpoint, {
        crawler_run_id_list: payload.crawlerRunIdList
      });
      if (!result.success) throw new Error(result.error);

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

// Crawler Dashboard API calls
export const listTodaysScheduledCrawlersAction = createAsyncThunk(
  'crawler/listTodaysScheduledCrawlersAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      let endpoint = `crawler_configuration/fetch_schedules/`;

      result = await callPostApi(endpoint, payload);
      if (!result.success) throw new Error(result.error);
      let response = result.data;

      let todaysScheduledCrawlers = response.map((scheduledCrawler) => {
        return {
          id: scheduledCrawler.id,
          name: scheduledCrawler.name,
          type: scheduledCrawler.type,
          urlLink: scheduledCrawler.url_link,
          schedule: scheduledCrawler.schedule,
          createdDate: scheduledCrawler.created_date,
          updatedDate: scheduledCrawler.updated_date,
          orgId: scheduledCrawler.org_id,
          deleted: scheduledCrawler.deleted,
          crawlerRuns: scheduledCrawler.crawler_runs
        };
      });

      return {
        success: true,
        todaysScheduledCrawlers: todaysScheduledCrawlers
      };
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const deleteCrawlerRunScheduleAction = createAsyncThunk(
  'crawler/deleteCrawlerScheduleAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      let endpoint = `crawler_configuration/delete_schedule/${payload.id}`;
      result = await callDeleteApi(endpoint);
      if (!result.success) throw new Error(result.error);

      return result;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const getDashboardSummaryAction = createAsyncThunk(
  'crawler/getDashboardSummaryAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_run/dashboard/insights`;
      result = await callGetApi(endpoint);
      if (!result.success) throw new Error(result.error);

      return result.data;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const getCrawlerRunAnalyticsGraphsDataAction = createAsyncThunk(
  'crawler/getCrawlerRunAnalyticsGraphsDataAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_run/dashboard/crawler_run_analytics`;
      result = await callPostApi(endpoint, payload);
      if (!result.success) throw new Error(result.error);

      return result.data;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const getCrawlerRuntimeMetricsGraphsDataAction = createAsyncThunk(
  'crawler/getCrawlerRuntimeMetricsGraphsDataAction',
  async (payload, thunkAPI) => {
    let result = { success: false };
    try {
      const endpoint = `crawler_run/dashboard/crawler_runtime_metrics`;
      result = await callPostApi(endpoint, payload);
      if (!result.success) throw new Error(result.error);

      return result.data;
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(result);
    }
  }
);
