import React, { useEffect, useState } from 'react';
import CustomDialog from '../Dialogs/CustomDialog';
import CrawlerTimeFilter from './CrawlerTimeFilter';
import { useDispatch, useSelector } from 'react-redux';
import { setCrawlerHistoryFilters } from '../../../app/redux/slices/crawlerSlice';
import { Box, Checkbox, Divider, Typography } from '@mui/material';
import useMultiSelector from '../../../app/hooks/useMultiSelector';

const MultiSelectFilter = ({ filterData, selectedFilter, setSelectedFilter }) => {
  const { selected, selectCurrent } = useMultiSelector(selectedFilter);

  useEffect(() => {
    setSelectedFilter(selected);
  }, [selected]);

  return (
    <>
      {filterData.map((filter) => (
        <Box
          key={filter.value}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '36px',
            marginTop: '4px',
            marginLeft: '9px',
            cursor: 'pointer'
          }}
          onClick={() => selectCurrent(filter.value)}
        >
          <Checkbox sx={{ p: 0, color: '#00649499' }} checked={selected.includes(filter.value)} />
          <Typography variant="h5" sx={{ paddingLeft: '16px' }}>
            {filter.name}
          </Typography>
        </Box>
      ))}
    </>
  );
};

const CrawlerHistoryFilters = (props) => {
  const { onfilterApply, openFilterDialog, setOpenFilterDialog } = props;
  const { historyFilters } = useSelector((state) => state.crawler);
  const dispatch = useDispatch();

  const [selectedDriveTypes, setSelectedDriveTypes] = useState(historyFilters?.crawlerTypes || []);
  const [selectedStatus, setSelectedStatus] = useState(historyFilters?.status || []);

  const DRIVE_TYPES = [
    { name: 'Sharepoint', value: 'sharepoint' },
    { name: 'Onedrive', value: 'onedrive' }

    // { name: 'Google Drive', value: 'googledrive' }
  ];

  const STATUS = [
    { name: 'In Progress', value: 'crawler_in_progress' },
    { name: 'Completed', value: 'crawler_completed' },
    { name: 'Created', value: 'crawler_created' },
    { name: 'Failed', value: 'crawler_failed' }
  ];

  const handleApplyFilter = (timefilter) => {
    const newFilters = {
      crawlerTypes: selectedDriveTypes,
      status: selectedStatus,
      fromDate: timefilter.fromDate,
      toDate: timefilter.toDate,
      dateRange: timefilter.dateRange
    };

    dispatch(setCrawlerHistoryFilters(newFilters));
    onfilterApply(newFilters);
  };

  return (
    <CustomDialog
      openDialog={openFilterDialog}
      handleCloseDialog={() => setOpenFilterDialog(false)}
      padding="28px"
      crossIconStyle={{ width: '16px', height: '16px' }}
      crossIconBtnStyle={{ top: '20px', right: '24px' }}
    >
      <div className="filter__dialog">
        <Typography variant="h3" className="filter__dialog__title">
          History Filters
        </Typography>
        <Typography
          variant="subtitle"
          className="filter__dialog__subtitle"
          sx={{ textTransform: 'none' }}
        >
          Select filters to apply
        </Typography>

        <div className="filter__content">
          <div className="filter__types">
            <div className="filter__drivetype">
              <Typography variant="filterHeader" className="filter__title">
                Crawler Type:
              </Typography>
              <MultiSelectFilter
                filterData={DRIVE_TYPES}
                selectedFilter={selectedDriveTypes}
                setSelectedFilter={setSelectedDriveTypes}
              />
            </div>
            <div className="filter__status">
              <Typography variant="filterHeader" className="filter__title">
                Status:
              </Typography>
              <MultiSelectFilter
                filterData={STATUS}
                selectedFilter={selectedStatus}
                setSelectedFilter={setSelectedStatus}
              />
            </div>
          </div>

          <Divider className="filter__divider" orientation="vertical" variant="middle" flexItem />

          <CrawlerTimeFilter
            title={'CRAWLERS STARTED AT:'}
            setOpenFilterDialog={setOpenFilterDialog}
            handleApplyFilter={handleApplyFilter}
            filters={historyFilters}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default CrawlerHistoryFilters;
