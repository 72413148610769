import { Box, CircularProgress, List, ListItem, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../../styles/components/Insights/Dashboard/MostReferedFilesList.css';
import { NoDataFound } from '../../common/ErrorComponents/NoDataFound';
import { useDispatch, useSelector } from 'react-redux';
import { getMostReferedFilesListAction } from '../../../app/services/insightsService';

export const ListRow = ({ row }) => {
  const openFile = () => {
    window.open(row?.file_link, '_blank');
  };

  return (
    <List
      className="list__item"
      onClick={() => openFile()}
      sx={{ width: '100%', bgcolor: 'background.paper' }}
    >
      <ListItem className="list__item__badge">{row?.file_extension}</ListItem>
      <ListItem className="list__item__content">
        <Tooltip title={row?.file_referred} placement="top">
          <Typography variant="h5" className="list__item__title">
            {row?.file_referred}
          </Typography>
        </Tooltip>
        <Typography variant="body1" className="list__item__subtitle">
          {row?.reference_count}
        </Typography>
      </ListItem>
    </List>
  );
};

const MostReferedFilesList = () => {
  // const { listItems, listStyle = {} } = props;
  const [loading, setLoading] = useState(true);
  const [listItems, setListItems] = useState();
  const dispatch = useDispatch();
  const { mostReferedFilesList } = useSelector((state) => state.insights);

  useEffect(() => {
    dispatch(getMostReferedFilesListAction())
      .unwrap()
      .then(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setListItems(mostReferedFilesList);
  }, [mostReferedFilesList]);

  return (
    <Box
      className={`list__container ${
        loading || !listItems?.length ? 'list__container__loading' : ''
      }`}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {listItems.map((listRow, index) => (
            <ListRow key={index} row={listRow} />
          ))}
        </>
      )}
      {!listItems?.length && !loading && <NoDataFound text="No Data Found" />}
    </Box>
  );
};

export default MostReferedFilesList;
