import React from 'react';
import '../../../styles/components/Insights/Dashboard/DashboardSummaryCard.css';
import { Stack, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';

const DashboardSummaryCard = (props) => {
  const { value, title, getIcon, iconBackgroundColor, loading } = props;

  return (
    <div className="summary__card">
      <Stack direction="row" alignItems={'center'}>
        <div
          className="summary__card__icon__container"
          style={{ backgroundColor: iconBackgroundColor }}
        >
          {getIcon('summary__card__icon')}
        </div>
        <Typography variant="body1" className="summary__card__title">
          {title}
        </Typography>
      </Stack>
      <Typography variant="h4" className="summary__card__value">
        {loading ? <ClipLoader color="#006494" size={20} speedMultiplier={0.7} /> : value}
      </Typography>
    </div>
  );
};

export default DashboardSummaryCard;
