import { TableCell, TableRow, Typography, Tooltip } from '@mui/material';
import React from 'react';
import { CheckboxTableCell } from './CheckboxTableCell';

const CustomTableRow = (props) => {
  const { row, selectRow, selected, rowCellsFormat, currentPage, tableCellStyles } = props;

  return (
    <TableRow hover>
      {rowCellsFormat.map((cell, index) => (
        <TableCell sx={tableCellStyles} key={`${row.id}-${index}`} align={cell.align}>
          {cell.customComponent ? (
            row?.customComponent(currentPage)
          ) : cell.checkbox ? (
            <CheckboxTableCell
              id={row.id}
              label={row[cell.id]}
              selectRow={selectRow}
              selected={selected}
            />
          ) : (
            <Tooltip title={row[cell.id]} placement="top">
              <Typography
                variant="tablecell"
                sx={{
                  textTransform: cell.textTransform ? 'capitalize' : 'none',
                  fontSize: '15px !important'
                }}
              >
                {row[cell.id]}
              </Typography>
            </Tooltip>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default CustomTableRow;
