import React from 'react';
import { Box } from '@mui/material';
import '../../styles/pages/Chatbot/Chatbot.css';
import ChatView from '../../components/Chatbot/ChatView';

const Chatbot = () => {
  return (
    <>
      <Box className="chatbot__component">
        <ChatView />
      </Box>
    </>
  );
};

export default Chatbot;
