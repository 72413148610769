// Contains API calls related to authentication & onboarding

import { callGetApi, callPostApi } from './apiCallService';

export const onboardUser = async (payload) => {
  let result = { success: false };
  try {
    const endpoint = `organization/on_boarding`;
    result = await callPostApi(endpoint, payload);
    if (!result.success) throw new Error(result.error);

    return result;
  } catch (error) {
    console.log(error);

    return result;
  }
};

export const configureCrawler = async (payload) => {
  let result = { success: false };
  try {
    const endpoint = `/organization/on_boarding/crawler_config_set`;
    result = await callPostApi(endpoint, payload);
    if (!result.success) throw new Error(result.error);

    return result;
  } catch (error) {
    console.log(error);

    return result;
  }
};

export const integrateTeamsBot = async (payload) => {
  let result = { success: false };
  try {
    const endpoint = `/organization/on_boarding/teams_config_set`;
    result = await callPostApi(endpoint, payload);
    if (!result.success) throw new Error(result.error);

    return result;
  } catch (error) {
    console.log(error);

    return result;
  }
};

export const downloadTeamsBotZip = async (payload) => {
  let result = { success: false };
  try {
    const endpoint = `/organization/on_boarding/get_bot_files_url`;
    result = await callGetApi(endpoint, payload);
    if (!result.success) throw new Error(result.error);

    return result;
  } catch (error) {
    console.log(error);

    return result;
  }
};

export const getTeamsBotIntegrationGuideLink = async () => {
  let result = { success: false };
  try {
    const endpoint = `organization/on_boarding/get_bot_upload_handbook`;
    result = await callGetApi(endpoint);
    if (!result.success) throw new Error(result.error);

    return result;
  } catch (error) {
    console.log(error);

    return result;
  }
};

export const getUserGuideLink = async () => {
  let result = { success: false };
  try {
    const endpoint = `organization/on_boarding/get_how_to_handbook`;
    result = await callGetApi(endpoint);
    if (!result.success) throw new Error(result.error);

    return result;
  } catch (error) {
    console.log(error);
  }
};
