import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import '../../styles/pages/Login/Login.css';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../app/msal-config/authConfig';
import iAskLogo from './../../assets/iask_logo.svg';

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = (e) => {
    e.preventDefault();
    localStorage.clear();
    localStorage.setItem('loginStarted', 'true');
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <Box className="loginpage__container">
      <Box className="loginpage__content">
        <img src={iAskLogo} className="loginpage__logo" />
        <Typography variant="h5">
          Elevating Enterprise Intelligence with LLM-Powered AI Precision
        </Typography>

        <Button className="login__btn" variant="contained" onClick={handleLogin}>
          Sign in <span className="login__or">or</span> Sign up
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
