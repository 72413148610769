import React from 'react';
import { Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard_logo.svg';
import '../../styles/pages/Crawler/Crawler.css';
import CustomTabs from '../../components/common/Tabs/CustomTabs';
import { Outlet } from 'react-router-dom';

const Crawler = () => {
  const tabs = [
    { tabName: 'Dashboard', icon: <DashboardIcon style={{ fontSize: 24 }} /> },
    { tabName: 'History', icon: <HistoryIcon style={{ fontSize: 24 }} /> },
    { tabName: 'Configuration', icon: <SettingsIcon style={{ fontSize: 24 }} /> }
  ];

  return (
    <>
      <Box className="crawler__component">
        <CustomTabs tabs={tabs} />
        <Box className="crawler__tabcontent">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Crawler;
