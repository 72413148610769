import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardSummaryAction } from '../services/insightsService';

const useInsightsDashboard = () => {
  const [summaryData, setSummaryData] = useState([
    {
      title: 'Total conversations',
      value: 0
    },
    {
      title: 'Avg session time(mins)',
      value: 0
    },
    {
      title: 'Total users',
      value: 0
    },
    {
      title: "Today's conversation",
      value: 0
    }
  ]);
  const [summaryLoading, setSummaryLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // get and set summary data from api
    setSummaryLoading(true);
    dispatch(getDashboardSummaryAction())
      .unwrap()
      .then((res) => {
        setSummaryLoading(false);

        const updatedSummaryData = [
          { title: 'Total conversations', value: res?.total_conversations || 0 },
          {
            title: 'Avg. session time(mins)',
            value: Math.round(res?.max_average_session_time_minutes / 60) || 0
          },
          { title: 'Total users', value: res?.total_users || 0 },
          { title: "Today's conversation", value: res?.todays_total_conversation || 0 }
        ];
        setSummaryData(updatedSummaryData);
      })
      .catch((err) => console.log(err));
  }, []);

  return {
    summaryData,
    summaryLoading
  };
};

export default useInsightsDashboard;
