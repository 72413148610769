/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import CustomDialog from '../Dialogs/CustomDialog';
import InsightsTimeFilter from './InsightsTimeFilter';
import { useDispatch, useSelector } from 'react-redux';
import { setInsightsAuditFilters } from '../../../app/redux/slices/insightsSlice';
import { Box, Checkbox, Typography } from '@mui/material';
import useMultiSelector from '../../../app/hooks/useMultiSelector';
import '../../../styles/components/common/Filters/InsightsAuditFilters.css';

const InsightsAuditFilters = (props) => {
  const { onfilterApply, openFilterDialog, setOpenFilterDialog } = props;
  const { auditFilters } = useSelector((state) => state.insights);
  const dispatch = useDispatch();

  const handleApplyFilter = (timefilter) => {
    const newFilters = {
      fromDate: timefilter.fromDate,
      toDate: timefilter.toDate,
      dateRange: timefilter.dateRange
    };

    dispatch(setInsightsAuditFilters(newFilters));
    onfilterApply(newFilters);
  };

  return (
    <CustomDialog
      openDialog={openFilterDialog}
      handleCloseDialog={() => setOpenFilterDialog(false)}
      padding="28px"
      crossIconStyle={{ width: '16px', height: '16px' }}
      crossIconBtnStyle={{ top: '20px', right: '24px' }}
    >
      <div className="filter__dialog">
        <Typography variant="h3" className="filter__dialog__title">
          Conversation Filters
        </Typography>
        <Typography
          variant="subtitle"
          className="filter__dialog__subtitle"
          sx={{ textTransform: 'none' }}
        >
          Select filters to apply
        </Typography>

        <div className="filter__content">
          <InsightsTimeFilter
            title={'CONVERSATIONS OF:'}
            setOpenFilterDialog={setOpenFilterDialog}
            handleApplyFilter={handleApplyFilter}
            filters={auditFilters}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default InsightsAuditFilters;
