//********************** BORDER RADIUS SHAPES **********************//
const shapes = {
  borderRadius: 4,
  borderRadiusSm: 8,
  borderRadiusMd: 12,
  borderRadiusLg: 16,
  borderRadiusXl: 24
};

export default shapes;
