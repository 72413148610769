import { Box, Button, Stack, Typography } from '@mui/material';
import { Carousel } from 'antd';
import AddCrawlerImg from './../../assets/imgs/addcrawler.png';
import AddCrawlerTypesImg from './../../assets/imgs/addcrawlertypes.png';
import CrawlerImportImg from './../../assets/imgs/crawler_import.png';
import RunCrawlerImg from './../../assets/imgs/run_crawler.png';
import '../../styles/components/Onboard/GettingStartedStep.css';
import { getUserGuideLink } from '../../app/services/authService';
import Toast from '../common/Toast/Toast';

export const GettingStartedStep = () => {
  const handleOpenUserGuide = async (e) => {
    e.preventDefault();
    getUserGuideLink()
      .then((result) => {
        if (result.success) {
          window.open(result?.data?.download_url, '_blank');
        } else throw new Error('Failed to download zip file');
      })
      .catch((error) => {
        <Toast severity="error" message={'Failed to download zip file'} />;
        console.log(error);
      });
  };

  return (
    <Box style={{ height: '100%' }}>
      <Typography className="onboard_steps_title" variant="h3">
        Get started with Privou
      </Typography>
      <Carousel autoplaySpeed={2500} style={{ marginTop: '16px', height: '100%' }} autoplay>
        <Box>
          <Typography className="onboard__getstarted__step__subtitle" variant="h5">
            Add crawler
          </Typography>
          <img loading="eager" className="onboard__getstarted__step__img" src={AddCrawlerImg} />
        </Box>
        <Box>
          <Typography className="onboard__getstarted__step__subtitle" variant="h5">
            Manually add or Import crawler
          </Typography>
          <img
            loading="eager"
            className="onboard__getstarted__step__img"
            src={AddCrawlerTypesImg}
          />
        </Box>
        <Box>
          <Typography className="onboard__getstarted__step__subtitle" variant="h5">
            Configure crawler
          </Typography>
          <img loading="eager" className="onboard__getstarted__step__img" src={CrawlerImportImg} />
        </Box>
        <Box>
          <Typography className="onboard__getstarted__step__subtitle" variant="h5">
            Run the crawler
          </Typography>
          <img loading="eager" className="onboard__getstarted__step__img" src={RunCrawlerImg} />
        </Box>
      </Carousel>
      <Stack sx={{ mt: 2 }}>
        <Typography className="onboard__getstarted__userguide__text" variant="body1">
          For more detailed information on Privou download the below User Guide
        </Typography>
        <Button
          className="onboard__getstarted__userguide__btn"
          variant="contained"
          onClick={handleOpenUserGuide}
        >
          User Guide
        </Button>
      </Stack>
    </Box>
  );
};
