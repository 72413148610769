export const getCrawlerAnalyticsGraphOption = (
  xAxisType,
  xAxisData,
  yAxisType,
  graphData,
  graphType
) => {
  let series = [];
  if (graphData && graphData[0] && graphData[0]?.length > 0) {
    series = [
      { name: 'Passed Crawler runs', color: '#4CAF50' },
      { name: 'Failed Crawler runs', color: '#EF5350' }
    ].map((params, sid) => {
      return {
        name: params?.name,
        color: params?.color,
        global: false,
        type: graphType,
        stack: 'Total',
        barWidth: '60%',
        label: graphData[sid],
        data: graphData[sid]
      };
    });
  }

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    grid: {
      top: '15%',
      right: '5%',
      left: '8%',
      bottom: '20%'
    },
    xAxis: {
      type: xAxisType,
      data: xAxisData
    },
    yAxis: {
      type: yAxisType,
      name: 'Run count',
      nameTextStyle: {
        color: '#324556',
        fontWeight: 'bold'
      },
      splitLine: {
        show: true
      }
    },
    series: series,
    dataZoom: [
      {
        type: 'slider',
        show: true,
        start: 50,
        end: 100,
        handleSize: 15,
        height: 1,
        bottom: '6%',
        width: '70%',
        left: '15%'
      },
      {
        type: 'inside',
        start: 10,
        end: 100
      }
    ]
  };
};
