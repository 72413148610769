export function isAccessTokenExpired(expiresOnTime) {
  // Parse the expiration time from the token
  const expiresOn = new Date(expiresOnTime) / 1000;

  // Get the current time
  const currentTime = Date.now() / 1000;

  // Compare the expiration time with the current time
  return expiresOn <= currentTime;
}
