import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SearchBar from '../../common/Searchbar/SearchBar';
import CustomTable from '../../common/Table/CustomTable';
import '../../../styles/components/Crawler/History/History.css';
import useCrawlerHistory from '../../../app/hooks/useCrawlerHistory';
import useMultiSelector from '../../../app/hooks/useMultiSelector';
import TableMenuBar from '../../common/Table/TableMenuBar';
import useSearch from '../../../app/hooks/useSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHistoryOrderParam,
  setHistorySearchParam
} from '../../../app/redux/slices/crawlerSlice';

const columnHeaders = [
  { id: 'name', checkbox: false, sort: true, label: 'Crawler Name', align: 'left' },
  {
    id: 'type',
    checkbox: false,
    sort: true,
    label: 'Crawler Type',
    align: 'left',
    textTransform: 'capitalize'
  },
  { id: 'status', checkbox: false, sort: true, label: 'Status', align: 'left' },
  { id: 'startDate', checkbox: false, sort: true, label: 'Start Date', align: 'left' },
  { id: 'timeTaken', checkbox: false, sort: false, label: 'Time Taken', align: 'left' },
  {
    id: 'view',
    checkbox: false,
    customComponent: true,
    sort: false,
    label: 'View',
    align: 'left'
  }
];

const History = () => {
  const theme = useTheme();
  const historyHeaderRef = useRef();
  const tableContainerRef = useRef();
  const dispatch = useDispatch();

  const {
    loading,
    pageCount,
    crawlerRunsHistoryList,
    getCrawlerRunHistory,
    currentPage,
    setCurrentPage,
    onfilterApply
  } = useCrawlerHistory();

  const { historyFilters, historySearchParam, historyOrderParam } = useSelector(
    (state) => state.crawler
  );

  const { searchText, setSearchText, onSearch, onClearSearch } = useSearch();
  const { selected, selectAll, selectCurrent } = useMultiSelector();
  const [tableRowsData, setTableRowsData] = useState(crawlerRunsHistoryList || []);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(columnHeaders[3].id);

  const paramsMap = {
    name: 'crawler_config_id__name',
    type: 'crawler_config_id__type',
    status: 'status',
    startDate: 'start_date',
    timeTaken: 'time_taken'
  };

  useEffect(() => {
    setTableRowsData(crawlerRunsHistoryList);
  }, [crawlerRunsHistoryList]);

  const getSortedList = (property) => {
    let nextOrder = 'asc'; //Default to ascending order

    if (orderBy === property) {
      //If clicking on the same column, toggle the order
      nextOrder = order === 'asc' ? 'desc' : 'asc';
    }

    let orderParam = nextOrder === 'asc' ? '' : '-';

    orderParam += paramsMap[property];
    dispatch(setHistoryOrderParam(orderParam));
    setCurrentPage(1);
    getCrawlerRunHistory(1, historyFilters, historySearchParam, orderParam, true);

    // Update state to reflect the new order and property
    setOrder(nextOrder);
    setOrderBy(property);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    onSearch(setHistorySearchParam, getCrawlerRunHistory, historyFilters);
  };

  useEffect(() => {
    if (historyOrderParam) {
      const paramsMapReverse = Object.entries(paramsMap).reduce((result, [key, value]) => {
        result[value] = key;

        return result;
      }, {});

      const isDescOrder = historyOrderParam.includes('-');

      const property =
        paramsMapReverse[isDescOrder ? historyOrderParam.substring(1) : historyOrderParam];

      setOrder(isDescOrder ? 'desc' : 'asc');
      setOrderBy(property);
    }
  }, [historyOrderParam]);

  return (
    <Box container className="history__container">
      <Box ref={historyHeaderRef} className="history__context">
        <Box container item className="history__header">
          <Typography variant="tabHeader">History</Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: theme.typography.fontWeightMedium, textTransform: 'none' }}
          >
            Crawler run history overview
          </Typography>
        </Box>

        <div className="history__tablemenubar">
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={handleSearch}
            onClear={() => {
              setCurrentPage(1);
              onClearSearch(setHistorySearchParam, getCrawlerRunHistory, historyFilters);
            }}
          />{' '}
          <TableMenuBar
            openFilterDialog={openFilterDialog}
            setOpenFilterDialog={setOpenFilterDialog}
            onfilterApply={onfilterApply}
            setSearchText={setSearchText}
            currentPage={currentPage}
            crawlerRunsHistoryList={crawlerRunsHistoryList}
            getCrawlerRunHistory={() =>
              getCrawlerRunHistory(
                currentPage,
                historyFilters,
                historySearchParam,
                historyOrderParam,
                false
              )
            }
          />
        </div>
      </Box>
      <Box
        ref={tableContainerRef}
        sx={{ height: `calc(100% - ${historyHeaderRef?.current?.clientHeight}px)` }}
        className="history__list"
      >
        <CustomTable
          rows={tableRowsData}
          columnHeaders={columnHeaders}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selected={selected}
          selectAll={selectAll}
          selectCurrent={selectCurrent}
          loading={loading}
          getSortedList={getSortedList}
        />
      </Box>
    </Box>
  );
};

export default History;
