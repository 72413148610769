import { GlobalStyles as GlobalStyle } from '@mui/material';

//********************** GLOBAL STYLES **********************//
export default function GlobalStyles() {
  return (
    <GlobalStyle
      styles={{
        '*': { margin: 0, padding: 0, boxSizing: 'border-box' },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          scrollBehavior: 'smooth'
        },
        body: { width: '100%', height: '100%' },
        '#root': { width: '100%', height: '100%' },
        img: { display: 'block', width: '100%', height: '100%' }
      }}
    />
  );
}
