/* eslint-disable no-unused-vars */
/* eslint-disable lines-around-comment */
import React, { useState } from 'react';
import '../../../styles/components/Insights/Dashboard/Dashboard.css';
import { Box, Grid, Stack, Typography } from '@mui/material';
import DashboardSummaryCard from './DashboardSummaryCard';
import { getInsightsAnalyticsGraphOption } from './getInsightsAnalyticsGraphOption';
import DashboardGraphFilters from './DashboardGraphFilters';
import { useSelector } from 'react-redux';
import Graph from '../../common/Graph/Graph';
import ConversationsIcon from '../../../assets/imgs/conversations.png';
import TotalConversationsIcon from '../../../assets/imgs/total_conversations.png';
import TotalUsersIcon from '../../../assets/imgs/total_users.png';
import AvgSessionTimeIcon from '../../../assets/imgs/avg_session_time.png';
import MostReferedFilesList from './MostReferedFilesList.jsx';
import useInsightsDashboard from '../../../app/hooks/useInsightsDashboard.js';
import { isObjectEmpty } from '../../../app/utils/objectEmpty';

const DashboardHeaders = ({ header, subheader }) => {
  return (
    <Stack>
      <Typography variant="h5" className="dashboard__summary__title">
        {header}
      </Typography>
      <Typography variant="h6" className="dashboard__summary__subtitle">
        {subheader}
      </Typography>
    </Stack>
  );
};

const Dashboard = () => {
  const { summaryData, summaryLoading } = useInsightsDashboard();
  const [selectedDataType, setSelectedDataType] = useState('Active Users');

  const getSummaryIcons = {
    0: (styleClassName) => <img className={styleClassName} src={TotalConversationsIcon} />,
    1: (styleClassName) => <img className={styleClassName} src={AvgSessionTimeIcon} />,
    2: (styleClassName) => <img className={styleClassName} src={TotalUsersIcon} />,
    3: (styleClassName) => <img className={styleClassName} src={ConversationsIcon} />
  };
  const getSummaryCardColor = ['#f1e3fa', '#fce7de', '#FBECCA', '#E9F9EE'];
  const { activeUsersAndConversationsGraphData } = useSelector((state) => state.insights);

  const [activeUsersAndConversationsGraphLoader, setActiveUsersAndConversationsGraphLoader] =
    useState(false);

  return (
    <Box className="insights__dashboard">
      {/* Dashboard summary content */}
      <Box className="insights__dashboard__summary">
        <DashboardHeaders
          header={'Insights Summary'}
          subheader={'User conversations statistics since start'}
        />
        <Grid
          container
          flexDirection="row"
          className="insights__dashboard__summary__content"
          gap={4}
        >
          {summaryData?.map((summaryCard, index) => (
            <Grid item xs key={summaryCard.title}>
              <DashboardSummaryCard
                title={summaryCard.title}
                value={summaryCard.value}
                getIcon={getSummaryIcons[index]}
                iconBackgroundColor={getSummaryCardColor[index]}
                loading={summaryLoading}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Dashboard graphs content */}
      <Box className="insights__dashboard__container">
        <Grid container flexDirection="row" className="insights__dashboard__content" gap={4}>
          <Grid item xs={7.5} sx={{ height: '100%' }}>
            <Box
              display="flex"
              style={{ height: '100%', width: '100%', flexDirection: 'column' }}
              className="insights__dashboard__graph__container"
            >
              <Box className="insights__dashboard__header">
                <DashboardHeaders
                  header={'Conversations Analytics'}
                  subheader={'Visualizing conversations over time'}
                />
                <DashboardGraphFilters
                  selectedDataType={selectedDataType}
                  setSelectedDataType={setSelectedDataType}
                  setLoading={setActiveUsersAndConversationsGraphLoader}
                />
              </Box>
              <Box
                display="flex"
                flex={1}
                flexDirection={{ xs: 'column' }}
                style={{ width: '100%' }}
              >
                <Graph
                  graphType="bar"
                  option={
                    !isObjectEmpty(activeUsersAndConversationsGraphData)
                      ? getInsightsAnalyticsGraphOption(
                          'category',
                          activeUsersAndConversationsGraphData?.dates,
                          'value',
                          selectedDataType === 'Active Users'
                            ? activeUsersAndConversationsGraphData?.active_users
                            : activeUsersAndConversationsGraphData?.conversation_count,
                          selectedDataType,
                          'bar'
                        )
                      : null
                  }
                  graphStyle={{ height: '100%', width: '100%' }}
                  loading={activeUsersAndConversationsGraphLoader}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs sx={{ height: '100%' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box className="insights__dashboard__table__container">
                <Box className="insights__dashboard__header">
                  <DashboardHeaders
                    header={'Most Referred Files'}
                    subheader={'Most referred files for all conversations'}
                  />
                </Box>
                <Box className="insights__dashboard__table__content">
                  <MostReferedFilesList />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
