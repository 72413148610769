import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/redux/store';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { b2cPolicies, msalConfig, protectedResources } from './app/msal-config/authConfig';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
const accounts = msalInstance.getAllAccounts();
if (accounts.length == 1) {
  const account = accounts[0];
  if (account?.tenantId === msalConfig.auth.tenantId) {
    msalInstance.setActiveAccount(account);
  }
} else if (accounts.length > 1) {
  accounts.forEach((account) => {
    if (account?.tenantId === msalConfig.auth.tenantId) {
      msalInstance.setActiveAccount(account);
    }
  });
}

async function loginRedirect() {
  try {
    const loginRequest = {
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      scopes: protectedResources.iaskAdminApi.scopes
    };
    await msalInstance.loginRedirect(loginRequest);
  } catch (err) {
    console.log(err);
  }
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (event.error?.name === 'InteractionRequiredAuthError') {
      loginRedirect();
    } else {
      console.log('ACQUIRE_TOKEN_FAILURE');
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error?.name === 'BrowserAuthError') {
      loginRedirect();
    } else {
      console.log('LOGIN FAILURE');
    }
  } else {
    console.log('Callback finished');
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App instance={msalInstance} />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
