import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import '../../../styles/components/Insights/Auditing/AuditingActions.css';
import CustomDialog from '../../common/Dialogs/CustomDialog';
import { listInsightsConversationAuditsAction } from '../../../app/services/insightsService';
import ConversationDetailsDialog from '../../common/Dialogs/ConversationDetailsDialog';
import { Visibility } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

const AuditingActions = (props) => {
  const dispatch = useDispatch();

  const { conversationDetails } = props;
  const [openConversationDetailsDialog, setOpenConversationDetailsDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => {
    setOpenConversationDetailsDialog(false);
  };

  const handleOpenDialog = () => {
    setLoading(true);
    dispatch(listInsightsConversationAuditsAction())
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setOpenConversationDetailsDialog(true);
  };

  return (
    <>
      <Tooltip title="View conversation details" placement="top">
        <IconButton className="actions__viewiconbtn" onClick={handleOpenDialog}>
          <Visibility className="actions__viewicon" />
        </IconButton>
      </Tooltip>

      <CustomDialog
        openDialog={openConversationDetailsDialog}
        handleCloseDialog={handleCloseDialog}
      >
        <ConversationDetailsDialog conversationDetails={conversationDetails} loading={loading} />
      </CustomDialog>
    </>
  );
};

export default AuditingActions;
