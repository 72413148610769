/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCrawlerConfigAction,
  deleteCrawlerConfigAction,
  editCrawlerConfigAction,
  listCrawlerConfigsAction,
  runCrawlerAction
} from '../services/crawlerService';
import ConfigurationActions from '../../components/Crawler/Configuration/ConfigurationActions.jsx';
import { getFormatedDate } from '../utils/dateFormater';
import { getValidatedFilterParams } from '../utils/getValidatedFilterParams.js';
import { setConfigSearchParam } from '../redux/slices/crawlerSlice.js';

const useCrawlerConfigs = () => {
  const dispatch = useDispatch();

  const {
    crawlerConfigurations,
    totalCrawlers,
    configFilters,
    configSearchParam,
    configOrderParam
  } = useSelector((state) => state.crawler);
  const [crawlersList, setCrawlersList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 10;

  const runCrawler = (payload) => {
    let SUCCESS_MESSAGE = { ok: true, message: 'Crawler run triggered successfully.' };
    let ERROR_MESSAGE = { ok: false, message: 'Failed to trigger crawler run.' };

    return dispatch(runCrawlerAction(payload))
      .unwrap()
      .then((res) => {
        SUCCESS_MESSAGE = {
          ...SUCCESS_MESSAGE,
          message: `${res.runCount} crawler${
            res.runCount > 1 ? 's' : ''
          } triggered to run successfully.`
        };

        if (res.success) return SUCCESS_MESSAGE;
        else return ERROR_MESSAGE;
      })
      .catch((err) => {
        console.log(err);

        // ERROR_MESSAGE = { ...ERROR_MESSAGE, message: err.message };
        return ERROR_MESSAGE;
      });
  };

  const addCrawlerConfig = (payload) => {
    const SUCCESS_MESSAGE = { ok: true, message: 'Crawler configuration added successfully.' };
    const ERROR_MESSAGE = { ok: false, message: 'Failed to add the crawler configuration.' };

    return dispatch(addCrawlerConfigAction(payload))
      .unwrap()
      .then((res) => {
        if (res.success) return SUCCESS_MESSAGE;
        else return ERROR_MESSAGE;
      })
      .catch((err) => {
        console.log(err);

        return ERROR_MESSAGE;
      });
  };

  const editCrawlerConfig = (payload) => {
    const SUCCESS_MESSAGE = { ok: true, message: 'Crawler configuration updated successfully.' };
    const ERROR_MESSAGE = { ok: false, message: 'Failed to update the crawler configuration.' };

    return dispatch(editCrawlerConfigAction(payload))
      .unwrap()
      .then((res) => {
        if (res.success) return SUCCESS_MESSAGE;
        else return ERROR_MESSAGE;
      })
      .catch((err) => {
        console.log(err);

        return ERROR_MESSAGE;
      });
  };

  const deleteCrawlerConfig = (payload) => {
    let SUCCESS_MESSAGE = { ok: true, message: 'Crawler configuration deleted successfully.' };
    const ERROR_MESSAGE = { ok: false, message: 'Failed to delete the crawler configuration.' };

    return dispatch(deleteCrawlerConfigAction(payload))
      .unwrap()
      .then((res) => {
        if (res.success) return SUCCESS_MESSAGE;
        else return ERROR_MESSAGE;
      })
      .catch((err) => {
        console.log(err);

        return ERROR_MESSAGE;
      });
  };

  const getFormatedCrawlersList = () => {
    // Getting Crawler Configurations for current page and formating them
    let currentCrawlersList = crawlerConfigurations[currentPage];
    currentCrawlersList = currentCrawlersList?.map((crawler) => {
      return {
        ...crawler,
        lastSynced: getFormatedDate(crawler.updatedDate),
        customComponent: (currentCrawlerConfigurationPage) => (
          <ConfigurationActions
            runCrawler={runCrawler}
            deleteCrawlerConfig={deleteCrawlerConfig}
            editCrawlerConfig={editCrawlerConfig}
            crawlerConfig={crawler}
            currentCrawlerConfigurationPage={currentCrawlerConfigurationPage}
            configFilters={configFilters}
            configSearchParam={configSearchParam}
            configOrderParam={configOrderParam}
            getCrawlerConfigs={getCrawlerConfigs}
          />
        )
      };
    });

    return currentCrawlersList;
  };

  const getCrawlerConfigs = (
    page = 1,
    newFilters = {},
    searchParams = '',
    orderParams = '',
    resetState = false
  ) => {
    try {
      setLoading(true);
      var filterParams = '';
      if (Object.keys(newFilters).length > 0) {
        try {
          filterParams += getValidatedFilterParams(
            newFilters?.crawlerTypes?.length > 1 ? 'type__in' : 'type__icontains',
            newFilters?.crawlerTypes
          );
          filterParams += getValidatedFilterParams('created_date__gte', newFilters?.fromDate);
          filterParams += getValidatedFilterParams('created_date__lte', newFilters?.toDate);
        } catch (error) {
          filterParams = '';
        }
      }

      const payload = {
        page: page,
        filterParams: filterParams,
        searchParams: searchParams,
        orderParams: orderParams,
        resetState: resetState
      };
      dispatch(listCrawlerConfigsAction(payload))
        .unwrap()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(crawlerConfigurations).length > 0) {
      setPageCount(Math.ceil(totalCrawlers / rowsPerPage));
      setCrawlersList(getFormatedCrawlersList());
    } else {
      getCrawlerConfigs(1, configFilters, configSearchParam, configOrderParam);
    }
  }, [crawlerConfigurations]);

  useEffect(() => {
    if (!(currentPage in crawlerConfigurations) && Object.keys(crawlerConfigurations).length > 0) {
      getCrawlerConfigs(currentPage, configFilters, configSearchParam, configOrderParam, false);
    }
    if (currentPage in crawlerConfigurations) {
      setCrawlersList(getFormatedCrawlersList());
    }
  }, [currentPage]);

  const onfilterApply = (newFilters) => {
    setCurrentPage(1);
    dispatch(setConfigSearchParam(''));
    getCrawlerConfigs(1, newFilters, '', '', true); // by default get crawler configs for page 1 when filters are applied.
  };

  return {
    crawlersList,
    setCrawlersList,
    pageCount,
    getCrawlerConfigs,
    currentPage,
    setCurrentPage,
    loading,
    setLoading,
    runCrawler,
    editCrawlerConfig,
    deleteCrawlerConfig,
    addCrawlerConfig,
    onfilterApply
  };
};

export default useCrawlerConfigs;
