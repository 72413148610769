import React from 'react';
import CrawlerConfigFilters from '../Filters/CrawlerConfigFilters';
import CrawlerHistoryFilters from '../Filters/CrawlerHistoryFilters';
import InsightsAuditFilters from '../Filters/InsightsAuditFilters';
import { FilterAlt, FilterAltOutlined } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useTabs } from '../../../app/hooks/useTab';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCrawlerConfigFilters,
  setCrawlerHistoryFilters
} from '../../../app/redux/slices/crawlerSlice';
import { setInsightsAuditFilters } from '../../../app/redux/slices/insightsSlice';
import '../../../styles/components/common/Table/TableMenuBar.css';
import { refreshCrawlerHistoryStatus } from '../../../app/services/crawlerService';
import { setToast } from '../../../app/redux/slices/toastSlice';

const TableMenuBar = (props) => {
  const {
    currentPage,
    openFilterDialog,
    setOpenFilterDialog,
    onfilterApply,
    getCrawlerRunHistory,
    setSearchText,
    crawlerRunsHistoryList = []
  } = props;
  const { currentTab } = useTabs();
  const { configFilters, historyFilters } = useSelector((state) => state.crawler);
  const { auditFilters } = useSelector((state) => state.insights);
  const dispatch = useDispatch();

  const FilterComponents = {
    Configuration: CrawlerConfigFilters,
    History: CrawlerHistoryFilters,
    Auditing: InsightsAuditFilters
  };

  const SelectedFilterComponent = FilterComponents[currentTab];

  const isFilterApplied =
    (currentTab === 'Configuration' && Object.keys(configFilters).length > 0) ||
    (currentTab === 'History' && Object.keys(historyFilters).length > 0) ||
    (currentTab === 'Auditing' && Object.keys(auditFilters).length > 0);

  const handleFilterApply = (filters) => {
    setSearchText('');
    onfilterApply(filters);
  };

  const handleClearFilter = () => {
    setSearchText('');
    if (currentTab === 'History') {
      dispatch(setCrawlerHistoryFilters({}));
      onfilterApply({});
    }
    if (currentTab === 'Configuration') {
      dispatch(setCrawlerConfigFilters({}));
      onfilterApply({});
    }
    if (currentTab === 'Auditing') {
      dispatch(setInsightsAuditFilters({}));
      onfilterApply({});
    }
  };

  const handleRefreshList = () => {
    if (crawlerRunsHistoryList.length === 0) return;
    const crawlerRunIdList = crawlerRunsHistoryList.map((crawlerRun) => crawlerRun.id);
    dispatch(
      refreshCrawlerHistoryStatus({
        page: currentPage,
        crawlerRunIdList: crawlerRunIdList
      })
    )
      .unwrap()
      .then((res) => {
        getCrawlerRunHistory();
        dispatch(
          setToast({
            open: true,
            title: res?.success ? 'Success' : 'Error',
            severity: res?.success ? 'success' : 'error',
            description: res?.success
              ? 'Crawler run status refreshed successfully'
              : 'Failed to refresh crawler run status',
            showDuration: 5000
          })
        );
      });
  };

  return (
    <>
      <Stack direction={'row'} className="tablemenubar__filter">
        {currentTab === 'History' && (
          <Button
            className="tablemenubar__refreshbutton"
            variant="outlined"
            color="primary"
            onClick={handleRefreshList}
          >
            Refresh
          </Button>
        )}
        <Button
          className={`tablemenubar__filterbutton ${isFilterApplied ? 'activeFilter' : ''}`}
          variant="outlined"
          color="primary"
          startIcon={isFilterApplied ? <FilterAlt /> : <FilterAltOutlined />}
          onClick={() => setOpenFilterDialog(true)}
        >
          Filter
        </Button>
        {/* Clear filters: only visible if filters are applied for Configuration or History page */}
        {isFilterApplied && (
          <Typography
            className="tablemenubar__clearfilterbutton"
            variant="h4"
            onClick={handleClearFilter}
          >
            Clear Filter
          </Typography>
        )}
      </Stack>

      {openFilterDialog && (
        <SelectedFilterComponent
          openFilterDialog={openFilterDialog}
          setOpenFilterDialog={setOpenFilterDialog}
          onfilterApply={handleFilterApply}
        />
      )}
    </>
  );
};

export default TableMenuBar;
