/* eslint-disable no-unused-vars */
import { MenuItem, Select } from '@mui/material';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import '../../../styles/components/Insights/Dashboard/DashboardGraphFilters.css';
import { useDispatch } from 'react-redux';
import { getUTCDateString } from '../../../app/utils/getUTCDateString';
import { getActiveUsersAndConversationsGraphDataAction } from '../../../app/services/insightsService';

const textFieldStyle = {
  sx: {
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.50)',
      fontSize: '13px'
    },
    fontSize: '13px',
    padding: '6.5px 12px'
  }
};

const DashboardGraphFilters = (props) => {
  const { selectedDataType, setLoading, setSelectedDataType } = props;

  const dataTypes = ['Active Users', 'Conversations'];
  const [dateRange, setDateRange] = useState();
  const { RangePicker } = DatePicker;
  const DATE_FORMAT = 'YYYY-MM-DD';
  const dispatch = useDispatch();

  const handleDefaultValue = () => {
    if (dateRange) {
      return [dayjs(dateRange[0], DATE_FORMAT), dayjs(dateRange[1], DATE_FORMAT)];
    }
    const today = dayjs().startOf('day');
    const sevenDaysAgo = today.subtract(7, 'day');
    setDateRange([sevenDaysAgo, today]);

    return [today, sevenDaysAgo];
  };

  useEffect(() => {
    try {
      if (selectedDataType || dateRange) {
        const payload = {
          insights_types: selectedDataType === 'Active Users' ? 'active_user' : 'conversation',
          start_date: getUTCDateString(dateRange[0], 'startDate'),
          end_date: getUTCDateString(dateRange[1], 'endDate')
        };

        setLoading(true);

        dispatch(getActiveUsersAndConversationsGraphDataAction(payload))
          .unwrap()
          .then(() => setLoading(false))
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedDataType, dateRange]);

  return (
    <div className="dashboard__graph__filters">
      <Select
        value={selectedDataType}
        required
        className="crawlertype__select"
        inputProps={textFieldStyle}
        onChange={(e) => setSelectedDataType(e.target.value)}
        displayEmpty
        renderValue={(selected) => {
          if (selected === '') {
            return <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Select crawler Type</span>;
          }

          return selected;
        }}
      >
        {dataTypes.map((dataType) => (
          <MenuItem className="form__selectitems" key={dataType} value={dataType}>
            {dataType}
          </MenuItem>
        ))}
      </Select>
      <RangePicker
        className="daterange__select"
        onChange={(selectedDates) => setDateRange(selectedDates)}
        value={dateRange}
        defaultValue={handleDefaultValue}
        format={DATE_FORMAT}
        size="small"
      />
    </div>
  );
};

export default DashboardGraphFilters;
