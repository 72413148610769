import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material';
import palette from './palette';
import breakPoints from './breakPoints';
import typography from './typography';
import shapes from './shapes';
import MuiOverrides from './muiOverrides';
import GlobalStyles from './globalStyles';

ThemeSettings.propTypes = {
  children: PropTypes.node
};

export default function ThemeSettings({ children }) {
  const themeOptions = {
    typography: {
      fontFamily: "'DM Sans', sans-serif"
    },
    palette: palette('light'),
    breakPoints: breakPoints(),
    shapes,
    spacing: (factor) => `${0.25 * factor}rem`
  };

  let theme = createTheme(themeOptions);
  theme = createTheme(theme, {
    typography: { ...typography(theme) }
  });

  // theme = responsiveFontSizes(theme);
  theme.components = MuiOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
