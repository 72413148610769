import { CheckCircle } from '@mui/icons-material';
import { Box, Typography, Button, CircularProgress, Stack } from '@mui/material';
import '../../styles/components/Onboard/ConfigureCrawlerStep.css';

export const ConfigureCrawlerStep = ({
  isCrawlerConfigured,
  loading,
  setLoading,
  errorInCrawlerConfiguration,
  setErrorInCrawlerConfiguration
}) => {
  const handleCrawlerConfigure = () => {
    if (isCrawlerConfigured) return;
    setLoading(true);
    setErrorInCrawlerConfiguration('');

    window.open(
      // eslint-disable-next-line no-undef
      `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_MSAL_CRAWLER_CLIENT_ID}`,
      '_self'
    );
  };

  return (
    <>
      <Box className="onboard__configure__step__title__container">
        <Typography className="onboard_steps_title" variant="h3">
          Configure the crawler
        </Typography>
        <Typography variant="h5" sx={{ mt: '12px', textTransform: 'none' }}>
          Crawlers are data source entities in Privou that connect to your Microsoft Data Sources
          (SharePoint, OneDrive).
        </Typography>
        <>
          <Typography className="onboard__teams__integrate__steps__title" variant="h4">
            Steps for configuring crawler
          </Typography>
          <Box className="onboard__teams__configure__steps">
            <Stack sx={{ pb: '12px' }}>
              <ul className="onboard__configure__steps__list">
                <li className="onboard__configure__steps__list__item">
                  Click the below button to install App Registration in your Azure Account.
                </li>
                <li className="onboard__configure__steps__list__item">
                  Please follow the steps and review the permissions for installation of the App
                  Registration.
                </li>
              </ul>
              <Typography variant="h6" sx={{ ml: 2, fontSize: '13px', textTransform: 'none' }}>
                [ Note: Privou App needs permissions tenant-wide, hence please use Microsoft Account
                with Global Administrator Permission. Kindly use the same email address that was
                used for signing in. ]
              </Typography>
              <Button
                className="onboard__configure__btn"
                variant="contained"
                onClick={handleCrawlerConfigure}
                startIcon={isCrawlerConfigured ? <CheckCircle /> : null}
                sx={{ cursor: isCrawlerConfigured ? 'default' : 'pointer' }}
              >
                {isCrawlerConfigured ? 'Configured' : 'Configure'}
                {loading && <CircularProgress size={16} sx={{ ml: 1.5, color: 'white' }} />}
              </Button>
              {errorInCrawlerConfiguration?.length > 0 && (
                <Typography variant="h6" sx={{ mt: 1, ml: '12px', fontSize: '13px', color: 'red' }}>
                  {errorInCrawlerConfiguration}
                </Typography>
              )}
            </Stack>
          </Box>
        </>
      </Box>
    </>
  );
};
