/* eslint-disable no-unused-vars */
import React from 'react';
import '../../../styles/components/common/Dialogs/ConversationDetailsDialog.css';
import { CircularProgress, Stack, Typography } from '@mui/material';
import iAskLogo from '../../../assets/imgs/iask_logo_blue.svg';
import userProfileLogo from '../../../assets/imgs/user_profile_blue.svg';
import { getFormattedResponse } from '../../../app/utils/responseFormatter';

const getConversations = (messages) => {
  const listItems = messages.map((message, index) => {
    let bubbleClass = 'detailsdialog__bot_response';
    let bubbleDirection = '';

    if (message.type === 0) {
      bubbleClass = 'detailsdialog__user_question';
      bubbleDirection = 'detailsdialog__bubble_direction_reverse';
    }

    return (
      <div className={`detailsdialog__bubble_container ${bubbleDirection}`} key={index}>
        <img className={`detailsdialog__img_circle`} src={message.image} />
        <div className={`detailsdialog__bubble ${bubbleClass} detailsdialog__bubble_text`}>
          {getFormattedResponse(message.text)}
        </div>
      </div>
    );
  });

  return listItems;
};

const ConversationDetailsDialog = ({ conversationDetails, loading }) => {
  const messages = [
    {
      type: 0,
      image: userProfileLogo,
      text: conversationDetails.question
    },
    {
      type: 1,
      image: iAskLogo,
      text: conversationDetails.answer
    }
  ];

  const chatList = getConversations(messages);

  return (
    <div className="detailsdialog">
      <Typography variant="h4" className="detailsdialog__title">
        Conversation Details
      </Typography>
      {loading ? (
        <div className="detailsdialog__loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="detailsdialog__chats">
          <div className="detailsdialog__chat_list">{chatList}</div>
        </div>
      )}
    </div>
  );
};

export default ConversationDetailsDialog;
