import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listInsightsConversationAuditsAction } from '../services/insightsService';
import { getFormatedDate } from '../utils/dateFormater';
import { getValidatedFilterParams } from '../utils/getValidatedFilterParams';
import AuditingActions from '../../components/Insights/Auditing/AuditingActions';
import { setAuditSearchParam } from '../redux/slices/insightsSlice';

const useInsightsAudit = () => {
  const dispatch = useDispatch();

  const {
    insightsConversationAudits,
    totalCount,
    auditFilters,
    auditSearchParam,
    auditOrderParam
  } = useSelector((state) => state.insights);
  const [insightsConversationAuditsList, setInsightsConversationAuditsList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 10;

  const getFormatedConversationsList = () => {
    // Getting conversations for current page and formating them
    let currentConversationsList = insightsConversationAudits[currentPage];
    currentConversationsList = currentConversationsList?.map((conversation) => {
      return {
        ...conversation,
        dateTime: getFormatedDate(conversation.dateTime),
        conversationQuestion: conversation.question,
        conversationAnswer: conversation.answer,
        customComponent: (currentPage) => (
          <AuditingActions conversationDetails={conversation} currentPage={currentPage} />
        )
      };
    });

    return currentConversationsList;
  };

  const getInsightsConversationAudits = (
    page = 1,
    newFilters = {},
    searchParams = '',
    orderParams = '',
    resetState = false
  ) => {
    try {
      setLoading(true);
      var filterParams = '';
      if (Object.keys(newFilters).length > 0) {
        try {
          filterParams += getValidatedFilterParams('start_date__gte', newFilters?.fromDate);
          filterParams += getValidatedFilterParams('start_date__lte', newFilters?.toDate);
        } catch (error) {
          filterParams = '';
        }
      }

      const payload = {
        page: page,
        filterParams: filterParams,
        searchParams: searchParams,
        orderParams: orderParams,
        resetState: resetState
      };
      dispatch(listInsightsConversationAuditsAction(payload))
        .unwrap()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(insightsConversationAudits).length > 0) {
      setPageCount(Math.ceil(totalCount / rowsPerPage));
      setInsightsConversationAuditsList(getFormatedConversationsList());
    } else {
      getInsightsConversationAudits(1, auditFilters, auditSearchParam, auditOrderParam, true);
    }
  }, [insightsConversationAudits]);

  useEffect(() => {
    if (
      !(currentPage in insightsConversationAudits) &&
      Object.keys(insightsConversationAudits).length > 0
    ) {
      getInsightsConversationAudits(currentPage, auditFilters, auditSearchParam, auditOrderParam);
    }
    if (currentPage in insightsConversationAudits) {
      setInsightsConversationAuditsList(getFormatedConversationsList());
    }
  }, [currentPage]);

  const onfilterApply = (newFilters) => {
    setCurrentPage(1);
    dispatch(setAuditSearchParam(''));
    getInsightsConversationAudits(1, newFilters, '', '', true); // by default get crawler configs for page 1 when filters are applied.
  };

  return {
    insightsConversationAuditsList,
    setInsightsConversationAuditsList,
    pageCount,
    currentPage,
    setCurrentPage,
    getInsightsConversationAudits,
    loading,
    setLoading,
    onfilterApply
  };
};

export default useInsightsAudit;
