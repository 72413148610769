import { useState } from 'react';

const useMultiSelector = (initialSelected) => {
  const [selected, setSelected] = useState(initialSelected || []);

  const selectAll = (event, rows) => {
    if (event.target.checked) {
      setSelected(rows.map((row) => row.id));
    } else setSelected([]);
  };

  const selectCurrent = (rowId) => {
    if (!selected.includes(rowId)) {
      setSelected([...selected, rowId]);
    } else setSelected(selected.filter((item) => item !== rowId));
  };

  return {
    selected,
    selectAll,
    selectCurrent
  };
};

export default useMultiSelector;
