/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  runCrawlerAction,
  listTodaysScheduledCrawlersAction,
  deleteCrawlerRunScheduleAction,
  getDashboardSummaryAction
} from '../services/crawlerService';
import { scheduledCrawlersPayload, todayDate } from '../utils/scheduledCrawlersPayload.js';

const useCrawlerDashboard = () => {
  const dispatch = useDispatch();

  const { todaysScheduledCrawlers, dashboardSummary } = useSelector((state) => state.crawler);
  const [scheduledCrawlersList, setScheduledCrawlersList] = useState([]);
  const [summaryLoader, setSummaryLoader] = useState(false);
  const [crawlerAnalyticsGraphLoader, setCrawlerAnalyticsGraphLoader] = useState(false);
  const [crawlerRuntimeMetricsGraphLoader, setCrawlerRuntimeMetricsGraphLoader] = useState(false);
  const [crawlerScheduleLoader, setCrawlerScheduleLoader] = useState(false);

  const getDashboardSummary = () => {
    setSummaryLoader(true);
    dispatch(getDashboardSummaryAction())
      .unwrap()
      .then(() => {
        setSummaryLoader(false);
      })
      .catch(() => setSummaryLoader(false));
  };

  const deleteCrawlerRunSchedule = (payload) => {
    let SUCCESS_MESSAGE = { ok: true, message: 'Crawler run schedule cancelled successfully.' };
    const ERROR_MESSAGE = { ok: false, message: 'Failed to cancel the crawler run schedule.' };

    return dispatch(deleteCrawlerRunScheduleAction(payload))
      .unwrap()
      .then((res) => {
        if (res.success) return SUCCESS_MESSAGE;
        else return ERROR_MESSAGE;
      })
      .catch((err) => {
        console.log(err);

        return ERROR_MESSAGE;
      });
  };

  const getFormatedScheduledCrawlersList = () => {
    // Getting Today's Scheduled Crawlers and formating them

    const nonFormattedScheduledCrawlersList = todaysScheduledCrawlers;

    const formattedScheduledCrawlersList = nonFormattedScheduledCrawlersList?.map(
      (scheduledCrawler) => ({
        ...scheduledCrawler,
        crawlerName: scheduledCrawler.name,
        crawlerTime: JSON.parse(scheduledCrawler.schedule).time,
        crawlerType: scheduledCrawler.type,
        todayDate: todayDate
      })
    );

    return formattedScheduledCrawlersList;
  };

  const getTodaysScheduledCrawlers = () => {
    setCrawlerScheduleLoader(true);

    dispatch(listTodaysScheduledCrawlersAction(scheduledCrawlersPayload()))
      .unwrap()
      .then(() => {
        setCrawlerScheduleLoader(false);
      })
      .catch(() => setCrawlerScheduleLoader(false));
  };

  useEffect(() => {
    if (todaysScheduledCrawlers?.length > 0) {
      //getting today's scheduled crawlers when there are scheduled crawlers
      setScheduledCrawlersList(getFormatedScheduledCrawlersList());
    } else if (todaysScheduledCrawlers === undefined) {
      //getting today's scheduled crawlers the first time
      getTodaysScheduledCrawlers();
      console.log('todaysScheduledCrawlers: ', todaysScheduledCrawlers);
    } else {
      //setting today's scheduled crawlers as an empty array when there are no scheduled crawlers
      setScheduledCrawlersList([]);
    }
  }, [todaysScheduledCrawlers]);

  useEffect(() => {
    if (Object.keys(dashboardSummary).length > 0) {
      setSummaryLoader(false);
    } else {
      getDashboardSummary();
    }
  }, [dashboardSummary]);

  return {
    scheduledCrawlersList,
    getTodaysScheduledCrawlers,
    deleteCrawlerRunSchedule,
    summaryLoader,
    setSummaryLoader,
    crawlerAnalyticsGraphLoader,
    setCrawlerAnalyticsGraphLoader,
    crawlerRuntimeMetricsGraphLoader,
    setCrawlerRuntimeMetricsGraphLoader,
    crawlerScheduleLoader,
    setCrawlerScheduleLoader
  };
};

export default useCrawlerDashboard;
