import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import '../../../styles/components/Crawler/Configuration/ConfigurationActions.css';
import CustomDialog from '../../common/Dialogs/CustomDialog';
import RunCrawlerDialog from '../../common/Dialogs/RunCrawlerDialog';
import EditCrawlerDialog from '../../common/Dialogs/EditCrawlerDialog';
import DeleteCrawlerDialog from '../../common/Dialogs/DeleteCrawlerDialog';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const ConfigurationActions = (props) => {
  const {
    crawlerConfig,
    currentCrawlerConfigurationPage,
    runCrawler,
    editCrawlerConfig,
    deleteCrawlerConfig,
    configFilters,
    configSearchParam,
    configOrderParam,
    getCrawlerConfigs
  } = props;
  const [openRunCrawlerDialog, setOpenRunCrawlerDialog] = useState(false);
  const [openEditCrawlerDialog, setOpenEditCrawlerDialog] = useState(false);
  const [openDeleteCrawlerDialog, setOpenDeleteCrawlerDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenRunCrawlerDialog(false);
    setOpenEditCrawlerDialog(false);
    setOpenDeleteCrawlerDialog(false);
    getCrawlerConfigs(
      currentCrawlerConfigurationPage,
      configFilters,
      configSearchParam,
      configOrderParam,
      false
    );
  };

  return (
    <>
      <Tooltip title="Run crawler" placement="top">
        <IconButton className="actions__runiconbtn" onClick={() => setOpenRunCrawlerDialog(true)}>
          <PlayArrowIcon className="actions__runicon" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit crawler configurations" placement="top">
        <IconButton className="actions__editiconbtn" onClick={() => setOpenEditCrawlerDialog(true)}>
          <EditIcon className="actions__editicon" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete crawler" placement="top">
        <IconButton
          className="actions__deleteiconbtn"
          onClick={() => setOpenDeleteCrawlerDialog(true)}
        >
          <DeleteIcon className="actions__deleteicon" />
        </IconButton>
      </Tooltip>

      <CustomDialog openDialog={openRunCrawlerDialog} handleCloseDialog={handleCloseDialog}>
        <RunCrawlerDialog
          crawlerConfig={crawlerConfig}
          runCrawler={runCrawler}
          handleCloseDialog={handleCloseDialog}
        />
      </CustomDialog>
      <CustomDialog
        openDialog={openEditCrawlerDialog}
        handleCloseDialog={handleCloseDialog}
        padding="24px 28px"
        crossIconStyle={{ width: '16px', height: '16px' }}
        crossIconBtnStyle={{ top: '20px', right: '24px' }}
      >
        <EditCrawlerDialog
          crawlerConfig={crawlerConfig}
          editCrawlerConfig={editCrawlerConfig}
          handleCloseDialog={handleCloseDialog}
        />
      </CustomDialog>
      <CustomDialog openDialog={openDeleteCrawlerDialog} handleCloseDialog={handleCloseDialog}>
        <DeleteCrawlerDialog
          crawlerConfig={crawlerConfig}
          deleteCrawlerConfig={deleteCrawlerConfig}
          handleCloseDialog={handleCloseDialog}
        />
      </CustomDialog>
    </>
  );
};

export default ConfigurationActions;
