import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Insights from '../../pages/Insights/Insights';
import Crawler from '../../pages/Crawler/Crawler';
import Login from '../../pages/Login/Login';
import Sidebar from '../../components/Siderbar/Sidebar';
import RouteGuard from './RouteGuard.jsx';
import { default as CrawlerDashboard } from '../../components/Crawler/Dashboard/Dashboard.jsx';
import { default as CrawlerConfiguration } from '../../components/Crawler/Configuration/Configuration';
import { default as CrawlerHistory } from '../../components/Crawler/History/History';
import { default as InsightsAuditing } from '../../components/Insights/Auditing/Auditing';
import { default as InsightsDashboard } from '../../components/Insights/Dashboard/Dashboard';

// import Policies from '../../pages/Policies/Policies';
import Onboard from '../../pages/Onboard/Onboard.jsx';
import Chatbot from '../../pages/Chatbot/Chatbot.jsx';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/crawler" />} />

    {/* Unprotected routes */}
    <Route exact path="login" element={<Login />} />

    {/* Protected Routes */}
    <Route element={<RouteGuard />}>
      {/* OnBoard route */}
      <Route exact path="onboard" element={<Onboard />} />

      <Route path="/" element={<Sidebar />}>
        {/* Crawler routes */}
        <Route exact path="crawler" element={<Crawler />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<CrawlerDashboard />} />
          <Route path="history" element={<CrawlerHistory />} />
          <Route path="configuration" element={<CrawlerConfiguration />} />
        </Route>

        {/* Insights routes */}
        <Route exact path="insights" element={<Insights />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<InsightsDashboard />} />
          <Route path="auditing" element={<InsightsAuditing />} />
        </Route>

        {/* Policies routes */}
        {/* <Route exact path="policies" element={<Policies />} /> */}

        {/* Chatbot route */}
        <Route exact path="chatbot" element={<Chatbot />} />
      </Route>
    </Route>
  </Routes>
);

export default AppRoutes;
