import { Close, Search } from '@mui/icons-material';
import { Button, InputAdornment, Stack, TextField, styled } from '@mui/material';
import React from 'react';
import '../../../styles/components/common/Searchbar/SearchBar.css';

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: '2px'
  }
}));

const SearchBar = (props) => {
  const { searchText, setSearchText, onSearch, onClear } = props;

  return (
    <>
      <Stack className="searchbar__container">
        <CustomTextField
          id="input-with-icon-textfield"
          className="searchbar__input"
          inputProps={{ style: { paddingRight: '4px' } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ pl: 2, pr: 1, opacity: 1 }}>
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchText?.trim()?.length ? (
              <InputAdornment position="start" sx={{ pl: 1, opacity: 1, cursor: 'pointer' }}>
                <Close onClick={onClear} />
              </InputAdornment>
            ) : null
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && onSearch()}
          onKeyUp={(e) => e.key === 'Backspace' && searchText === '' && onClear()}
          placeholder="Search"
          variant="outlined"
        />
        <Button className="searchbtn" variant="contained" color="primary" onClick={onSearch}>
          Search
        </Button>
      </Stack>
    </>
  );
};

export default SearchBar;
