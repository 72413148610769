import { useContext, useEffect } from 'react';
import { TabsContext } from '../context/TabsContext';
import { useNavigate } from 'react-router-dom';

export const useTabs = () => {
  const { currentTab, setCurrentTab } = useContext(TabsContext);
  const navigate = useNavigate();

  const handleSetCurrentTab = (tab) => {
    navigate(tab.toLowerCase(), { replace: true });
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (window?.location?.pathname.toLowerCase().includes('dashboard')) {
      setCurrentTab('Dashboard');
    }
    if (window?.location?.pathname.toLowerCase().includes('history')) {
      setCurrentTab('History');
    }
    if (window?.location?.pathname.toLowerCase().includes('configuration')) {
      setCurrentTab('Configuration');
    }
    if (window?.location?.pathname.toLowerCase().includes('auditing')) {
      setCurrentTab('Auditing');
    }
  }, [window?.location?.pathname]);

  if (!TabsContext) {
    throw new Error('useTabs should be used inside TabsProvider');
  }

  return { currentTab, handleSetCurrentTab };
};
