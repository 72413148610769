import utc from 'dayjs/plugin/utc';
import toObject from 'dayjs/plugin/toObject';
import dayjs from 'dayjs';

dayjs.extend(utc);
dayjs.extend(toObject);

// Convert dayjs date to UTC string
export function getUTCDateString(inputDate, type = 'startDate') {
  const { years, months, date } = inputDate.toObject();
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let milliseconds = 0;
  if (type === 'endDate') {
    // For endDate, set time to 23:59:59
    hours = 23;
    minutes = 59;
    seconds = 59;
    milliseconds = 999;
  }

  // Create a Date object using the provided properties and adjusted time
  const utcDate = new Date(Date.UTC(years, months, date, hours, minutes, seconds, milliseconds));

  // Get the UTC string format (YYYY-MM-DDTHH:mm:ss.SSSZ)
  const utcString = utcDate.toISOString();

  return utcString;
}
