/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import '../../../styles/components/common/Dialogs/DialogActionButtons.css';
import { Button } from '@mui/material';
import { clearToast, setToast } from '../../../app/redux/slices/toastSlice';
import { useDispatch, useSelector } from 'react-redux';

const DialogActionButtons = (props) => {
  const {
    align,
    isFormValid,
    checkValidity = false,
    submitBtnText,
    cancelBtnText,
    onSubmitAction,
    handleCloseDialog,
    apiPayload = null,
    showToastMessage = true
  } = props;
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.toast);

  const onSubmitButtonClick = async (e) => {
    e.preventDefault();
    if (checkValidity && !isFormValid()) {
      return;
    }
    const response = await onSubmitAction(apiPayload);

    // Show toast message for specific action
    if (showToastMessage) {
      let delayTime = 0;
      if (open) {
        delayTime = 500;
        dispatch(clearToast());
      }
      setTimeout(() => {
        dispatch(
          setToast({
            open: true,
            title: response.ok ? 'Success' : 'Error',
            severity: response.ok ? 'success' : 'error',
            description: response.message,
            showDuration: 7000
          })
        );
      }, delayTime);
    }
    handleCloseDialog();
  };

  return (
    <>
      <div
        className="dialog__actionbtns"
        style={{ alignSelf: align === 'right' ? 'flex-end' : '' }}
      >
        <Button
          onClick={handleCloseDialog}
          className="dialog__cancelbtn"
          variant="outlined"
          color="primary"
        >
          {cancelBtnText}
        </Button>
        <Button
          onClick={onSubmitButtonClick}
          className="dialog__confirmbtn"
          variant="contained"
          color="primary"
        >
          {submitBtnText}
        </Button>
      </div>
    </>
  );
};

export default DialogActionButtons;
