// Slice containes user related states and actions
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: undefined,
  objectId: undefined,
  orgId: '',
  active: false,
  crawlerConfigured: undefined,
  domainName: '',
  onBoarding: undefined,
  teamsBotConfigured: false
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserData: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
