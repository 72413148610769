export const getFormattedResponse = (text, isList = true) => {
  // Regular expression to match file names and URLs in the given format: [fileName](url)
  const urlRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

  const elements = [];
  let match;
  let lastIndex = 0;

  // Loop through all matches found by the regular expression
  while ((match = urlRegex.exec(text)) !== null) {
    // Destructure the match into parts: fullMatch, fileName, and url
    const [fullMatch, fileName, url] = match;

    // Extract the non-matching text between the last match and the current match
    const nonMatchingText = text.slice(lastIndex, match.index);

    // If there is non-matching text, add it as a <span> element
    if (nonMatchingText) {
      elements.push(<span key={`non-match-${lastIndex}`}>{nonMatchingText}</span>);
    }

    if (!isList) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" className="detailsdialog__url_link">
          {fileName}
        </a>
      );
    }

    // Add a list item for each matched file, with hyperlinked file name
    elements.push(
      <li key={match.index} className="detailsdialog__bullet_point">
        <a href={url} target="_blank" rel="noopener noreferrer" className="detailsdialog__url_link">
          {fileName}
        </a>
      </li>
    );

    // Update the lastIndex to the end of the current match
    lastIndex = match.index + fullMatch.length;
  }

  // Add any remaining non-matching text after the last match
  const remainingText = text.slice(lastIndex);
  if (remainingText) {
    elements.push(<span key={`non-match-${lastIndex}`}>{remainingText}</span>);
  }

  // Return the final JSX structure with the bulleted list
  return <ul>{elements}</ul>;
};
