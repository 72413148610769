/* eslint-disable no-undef */
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice.js';
import crawlerReducer from './slices/crawlerSlice.js';
import toastReducer from './slices/toastSlice.js';
import insightsReducer from './slices/insightsSlice.js';

export const store = configureStore({
  reducer: {
    user: userReducer,
    crawler: crawlerReducer,
    insights: insightsReducer,
    toast: toastReducer
  },
  devTools: process.env.NODE_ENV !== 'production'
});
