import { Box, Checkbox, Chip, Typography, Tooltip } from '@mui/material';

export const CheckboxTableCell = ({
  id,
  label,
  selectRow,
  selected,
  textSelection = false,
  showDisableText = false
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        sx={{ p: 0 }}
        disabled={showDisableText}
        checked={selected.includes(id) || showDisableText}
        onChange={() => !showDisableText && selectRow(id)}
      />
      <Tooltip title={label} placement="top">
        <Typography
          variant="tablecell"
          sx={{
            paddingLeft: '16px',
            cursor: !textSelection ? 'pointer' : 'default',
            color: showDisableText ? '#B7BEC4' : '#324556',
            fontSize: '15px !important'
          }}
          onClick={() => !textSelection && selectRow(id)}
        >
          {label}
        </Typography>
      </Tooltip>
      {showDisableText && <Chip label="Already exists" sx={{ ml: 2, height: '24px' }} />}
    </Box>
  );
};
