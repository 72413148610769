/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../styles/pages/Onboard/Onboard.css';
import iAskLogo from './../../assets/iask_logo.svg';
import { CheckCircle } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  configureCrawler,
  downloadTeamsBotZip,
  integrateTeamsBot
} from '../../app/services/authService';
import { useSelector } from 'react-redux';
import Toast from '../../components/common/Toast/Toast';
import { Carousel } from 'antd';
import { ConfigureCrawlerStep } from '../../components/Onboard/ConfigureCrawlerStep';
import { IntegrateTeamsStep } from '../../components/Onboard/IntegrateTeamsStep';
import { GettingStartedStep } from '../../components/Onboard/GettingStartedStep';
import { useMsal } from '@azure/msal-react';

const OnBoard = () => {
  const { instance } = useMsal();
  const [activeStep, setActiveStep] = useState(0);
  const [errorInCrawlerConfiguration, setErrorInCrawlerConfiguration] = useState('');
  const [errorInTeamsIntegration, setErrorInTeamsIntegration] = useState('');
  const [loading, setLoading] = useState(false);

  const { crawlerConfigured, teamsBotConfigured } = JSON.parse(
    localStorage.getItem('userData') || '{}'
  );
  const [isCrawlerConfigured, setIsCrawlerConfigured] = useState(crawlerConfigured);

  const [isTeamsIntegrated, setIsTeamsIntegrated] = useState({
    integrated: teamsBotConfigured,
    confirmed: false
  });
  const navigate = useNavigate();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const adminConsentParam = searchParams.get('admin_consent');
  const tenantParam = searchParams.get('tenant');

  const teamsBotIntegrateAdminConsent = async () => {
    navigate('', { replace: true });
    try {
      const payload = {
        ad_tenant_id: tenantParam
      };
      const isTeamsBotIntegrated = await integrateTeamsBot(payload);
      if (isTeamsBotIntegrated.success) {
        setErrorInTeamsIntegration('');
        setIsTeamsIntegrated({ ...isTeamsIntegrated, integrated: true });
        let userData = localStorage.getItem('userData') || '{}';
        userData = { ...JSON.parse(userData), teamsBotConfigured: true };
        localStorage.setItem('userData', JSON.stringify(userData));
      } else setErrorInTeamsIntegration('Failed to integrate with Microsoft Teams');

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const configureCrawlerAdminConsent = async () => {
    navigate('', { replace: true });
    try {
      const payload = {
        ad_tenant_id: tenantParam
      };
      const response = await configureCrawler(payload);
      if (response.success) {
        setIsCrawlerConfigured(true);
        setErrorInCrawlerConfiguration('');
        let userData = localStorage.getItem('userData') || '{}';
        userData = { ...JSON.parse(userData), crawlerConfigured: true, onBoarding: false };
        localStorage.setItem('userData', JSON.stringify(userData));
      } else setErrorInCrawlerConfiguration('Failed to configure the crawler');

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    // set crawler configurations based on admin consent
    if (adminConsentParam && !isCrawlerConfigured && activeStep === 0) {
      configureCrawlerAdminConsent();
    }

    // set teamsbot configurations based on admin consent
    if (adminConsentParam && !isTeamsIntegrated.integrated && activeStep === 1) {
      teamsBotIntegrateAdminConsent();
    }
  }, [searchParams]);

  useEffect(() => {
    if (crawlerConfigured) setActiveStep(1);
    setIsCrawlerConfigured(crawlerConfigured);

    setIsTeamsIntegrated({
      ...isTeamsIntegrated,
      integrated: teamsBotConfigured
    });
  }, []);

  const steps = [
    'Configure the crawler',
    'Integrate Microsoft teams bot [Optional]',
    'Get started with Privou'
  ];

  const handleOnboard = () => {
    navigate('/crawler');
  };

  const handleStepChange = (stepChange) => {
    setActiveStep(activeStep + stepChange);
  };

  const checkForButtonDisable = () => {
    if (activeStep === 0 && !isCrawlerConfigured) return true;
    if (activeStep === 1 && !isTeamsIntegrated.confirmed) return true;
  };

  const handleLogout = (e) => {
    e.preventDefault();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <Box className="onboard__container">
      <Grid container className="onboard__content">
        <Grid item xs={5} className="onboard__left__panel">
          <img className="onboard__logo" src={iAskLogo} />
          <Box className="onboard__title__container">
            <Typography className="onboard__title" variant="h3">
              Welcome to Privou
            </Typography>
            <Typography className="onboard__subtitle" variant="h5">
              Elevating Enterprise Intelligence with LLM-Powered AI Precision
            </Typography>
          </Box>
          <Box className="onboard__steps__preview">
            <Typography className="onboard__steps__title" variant="h5">
              Follow the below steps:
            </Typography>
            <Stepper className="onboard__steps" activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Stack sx={{ mt: '24px', ml: 2 }}>
            <Button className="onboard__logout__btn" variant="contained" onClick={handleLogout}>
              Logout
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={7} className="onboard__right__panel">
          <Box className="onboard__step__content">
            {activeStep === 0 && (
              <ConfigureCrawlerStep
                loading={loading}
                setLoading={setLoading}
                isCrawlerConfigured={isCrawlerConfigured}
                setIsCrawlerConfigured={setIsCrawlerConfigured}
                errorInCrawlerConfiguration={errorInCrawlerConfiguration}
                setErrorInCrawlerConfiguration={setErrorInCrawlerConfiguration}
              />
            )}
            {activeStep === 1 && (
              <IntegrateTeamsStep
                isTeamsIntegrated={isTeamsIntegrated}
                setIsTeamsIntegrated={setIsTeamsIntegrated}
                loading={loading}
                setLoading={setLoading}
                errorInTeamsIntegration={errorInTeamsIntegration}
                setErrorInTeamsIntegration={setErrorInTeamsIntegration}
              />
            )}
            {activeStep === 2 && <GettingStartedStep />}
          </Box>

          <Box className="onboard__step__btns__container">
            <div>
              {activeStep === 1 && (
                <Button
                  className="onboard__skip__btn"
                  variant="outlined"
                  onClick={() => handleStepChange(+1)}
                >
                  Skip
                </Button>
              )}
            </div>

            <div>
              {activeStep > 0 && activeStep < steps.length && (
                <Button
                  className="onboard__back__btn"
                  variant="outlined"
                  onClick={() => handleStepChange(-1)}
                >
                  Back
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  className="onboard__next__btn"
                  variant="contained"
                  onClick={() => handleStepChange(1)}
                  disabled={checkForButtonDisable()}
                >
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button className="onboard__next__btn" variant="contained" onClick={handleOnboard}>
                  Get started
                </Button>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnBoard;
