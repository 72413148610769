// Utility function to convert local time to UTC time
export const convertLocalTimeToUTC = (localTime) => {
  const [hours, minutes] = localTime.split(':').map(Number);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  // Getting UTC time in HH:mm format
  const utcTime = date.toUTCString().match(/(\d{2}:\d{2})/)[0];

  return utcTime;
};

export const getFormatedTime = (startDateString, endDateString) => {
  // Check if start or end date strings are null or empty
  if (!startDateString || !endDateString) {
    return '0hr:1m:0s';
  }
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Convert the time difference to a readable format
  let seconds = Math.floor((timeDifference / 1000) % 60);
  let minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  let hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);

  // Format the time difference
  const formattedTime = `${hours}hr:${minutes}m:${seconds}s`;

  return formattedTime;
};
