export const getFormatedDate = (dateString) => {
  // format dateString = '2023-11-22T10:09:10.728468Z';
  const inputDate = new Date(dateString);

  // eslint-disable-next-line prettier/prettier
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const day = inputDate.getDate();
  const monthIndex = inputDate.getMonth();
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  const formattedDate = `${hours < 10 ? `0` : ''}${hours}:${minutes < 10 ? `0` : ''}${minutes} ${
    months[monthIndex]
  } ${day}, ${year}`;

  return formattedDate; // Output: "10:55 Jan 11, 2024"
};
