const responsiveFontSizes = ({ sm, md, lg, xl }) => {
  return {
    '@media (min-width:700px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:1000px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    },
    '@media (min-width:1600px)': {
      fontSize: pxToRem(xl)
    }
  };
};

const pxToRem = (font) => {
  return font / 16 + 'rem';
};

const Typography = (theme) => {
  return {
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: '33px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 27, md: 29, lg: 31, xl: 33 })
    },
    h2: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: '28px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 22, md: 24, lg: 26, xl: 28 })
    },
    h3: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: '23px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 17, md: 19, lg: 21, xl: 23 })
    },
    h4: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: '19px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 13, md: 15, lg: 17, xl: 19 })
    },
    h5: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: '16px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 11, md: 12, lg: 14, xl: 16 })
    },
    h6: {
      color: theme.palette.text.primary,
      fontSize: '13px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 10, md: 10, lg: 11, xl: 13 })
    },
    subtitle: {
      letterSpacing: '0.15px',
      color: theme.palette.text.primary,
      fontSize: '11px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 9, md: 9, lg: 10, xl: 11 })
    },
    body1: {
      color: theme.palette.text.primary,
      fontSize: '16px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 11, md: 12, lg: 14, xl: 16 })
    },
    tabHeader: {
      fontWeight: 700,
      color: theme.palette.blue.normal,
      fontSize: '23px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 17, md: 19, lg: 21, xl: 23 })
    },
    subheader: {
      color: theme.palette.text.primary,
      fontSize: '19px',
      textTransform: 'capitalize',
      ...responsiveFontSizes({ sm: 13, md: 15, lg: 17, xl: 19 })
    },
    button: {
      letterSpacing: '0.3px',
      color: theme.palette.text.primary,
      textTransform: 'capitalize'
    },
    caption: {
      letterSpacing: '0.4px',
      color: theme.palette.text.secondary,
      textTransform: 'capitalize'
    },
    overline: {
      letterSpacing: '1px',
      color: theme.palette.text.secondary,
      textTransform: 'capitalize'
    },
    tablecell: {
      fontWeight: 400,
      color: theme.palette.text.primary,
      fontSize: '19px',
      textTransform: 'none',
      ...responsiveFontSizes({ sm: 13, md: 15, lg: 17, xl: 19 })
    },
    filterHeader: {
      fontWeight: 700,
      color: theme.palette.text.primary,
      fontSize: '14px',
      textTransform: 'uppercase',
      ...responsiveFontSizes({ sm: 11, md: 12, lg: 13, xl: 14 })
    }
  };
};

export default Typography;
