/* eslint-disable no-unused-vars */
// Slice containes crawler tab related states
import { createSlice } from '@reduxjs/toolkit';
import {
  addCrawlerConfigAction,
  deleteCrawlerConfigAction,
  deleteCrawlerRunScheduleAction,
  editCrawlerConfigAction,
  getCrawlerRunAnalyticsGraphsDataAction,
  getCrawlerRuntimeMetricsGraphsDataAction,
  getDashboardSummaryAction,
  listCrawlerConfigsAction,
  listCrawlerRunHistoryAction,
  listTodaysScheduledCrawlersAction,
  runCrawlerAction
} from '../../services/crawlerService';

const initialState = {
  // crawler configurations states
  crawlerConfigurations: {}, // { pageNumber: [crawlers..], ... }
  totalCrawlers: 0,
  totalRuns: 0,
  configFilters: {}, // filters
  configSearchParam: '', // search
  configOrderParam: '', // order

  // crawler run history statest
  crawlerRunHistory: {}, // { pageNumber: [runs..], ... }
  historyFilters: {}, // filters
  historySearchParam: '', // search
  historyOrderParam: '', // order

  // crawler dashboard states
  todaysScheduledCrawlers: undefined,
  dashboardSummary: {},
  crawlerRunAnalyticsGraphData: {},
  crawlerRuntimeMetricsGraphData: {}
};

const crawlerSlice = createSlice({
  name: 'crawler',
  initialState: initialState,
  reducers: {
    setCrawlerConfigurations: (state, action) => {
      state.crawlerConfigurations = action.payload;
    },
    setCrawlerConfigFilters: (state, action) => {
      state.configFilters = action.payload;
    },
    setCrawlerHistoryFilters: (state, action) => {
      state.historyFilters = action.payload;
    },
    setConfigSearchParam: (state, action) => {
      state.configSearchParam = action.payload;
    },
    setHistorySearchParam: (state, action) => {
      state.historySearchParam = action.payload;
    },
    setConfigOrderParam: (state, action) => {
      state.configOrderParam = action.payload;
    },
    setHistoryOrderParam: (state, action) => {
      state.historyOrderParam = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCrawlerConfigsAction.fulfilled, (state, action) => {
        if (action.payload.reset) state.crawlerConfigurations = {};
        state.crawlerConfigurations[action.payload.page] = action.payload.crawlers;
        state.totalCrawlers = action.payload.totalCrawlers;
      })
      .addCase(listCrawlerConfigsAction.rejected, (state, action) => {})
      .addCase(addCrawlerConfigAction.fulfilled, (state, action) => {})
      .addCase(addCrawlerConfigAction.rejected, (state, action) => {})
      .addCase(editCrawlerConfigAction.fulfilled, (state, action) => {})
      .addCase(editCrawlerConfigAction.rejected, (state, action) => {})
      .addCase(deleteCrawlerConfigAction.fulfilled, (state, action) => {})
      .addCase(deleteCrawlerConfigAction.rejected, (state, action) => {})
      .addCase(deleteCrawlerRunScheduleAction.fulfilled, (state, action) => {})
      .addCase(deleteCrawlerRunScheduleAction.rejected, (state, action) => {})
      .addCase(runCrawlerAction.fulfilled, (state, action) => {})
      .addCase(runCrawlerAction.rejected, (state, action) => {})
      .addCase(listCrawlerRunHistoryAction.fulfilled, (state, action) => {
        if (action.payload.reset) state.crawlerConfigurations = {};
        state.crawlerRunHistory[action.payload.page] = action.payload.runHistoryList;
        state.totalRuns = action.payload.totalRuns;
      })
      .addCase(listCrawlerRunHistoryAction.rejected, (state, action) => {})
      .addCase(listTodaysScheduledCrawlersAction.fulfilled, (state, action) => {
        state.todaysScheduledCrawlers = action.payload.todaysScheduledCrawlers;
      })
      .addCase(listTodaysScheduledCrawlersAction.rejected, (state, action) => {})
      .addCase(getDashboardSummaryAction.fulfilled, (state, action) => {
        state.dashboardSummary = action.payload;
      })
      .addCase(getDashboardSummaryAction.rejected, (state, action) => {})
      .addCase(getCrawlerRunAnalyticsGraphsDataAction.fulfilled, (state, action) => {
        state.crawlerRunAnalyticsGraphData = action.payload;
      })
      .addCase(getCrawlerRunAnalyticsGraphsDataAction.rejected, (state, action) => {})
      .addCase(getCrawlerRuntimeMetricsGraphsDataAction.fulfilled, (state, action) => {
        state.crawlerRuntimeMetricsGraphData = action.payload;
      })
      .addCase(getCrawlerRuntimeMetricsGraphsDataAction.rejected, (state, action) => {});
  }
});

export const {
  setCrawlerConfigurations,
  setCrawlerConfigFilters,
  setCrawlerHistoryFilters,
  setConfigSearchParam,
  setHistorySearchParam,
  setConfigOrderParam,
  setHistoryOrderParam
} = crawlerSlice.actions;

export default crawlerSlice.reducer;
