import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { CircularProgress } from '@mui/material';
import { NoDataFound } from '../../common/ErrorComponents/NoDataFound';

const Graph = (props) => {
  const { option, graphStyle, loading } = props;

  return loading ? (
    <div
      style={{
        width: '100%',
        height: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </div>
  ) : !option ? (
    <div
      className="dashboardgraph__nodata"
      style={{
        width: '100%',
        height: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <NoDataFound text={'No Data Found'} />
    </div>
  ) : (
    <ReactEcharts option={option} style={graphStyle} />
  );
};

export default Graph;
