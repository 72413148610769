//Checks if every attribute in an object is an empty array
export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      Array.isArray(obj[key]) &&
      obj[key].length !== 0
    ) {
      return false;
    }
  }

  return true;
};
