import React, { useState } from 'react';
import { Tooltip, Button } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayArrow';
import CancelIcon from '@mui/icons-material/Close';
import CustomDialog from '../../common/Dialogs/CustomDialog';
import RunCrawlerDialog from '../../common/Dialogs/RunCrawlerDialog';
import useCrawlerDashboard from '../../../app/hooks/useCrawlerDashboard';
import useCrawlerConfigs from '../../../app/hooks/useCrawlerConfigs';
import DeleteCrawlerScheduleDialog from '../../common/Dialogs/DeleteCrawlerScheduleDialog';

const ScheduledCrawlersListButtons = ({ crawler }) => {
  const [openRunCrawlerDialog, setOpenRunCrawlerDialog] = useState(false);
  const [openDeleteCrawlerScheduleDialog, setOpenDeleteCrawlerScheduleDialog] = useState(false);

  const { runCrawler } = useCrawlerConfigs();
  const { getTodaysScheduledCrawlers, deleteCrawlerRunSchedule } = useCrawlerDashboard();

  const handleCloseDialog = () => {
    setOpenRunCrawlerDialog(false);
    setOpenDeleteCrawlerScheduleDialog(false);

    getTodaysScheduledCrawlers();
  };

  return (
    <div
      className="customlist__listitem__buttons"
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-around',
        marginRight: '4px'
      }}
    >
      <Tooltip title="Run crawler now" placement="top">
        <Button
          className="button runbtn"
          variant="contained"
          color="primary"
          startIcon={<PlayIcon style={{ color: 'white', fontSize: 'medium' }} />}
          sx={{
            justifyContent: 'center',
            padding: '5px',
            fontSize: '13px',
            display: 'flex',
            flexFlow: 'row wrap',
            height: 'fit-content',
            width: '100%',
            marginRight: '20px'
          }}
          onClick={() => setOpenRunCrawlerDialog(true)}
        >
          Run
        </Button>
      </Tooltip>
      <Tooltip title="Cancel crawler schedule" placement="top">
        <Button
          className="button cancelbtn"
          variant="outlined"
          color="secondary"
          endIcon={<CancelIcon style={{ color: '#006494', fontSize: 'medium' }} />}
          sx={{
            justifyContent: 'center',
            padding: '5px',
            fontSize: '13px',
            display: 'flex',
            flexFlow: 'row wrap',
            height: 'fit-content',
            width: '100%',
            backgroundColor: 'white',
            color: '#006494'
          }}
          onClick={() => {
            setOpenDeleteCrawlerScheduleDialog(true);
          }}
        >
          Cancel
        </Button>
      </Tooltip>

      <CustomDialog openDialog={openRunCrawlerDialog} handleCloseDialog={handleCloseDialog}>
        <RunCrawlerDialog
          crawlerConfig={crawler}
          handleCloseDialog={handleCloseDialog}
          runCrawler={runCrawler}
        />
      </CustomDialog>

      <CustomDialog
        openDialog={openDeleteCrawlerScheduleDialog}
        handleCloseDialog={handleCloseDialog}
      >
        <DeleteCrawlerScheduleDialog
          todaysScheduledCrawler={crawler}
          handleCloseDialog={handleCloseDialog}
          deleteCrawlerRunSchedule={deleteCrawlerRunSchedule}
        />
      </CustomDialog>
    </div>
  );
};

export default ScheduledCrawlersListButtons;
