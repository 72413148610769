const today = new Date();
const options = { weekday: 'short', month: 'short', day: 'numeric' };

export const todayDate = today.toLocaleDateString('en-US', options);

export const scheduledCrawlersPayload = () => {
  const dayOfWeek = today.getDay();

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const todayDay = daysOfWeek[dayOfWeek];

  return {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: todayDay
  };
};
