import React from 'react';
import './../../styles/pages/Insights/Insights.css';
import HistoryIcon from '@mui/icons-material/History';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard_logo.svg';
import { Box } from '@mui/material';
import CustomTabs from '../../components/common/Tabs/CustomTabs';
import { Outlet } from 'react-router-dom';

const Insights = () => {
  const tabs = [
    { tabName: 'Dashboard', icon: <DashboardIcon style={{ fontSize: 24 }} /> },
    { tabName: 'Auditing', icon: <HistoryIcon style={{ fontSize: 24 }} /> }
  ];

  return (
    <>
      <Box className="insights__component">
        <CustomTabs tabs={tabs} />
        <Box className="insights__tabcontent">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Insights;
