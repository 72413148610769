export const getRunTimeGraphOption = (
  xAxisType,
  xAxisData,
  yAxisType,
  graphType,
  avgTimeData,
  maxTimeData,
  minTimeData
) => {
  const convertedAvgTimeData = avgTimeData?.map((time) => (time / 60).toFixed(4));
  const convertedMaxTimeData = maxTimeData?.map((time) => (time / 60).toFixed(4));
  const convertedMinTimeData = minTimeData?.map((time) => (time / 60).toFixed(4));

  return {
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      },
      valueFormatter: (value) => `${value} mins`
    },
    grid: {
      top: '15%',
      right: '5%',
      left: '8%',
      bottom: '20%'
    },
    xAxis: {
      type: xAxisType,
      data: xAxisData
    },
    yAxis: {
      type: yAxisType,
      name: 'Time (mins)',
      nameTextStyle: {
        color: '#324556',
        fontWeight: 'bold'
      },
      splitLine: {
        show: true
      }
    },
    series: [
      {
        name: 'Average run time',
        type: graphType,
        data: convertedAvgTimeData,
        label: convertedAvgTimeData,
        global: false,
        color: '#4CAF50'
      },
      {
        name: 'Max run time',
        type: graphType,
        data: convertedMaxTimeData,
        label: convertedMaxTimeData,
        global: false,
        color: '#006494'
      },
      {
        name: 'Min run time',
        type: graphType,
        data: convertedMinTimeData,
        label: convertedMinTimeData,
        global: false,
        color: '#FF9800'
      }
    ],
    dataZoom: [
      {
        type: 'slider',
        show: true,
        start: 50,
        end: 100,
        handleSize: 15,
        height: 1,
        bottom: '6%',
        width: '70%',
        left: '15%'
      },
      {
        type: 'inside',
        start: 10,
        end: 100
      }
    ]
  };
};
