import React, { useEffect, useState } from 'react';
import DialogActionButtons from '../Dialogs/DialogActionButtons';
import { Stack, Typography } from '@mui/material';
import '../../../styles/components/common/Filters/InsightsTimeFilter.css';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getDateRange } from '../../../app/utils/dateRange';
import { getUTCDateString } from '../../../app/utils/getUTCDateString';

const CrawlerTimeFilter = (props) => {
  const { title, handleApplyFilter, setOpenFilterDialog, filters } = props;
  const [fromDate, setFromDate] = useState(dayjs(filters?.fromDate));
  const [toDate, setToDate] = useState(dayjs(filters?.toDate));
  const [dateRange, setDateRange] = useState(filters?.dateRange || 'This Month');
  const { startDate, endDate } = getDateRange(dateRange);

  const handleApplyClick = () => {
    let formatedFromDate = getUTCDateString(fromDate, 'startDate');
    let formatedToDate = getUTCDateString(toDate, 'endDate');

    const timefilter = {
      fromDate: formatedFromDate,
      toDate: formatedToDate,
      dateRange: dateRange
    };

    handleApplyFilter(timefilter);
  };

  function resetDates(startDate, endDate) {
    setFromDate(dayjs(startDate));
    setToDate(dayjs(endDate));
  }

  function checkDatesMatch(startDate, endDate) {
    if (
      dayjs(fromDate).format('MM/DD/YYYY') !== startDate ||
      dayjs(toDate).format('MM/DD/YYYY') !== endDate
    ) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    try {
      const { startDate, endDate } = getDateRange(dateRange);
      setFromDate(dayjs(startDate));
      setToDate(dayjs(endDate));
    } catch (err) {
      console.log(err);
    }
  }, [dateRange]);

  return (
    <div className="filter__timefilter">
      <div className="filter__timepickers">
        <Typography variant="filterHeader" className="filter__title">
          {title}
        </Typography>
        <div className="filter__lastDatepick">
          <Typography
            variant="h5"
            className={`filter__lastDate ${
              dateRange === 'Today' && checkDatesMatch(startDate, endDate) ? 'selectedDates' : ''
            }`}
            onClick={() => {
              setDateRange('Today');
              resetDates(startDate, endDate);
            }}
          >
            Today
          </Typography>
          <Typography
            variant="h5"
            className={`filter__lastDate ${
              dateRange === 'Last 7 Days' && checkDatesMatch(startDate, endDate)
                ? 'selectedDates'
                : ''
            }`}
            onClick={() => {
              setDateRange('Last 7 Days');
              resetDates(startDate, endDate);
            }}
          >
            Last 7 Days
          </Typography>
          <Typography
            variant="h5"
            className={`filter__lastDate ${
              dateRange === 'This Month' && checkDatesMatch(startDate, endDate)
                ? 'selectedDates'
                : ''
            }`}
            onClick={() => {
              setDateRange('This Month');
              resetDates(startDate, endDate);
            }}
          >
            This Month
          </Typography>
          <Typography
            variant="h5"
            className={`filter__lastDate ${
              dateRange === 'Last Year' && checkDatesMatch(startDate, endDate)
                ? 'selectedDates'
                : ''
            }`}
            onClick={() => {
              setDateRange('Last Year');
              resetDates(startDate, endDate);
            }}
          >
            Last Year
          </Typography>
        </div>
        <Stack direction={'row'} className="filter__datepicker">
          <Stack direction={'column'} sx={{ mr: '21px' }}>
            <Typography variant="h5" className="filter__datepickerlabel">
              From
            </Typography>
            <DatePicker
              className="filter__datepickerInput"
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
            />
          </Stack>
          <Stack direction={'column'}>
            <Typography variant="h5" className="filter__datepickerlabel">
              To
            </Typography>
            <DatePicker
              className="filter__datepickerInput"
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
            />
          </Stack>
        </Stack>
      </div>
      <DialogActionButtons
        submitBtnText="Apply Filters"
        cancelBtnText="Cancel"
        onSubmitAction={handleApplyClick}
        handleCloseDialog={() => setOpenFilterDialog(false)}
        showToastMessage={false}
      />
    </div>
  );
};

export default CrawlerTimeFilter;
