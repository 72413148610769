const validateField = (fieldName, value) => {
  let errorObject = {
    error: false,
    message: ''
  };

  if (fieldName === 'clientSecret' && value.trim().length === 0) {
    errorObject = {
      error: true,
      message: 'Enter a valid Client_Secret'
    };
  } else if (fieldName === 'crawlerName' && value.trim().length === 0) {
    errorObject = {
      error: true,
      message: 'Enter a valid crawler name'
    };
  } else if (fieldName === 'crawlerUrl') {
    if (value.trim().length === 0) {
      errorObject = {
        error: true,
        message: 'Enter a valid crawler URL'
      };
    } else {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

      if (!urlPattern.test(value)) {
        errorObject = {
          error: true,
          message: 'Enter a valid URL (include http/https)'
        };
      }
    }
  } else if (fieldName === 'crawlerType' && value.trim().length === 0) {
    errorObject = {
      error: true,
      message: 'Select a crawler type'
    };
  } else if (fieldName === 'clientId' && value.trim().length === 0) {
    errorObject = {
      error: true,
      message: 'Enter a valid Client_ID'
    };
  } else if (fieldName === 'tenantId' && value.trim().length === 0) {
    errorObject = {
      error: true,
      message: 'Enter a valid Tenant_ID'
    };
  }

  return errorObject;
};

export const validateForm = (formData, setFormErrors) => {
  const updatedFormErrors = {};
  Object.entries(formData).forEach(([fieldName, value]) => {
    updatedFormErrors[fieldName] = validateField(fieldName, value);
  });
  setFormErrors(updatedFormErrors);

  return Object.values(updatedFormErrors).every((error) => error.error === false);
};
