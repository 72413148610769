// Slice containes toast related states
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  title: '',
  description: '',
  severity: 'success',
  showDuration: 5000
};

const toastSlice = createSlice({
  name: 'toast',
  initialState: initialState,
  reducers: {
    setToast: (state, action) => {
      state.open = action.payload.open;
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.severity = action.payload.severity;
      state.showDuration = action.payload.showDuration;
    },
    // eslint-disable-next-line no-unused-vars
    clearToast: (state, action) => {
      state.open = false;
      state.showDuration = 5000;

      // state.title = '';
      // state.description = '';
      // state.severity = '';
    }
  }
});

export const { setToast, clearToast } = toastSlice.actions;

export default toastSlice.reducer;
