import React, { useState } from 'react';
import '../../../styles/components/common/Dialogs/DeleteCrawlerDialog.css';
import { Chip, Stack, TextField, Typography } from '@mui/material';
import DialogActionButtons from './DialogActionButtons';
import { WarningAmberOutlined } from '@mui/icons-material';
import { clearToast, setToast } from '../../../app/redux/slices/toastSlice';
import { useDispatch, useSelector } from 'react-redux';

const DeleteCrawlerDialog = ({ crawlerConfig, deleteCrawlerConfig, handleCloseDialog }) => {
  const [confirmCrawlerNameError, setconfirmCrawlerNameError] = useState({
    error: false,
    message: 'Enter a valid crawler name'
  });
  const [confirmCrawlerName, setconfirmCrawlerName] = useState('');
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.toast);
  const showToastMessage = true;

  const textFieldStyle = {
    sx: {
      '&::placeholder': {
        color: '#ACACAC',
        fontSize: '14px',
        fontWeight: '500'
      },
      fontSize: '14px',
      padding: '14px 16px'
    }
  };

  const isFormValid = () => {
    if (confirmCrawlerName !== crawlerConfig.name) {
      setconfirmCrawlerNameError({ error: true, message: 'Enter a valid crawler name' });

      return false;
    }

    return true;
  };

  const onEnterKeyPress = async () => {
    if (!isFormValid()) {
      return;
    }
    const response = await deleteCrawlerConfig({ id: crawlerConfig.id });
    if (showToastMessage) {
      let delayTime = 0;
      if (open) {
        delayTime = 1000;
        dispatch(clearToast());
      }
      setTimeout(() => {
        dispatch(
          setToast({
            open: true,
            title: response.ok ? 'Success' : 'Error',
            severity: response.ok ? 'success' : 'error',
            description: response.message,
            showDuration: 7000
          })
        );
      }, delayTime);
    }
    handleCloseDialog();
  };

  return (
    <div className="delete__dialog">
      <Stack flexDirection="row">
        <WarningAmberOutlined sx={{ color: '#EF6C00', mr: 2 }} />
        <Typography variant="h4" className="delete__dialog__title">
          Sure, you want to delete the crawler?
        </Typography>
      </Stack>

      <Stack flexDirection="row" className="deletedialog__crawlers__chips">
        <Chip label={crawlerConfig.name} className="deletedialog__crawlers__chips__chip" />
      </Stack>

      <div className="delete__dialog__form">
        <Typography variant="subtitle" className="dialog__form__confirmCrawlerNamelabel">
          Please type in the name of the crawler to confirm.
        </Typography>
        <TextField
          variant="outlined"
          inputProps={textFieldStyle}
          value={confirmCrawlerName}
          onChange={(e) => setconfirmCrawlerName(e.target.value)}
          placeholder="Enter the crawler name"
          error={confirmCrawlerNameError.error}
          helperText={confirmCrawlerNameError.error ? confirmCrawlerNameError.message : ''}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onEnterKeyPress();
            }
          }}
        />
      </div>

      <DialogActionButtons
        submitBtnText="Yes, Confirm"
        cancelBtnText="No, Cancel"
        onSubmitAction={deleteCrawlerConfig}
        handleCloseDialog={handleCloseDialog}
        checkValidity={true}
        isFormValid={isFormValid}
        align="right"
        apiPayload={{ id: crawlerConfig.id }}
      />
    </div>
  );
};

export default DeleteCrawlerDialog;
