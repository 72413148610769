import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Pagination, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import '../../../styles/components/common/Table/CustomTable.css';
import TableHeader from './TableHeader';
import CustomTableRow from './CustomTableRow';
import { NoDataFound } from '../ErrorComponents/NoDataFound';

/**
 * CustomTable Component
 *
 * This component represents a customizable table for displaying data in a React application.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.rows - An array of objects representing the data rows to be displayed in the table.
 * @param {Array} props.columnHeaders - An array of strings representing the column headers for the table.
 * @param {boolean} props.loading - A boolean indicating whether the table is currently in a loading state.
 * @param {number} [props.pageCount=1] - The total number of pages for pagination.
 * @param {number} [props.currentPage=1] - The current active page for pagination.
 * @param {Function} [props.setCurrentPage=() => {}] - A function to set the current active page.
 * @param {Array} [props.selected=[]] - An array of selected rows.
 * @param {Function} [props.selectAll=() => {}] - A function to select all rows.
 * @param {Function} [props.selectCurrent=() => {}] - A function to select the current row.
 * @param {boolean} [props.pagination=true] - A boolean indicating whether to show pagination.
 * @param {Function} [props.getSortedList=() => {}] - A function to get a sorted list based on user interaction.
 * @returns {JSX.Element} - The JSX element representing the CustomTable component.
 */
const CustomTable = (props) => {
  const {
    rows,
    columnHeaders,
    loading,
    pageCount = 1,
    currentPage = 1,
    tableHeaderStyles = { height: '48px', maxHeight: '48px !important', padding: '8px 0 8px 20px' },
    tableCellStyles = { height: '48px', maxHeight: '48px !important', padding: '8px 0 8px 20px' },
    setCurrentPage = () => {},
    selected = [],
    selectAll = () => {},
    selectCurrent = () => {},
    pagination = true,
    getSortedList = () => {}
  } = props;
  const theme = useTheme();
  const tableComponentRef = useRef();

  const tableHeight = tableComponentRef?.current?.clientHeight;

  return (
    <Box ref={tableComponentRef} className="table__component">
      <TableContainer
        className="table__container"
        style={{ height: `calc(${tableHeight}px - ${pagination ? '56px' : '0px'}` }}
        sx={{ border: `1px solid ${theme.palette.primary.lighter}` }}
      >
        <Table
          stickyHeader
          className="table__content"
          sx={{ height: rows?.length === 0 || loading ? '100%' : 'auto' }}
          aria-label="table"
        >
          <TableHeader
            tableHeaderStyles={tableHeaderStyles}
            columnHeaders={columnHeaders}
            onSelectAllClick={(event) => selectAll(event, rows)}
            rowCount={rows?.length}
            selectedRowsCount={selected.length}
            onRequestSort={getSortedList}
          />
          <TableBody className="table__body">
            {rows && rows?.length && !loading ? (
              rows.map((row, index) => (
                <CustomTableRow
                  tableCellStyles={tableCellStyles}
                  row={row}
                  key={`${row.id}-${index}`}
                  selectRow={(rowId) => selectCurrent(rowId)}
                  selected={selected}
                  rowCellsFormat={columnHeaders}
                  currentPage={currentPage}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columnHeaders.length}>
                  <NoDataFound loading={loading} text={'No Data Found'} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination && (
        <Pagination
          className="table__pagination"
          variant="outlined"
          shape="rounded"
          count={pageCount}
          page={currentPage}
          onChange={(event, newPage) => setCurrentPage(newPage)}
          showFirstButton
          showLastButton
        />
      )}
    </Box>
  );
};

export default CustomTable;
