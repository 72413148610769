/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../../../styles/components/common/Dialogs/RunCrawlerDialog.css';
import { Chip, Stack, Typography } from '@mui/material';
import DialogActionButtons from './DialogActionButtons';

const RunCrawlerDialog = ({
  runCrawler,
  handleCloseDialog,
  crawlerConfig = null,
  runMultiple = false,
  selectedCrawlers = [],
  crawlersList = [],
  deleteCrawler = () => {}
}) => {
  const [selectedCrawlerList, setSelectedCrawlerList] = useState([]);
  const [apiPayload, setApiPayload] = useState();

  useEffect(() => {
    if (selectedCrawlers.length > 0) {
      setSelectedCrawlerList(
        crawlersList.map((crawler) => {
          if (selectedCrawlers.includes(crawler.id)) return { id: crawler.id, name: crawler.name };

          return null;
        })
      );
    }
  }, [selectedCrawlers]);

  useEffect(() => {
    setApiPayload({
      org_id: crawlerConfig?.orgId,
      crawler_config_id: crawlerConfig?.id,
      selectedCrawlers: selectedCrawlers,
      runMultiple: runMultiple
    });
  }, [selectedCrawlers]);

  const handleDeleteChip = (id) => {
    if (selectedCrawlers.length <= 1) return;
    deleteCrawler(id);
  };

  return (
    <div className="rundialog">
      <Stack flexDirection="row">
        <Typography variant="h4" className="rundialog__title">
          {selectedCrawlerList.length > 0
            ? 'Sure, you want to run these crawlers?'
            : 'Sure, you want to run this crawler?'}
        </Typography>
      </Stack>
      <Stack flexDirection="row" className="rundialog__crawlers__chips">
        {selectedCrawlerList?.length > 0
          ? selectedCrawlerList?.map(
              (crawler) =>
                crawler && (
                  <Chip
                    key={crawler.id}
                    label={crawler?.name}
                    onDelete={() => handleDeleteChip(crawler.id)}
                    className="rundialog__crawlers__chips__chip"
                  />
                )
            )
          : crawlerConfig && (
              <Chip label={crawlerConfig.name} className="rundialog__crawlers__chips__chip" />
            )}
      </Stack>

      <DialogActionButtons
        submitBtnText="Yes, Confirm"
        cancelBtnText="No, Cancel"
        onSubmitAction={runCrawler}
        handleCloseDialog={handleCloseDialog}
        apiPayload={apiPayload}
        align="right"
      />
    </div>
  );
};

export default RunCrawlerDialog;
