import { merge } from 'lodash';

import Input from './input';
import Button from './button';
import Tab from './tabs';
import Table from './table';
import Pagination from './pagination';
import Dialog from './dialog';
import Select from './select';

export default function MuiOverrides(theme) {
  return merge(
    Button(theme),
    Tab(theme),
    Input(theme),
    Table(theme),
    Pagination(theme),
    Dialog(theme),
    Select(theme)
  );
}
