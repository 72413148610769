/* eslint-disable no-unused-vars */
import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SearchBar from '../../common/Searchbar/SearchBar';
import CustomTable from '../../common/Table/CustomTable';
import '../../../styles/components/Insights/Auditing/Auditing.css';
import AuditingActions from './AuditingActions';

import useInsightsAudit from '../../../app/hooks/useInsightsAudit.js';
import useMultiSelector from '../../../app/hooks/useMultiSelector';
import TableMenuBar from '../../common/Table/TableMenuBar';
import useSearch from '../../../app/hooks/useSearch';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditOrderParam, setAuditSearchParam } from '../../../app/redux/slices/insightsSlice';

const columnHeaders = [
  {
    id: 'conversationQuestion',
    checkbox: false,
    sort: false,
    label: 'Conversations',
    align: 'left'
  },
  { id: 'dateTime', checkbox: false, sort: true, label: 'Date & Time', align: 'left' },
  {
    id: 'view',
    checkbox: false,
    customComponent: true,
    sort: false,
    label: 'View',
    align: 'left'
  }
];

const Auditing = () => {
  const theme = useTheme();
  const auditingHeaderRef = useRef();
  const tableContainerRef = useRef();
  const dispatch = useDispatch();

  const {
    loading,
    pageCount,
    insightsConversationAuditsList,
    getInsightsConversationAudits,
    currentPage,
    setCurrentPage,
    onfilterApply
  } = useInsightsAudit();

  const { auditFilters, auditSearchParam, auditOrderParam } = useSelector(
    (state) => state.insights
  );

  const { searchText, setSearchText, onSearch, onClearSearch } = useSearch();
  const { selected, selectAll, selectCurrent } = useMultiSelector();

  const [tableRowsData, setTableRowsData] = useState(insightsConversationAuditsList || []);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(columnHeaders[1].id);

  const paramsMap = {
    dateTime: 'date',
    conversationQuestion: 'question'
  };

  useEffect(() => {
    setTableRowsData(insightsConversationAuditsList);
  }, [insightsConversationAuditsList]);

  const getSortedList = (property) => {
    const isNextOrderAsc = orderBy === property && order === 'desc';

    //Ascending order has orderParam '-property', Descending order of dates has orderParam 'property'
    let orderParam = isNextOrderAsc ? '-' : '';

    orderParam += paramsMap[property];
    dispatch(setAuditOrderParam(orderParam));
    setCurrentPage(1);
    getInsightsConversationAudits(1, auditFilters, auditSearchParam, orderParam, true);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    onSearch(setAuditSearchParam, getInsightsConversationAudits, auditFilters);
  };

  useEffect(() => {
    if (auditOrderParam) {
      const paramsMapReverse = Object.entries(paramsMap).reduce((result, [key, value]) => {
        result[value] = key;

        return result;
      }, {});

      //Example: Ascending order of dates has orderParam '-date', Descending order of dates has orderParam 'date'
      setOrder(auditOrderParam?.includes('-') ? 'asc' : 'desc');
      let res = auditOrderParam;
      if (auditOrderParam?.includes('-')) res = auditOrderParam.substring(1);
      setOrderBy(paramsMapReverse[res]);
    }
  }, [auditOrderParam]);

  return (
    <Box container className="auditing__container">
      <Box ref={auditingHeaderRef} className="auditing__context">
        <Box container item className="auditing__header">
          <Typography variant="tabHeader">Auditing</Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: theme.typography.fontWeightMedium, textTransform: 'none' }}
          >
            Conversations
          </Typography>
        </Box>

        <div className="auditing__tablemenubar">
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={handleSearch}
            onClear={() => {
              setCurrentPage(1);
              onClearSearch(setAuditSearchParam, getInsightsConversationAudits, auditFilters);
            }}
          />
          <TableMenuBar
            setOpenFilterDialog={setOpenFilterDialog}
            setSearchText={setSearchText}
            openFilterDialog={openFilterDialog}
            onfilterApply={onfilterApply}
            currentPage={currentPage}
            crawlerRunsHistoryList={insightsConversationAuditsList}
            getCrawlerRunHistory={() =>
              getInsightsConversationAudits(
                currentPage,
                auditFilters,
                auditSearchParam,
                auditOrderParam,
                false
              )
            }
          />
        </div>
      </Box>
      <Box
        ref={tableContainerRef}
        sx={{ height: `calc(100% - ${auditingHeaderRef?.current?.clientHeight}px)` }}
        className="auditing__list"
      >
        <CustomTable
          columnHeaders={columnHeaders}
          selected={selected}
          selectAll={selectAll}
          selectCurrent={selectCurrent}
          rows={tableRowsData}
          loading={loading}
          getSortedList={getSortedList}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
    </Box>
  );
};

export default Auditing;
