/* eslint-disable no-unused-vars */
export default function Select(theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        list: {
          maxHeight: '150px'
        },
        paper: {
          '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: '#F5F5F5'
          },
          '&::-webkit-scrollbar': {
            width: '4px',
            backgroundColor: '#F5F5F5'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.35)'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '36px'
        }
      }
    }
  };
}
