/* eslint-disable no-unused-vars */
import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import '../../../styles/components/Crawler/Dashboard/DashboardGraphFilters.css';
import {
  getCrawlerRunAnalyticsGraphsDataAction,
  getCrawlerRuntimeMetricsGraphsDataAction
} from '../../../app/services/crawlerService';
import { useDispatch } from 'react-redux';
import { getUTCDateString } from '../../../app/utils/getUTCDateString';

const textFieldStyle = {
  sx: {
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.50)',
      fontSize: '10px'
    },
    fontSize: '13px',
    padding: '6.5px 12px'
  }
};

const DashboardGraphFilters = (props) => {
  const { graphFor, setLoading } = props;
  const [selectedCrawlerType, setSelectedCrawlerType] = useState(['sharepoint']);

  const crawlerTypes = ['sharepoint', 'onedrive'];
  const [dateRange, setDateRange] = useState();
  const { RangePicker } = DatePicker;
  const DATE_FORMAT = 'YYYY-MM-DD';
  const dispatch = useDispatch();

  const handleDefaultValue = () => {
    if (dateRange) {
      return [dayjs(dateRange[0], DATE_FORMAT), dayjs(dateRange[1], DATE_FORMAT)];
    }
    const today = dayjs().startOf('day');
    const sevenDaysAgo = today.subtract(7, 'day');
    setDateRange([sevenDaysAgo, today]);

    return [today, sevenDaysAgo];
  };

  useEffect(() => {
    try {
      const payload = {
        crawler_types: selectedCrawlerType,
        start_date: getUTCDateString(dateRange[0], 'startDate'),
        end_date: getUTCDateString(dateRange[1], 'endDate')
      };
      if (graphFor === 'crawlerRunAnalytics' && (selectedCrawlerType || dateRange)) {
        setLoading(true);

        dispatch(getCrawlerRunAnalyticsGraphsDataAction(payload))
          .unwrap()
          .then(() => setLoading(false))
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
      if (graphFor === 'crawlerRuntimeMetrics') {
        setLoading(true);

        dispatch(getCrawlerRuntimeMetricsGraphsDataAction(payload))
          .unwrap()
          .then(() => setLoading(false))
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedCrawlerType, dateRange]);

  return (
    <div className="dashboard__graph__filters">
      <Select
        value={selectedCrawlerType}
        required
        multiple
        className="crawlertype__select"
        inputProps={textFieldStyle}
        onChange={(e) => setSelectedCrawlerType(e.target.value)}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Crawler Type</em>;
          }

          return selected.join(', ');
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          getContentAnchorEl: null
        }}
      >
        <MenuItem className="form__selectitems" disabled value="">
          <span>Select Crawler Type</span>
        </MenuItem>
        {crawlerTypes.map((crawlerType) => (
          <MenuItem className="form__selectitems" key={crawlerType} value={crawlerType}>
            <Checkbox
              size="small"
              sx={{ mr: 2, width: '5px', height: '5px' }}
              checked={selectedCrawlerType.includes(crawlerType)}
            />
            <span>{crawlerType}</span>
          </MenuItem>
        ))}
      </Select>
      <RangePicker
        className="daterange__select"
        onChange={(selectedDates) => setDateRange(selectedDates)}
        value={dateRange}
        defaultValue={handleDefaultValue}
        format={DATE_FORMAT}
        size="small"
        allowClear={false}
      />
    </div>
  );
};

export default DashboardGraphFilters;
