import React from 'react';
import '../../../styles/components/common/Dialogs/DeleteCrawlerScheduleDialog.css';
import { Chip, Stack, Typography } from '@mui/material';
import DialogActionButtons from './DialogActionButtons';
import { WarningAmberOutlined } from '@mui/icons-material';

const DeleteCrawlerScheduleDialog = ({
  todaysScheduledCrawler,
  handleCloseDialog,
  deleteCrawlerRunSchedule
}) => {
  return (
    <div className="delete__dialog">
      <Stack flexDirection="row">
        <WarningAmberOutlined sx={{ color: '#EF6C00', mr: 2 }} />
        <Typography variant="h4" className="delete__dialog__title">
          Sure, you want to cancel the schedule?
        </Typography>
      </Stack>

      <Stack flexDirection="row" className="deletedialog__crawlers__chips">
        <Chip
          label={
            todaysScheduledCrawler.crawlerName +
            ' - ' +
            todaysScheduledCrawler.crawlerTime +
            ' ' +
            todaysScheduledCrawler.todayDate
          }
          className="deletedialog__crawlers__chips__chip"
        />
      </Stack>

      <DialogActionButtons
        submitBtnText="Yes, Confirm"
        cancelBtnText="No, Cancel"
        onSubmitAction={deleteCrawlerRunSchedule}
        handleCloseDialog={handleCloseDialog}
        align="right"
        apiPayload={{ id: todaysScheduledCrawler.id }}
      />
    </div>
  );
};

export default DeleteCrawlerScheduleDialog;
