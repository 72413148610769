export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: 16
        },
        contained: {
          height: 42,
          minWidth: 110,
          paddingLeft: 28,
          paddingRight: 28,
          background: theme.palette.primary.dark,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            background: theme.palette.primary.dark
          }
        },
        outlined: {
          color: theme.palette.primary.darker,
          height: 42,
          padding: '12px 28px',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.primary.dark}`,
          background: theme.palette.primary.lighter,
          backdropFilter: 'blur(0px)',
          '&:hover': {
            background: theme.palette.primary.lighter
          }
        },
        text: {
          padding: '0px !important',
          '&:hover': {
            backgroundColor: theme.palette.primary.main
          }
        }
      }
    }
  };
}
