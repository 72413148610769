import { Box, Button, Checkbox, CircularProgress, Stack, Typography } from '@mui/material';
import {
  downloadTeamsBotZip,
  getTeamsBotIntegrationGuideLink
} from '../../app/services/authService';
import Toast from '../common/Toast/Toast';
import { CheckCircle } from '@mui/icons-material';
import '../../styles/components/Onboard/IntegrateTeamsStep.css';

export const IntegrateTeamsStep = ({
  isTeamsIntegrated,
  setIsTeamsIntegrated,
  loading,
  setLoading,
  errorInTeamsIntegration,
  setErrorInTeamsIntegration
}) => {
  const handleTeamIntegrate = () => {
    if (isTeamsIntegrated.integrated) return;
    setErrorInTeamsIntegration('');
    setLoading(true);

    window.open(
      // eslint-disable-next-line no-undef
      `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_MSAL_TEAMS_CLIENT_ID}`,
      '_self'
    );
  };

  const handleManifestDownload = () => {
    downloadTeamsBotZip()
      .then((result) => {
        if (result.success) {
          window.open(result?.data?.download_url, '_self');
        } else throw new Error('Failed to download zip file');
      })
      .catch((error) => {
        <Toast severity="error" message={'Failed to download zip file'} />;
        console.log(error);
      });
  };

  const openGuide = () => {
    getTeamsBotIntegrationGuideLink()
      .then((result) => {
        if (result.success) {
          window.open(result?.data?.download_url, '_blank');
        } else throw new Error('Failed to download zip file');
      })
      .catch((error) => {
        <Toast severity="error" message={'Failed to download zip file'} />;
        console.log(error);
      });
  };

  const handleConfirm = () => {
    setIsTeamsIntegrated({ ...isTeamsIntegrated, confirmed: !isTeamsIntegrated.confirmed });
  };

  return (
    <Box>
      <Typography className="onboard_steps_title" variant="h3">
        Integrate with Microsoft Teams Bot
      </Typography>
      <Typography variant="h5" sx={{ mt: '12px', textTransform: 'none' }}>
        To install and publish Privou Search Assistant in you Microsoft Teams, Please follow the
        below two step process and ensure that you have completed them in order.
      </Typography>

      <>
        <Typography className="onboard__teams__integrate__steps__title" variant="h4">
          Install Privou MS Teams App Registration
        </Typography>
        <Box className="onboard__teams__integrate__steps">
          <Stack className="onboard__teams__integrate__step">
            <Typography className="onboard__teams__integrate__download__step" variant="h5">
              Click below to install Privou App Registration in Microsoft Teams.
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '13px', textTransform: 'none' }}>
              [ Note: Use Microsoft Account with Global Administrator Permission. ]
            </Typography>
            <Button
              className="onboard__manifest__download__btn"
              variant="contained"
              onClick={handleTeamIntegrate}
              startIcon={isTeamsIntegrated.integrated ? <CheckCircle /> : null}
              sx={{ cursor: isTeamsIntegrated.integrated ? 'default' : 'pointer' }}
            >
              {isTeamsIntegrated.integrated ? 'Integrated' : 'Integrate'}
              {loading && <CircularProgress size={16} sx={{ ml: 1.5, color: 'white' }} />}
            </Button>
            {errorInTeamsIntegration?.length > 0 && (
              <Typography variant="h6" sx={{ mt: 1, ml: '12px', fontSize: '13px', color: 'red' }}>
                {errorInTeamsIntegration}
              </Typography>
            )}
          </Stack>
        </Box>
      </>

      <>
        <Typography className="onboard__teams__integrate__steps__title" variant="h4">
          Install and publish Privou Search Assistant MS Teams package
        </Typography>
        <Box className="onboard__teams__integrate__steps">
          <Stack>
            <Stack className="onboard__teams__integrate__step">
              <Typography className="onboard__teams__integrate__download__step" variant="h5">
                1. Download the necessary zip files by clicking the below button.
              </Typography>
              <Button
                className="onboard__manifest__download__btn"
                variant="contained"
                onClick={handleManifestDownload}
              >
                Download
              </Button>
            </Stack>
            <Stack sx={{ mt: 1 }} className="onboard__teams__integrate__step">
              <Typography className="onboard__teams__integrate__step" variant="h5">
                2. Use this handbook to publish and install the downloaded zip in you Teams
                Application.
              </Typography>
              <Button
                className="onboard__manifest__download__btn"
                variant="contained"
                onClick={openGuide}
              >
                Handbook
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>

      {isTeamsIntegrated.integrated && (
        <Stack
          flexDirection="row"
          alignItems="center"
          className="onboard__teams__integrate__confirm"
        >
          <Checkbox
            size="small"
            className="onboard__manifest__confirm__checkbox"
            onClick={handleConfirm}
            checked={isTeamsIntegrated.confirmed}
          />
          <Typography className="onboard__teams__integrate__confirm__title" variant="h6">
            Confirm that you have followed above steps to integrate with Microsoft Teams.
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
