/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { PermIdentityOutlined } from '@mui/icons-material';
import botLogo from '../../assets/bot_logo.svg';
import '../../styles/components/Chatbot/Message.css';
import { getFormattedResponse } from '../../app/utils/responseFormatter';
import { getFormatedDate } from '../../app/utils/dateFormater';
import Typewriter from 'typewriter-effect';

const Message = (props) => {
  const { id, createdAt, text, ai = false } = props.message;
  const { isTypingStatus, setIsTypingStatus, typingMessagesEndRef } = props;

  const [isTyping, setIsTyping] = useState(false);
  const formattedMessage = getFormattedResponse(text);

  useEffect(() => {
    if (isTypingStatus?.isTyping && ai && isTypingStatus?.id === id) {
      setIsTyping(true);

      const timer = setTimeout(
        () => {
          setIsTyping(false);
          setIsTypingStatus({ id: 0, isTyping: false });
        },
        text?.replace(/\s+/g, '')?.length * 18
      );

      return () => clearTimeout(timer);
    }
  }, [isTypingStatus, ai, id, setIsTypingStatus, text]);

  return (
    <div key={id} className={`message ${ai ? 'message-ai' : 'message-user'}`}>
      <div className="message__chat">
        <div className={`message__chat__bubble ${ai ? 'bubble-ai' : 'bubble-user'}`}>
          {isTyping && ai ? (
            <Typewriter
              options={{
                delay: 5,
                wrapperClassName: 'message__chat__bubble__typewritter'
              }}
              onInit={(typewriter) => {
                typewriter.typeString(text).start();
              }}
            />
          ) : (
            <Typography variant="body1" className="message__chat__bubble__text">
              {formattedMessage}
            </Typography>
          )}
          <div className="timestamp">{getFormatedDate(createdAt)}</div>
          <span ref={typingMessagesEndRef}></span>
        </div>
      </div>

      <div className="message__avatar">
        {ai ? (
          <img src={botLogo} className="message__avatar__img" alt="Bot Avatar" />
        ) : (
          <PermIdentityOutlined className="message__avatar__icon" />
        )}
      </div>
    </div>
  );
};

export default Message;

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.number.isRequired,
    text: PropTypes.string,
    ai: PropTypes.bool,
    selected: PropTypes.string
  }).isRequired
};
